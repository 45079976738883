export default {
  INIT_STORE(state) {
    console.log("Loading states for app from local storage", state)
  },
  SET_CONFIG(state, config) {
    state.config = config
  },
  SET_OPTIONS(state, options) {
    state.options = options
    console.log('Setting options =>', options)
  },
	//this specifically sets the zone_toggle_setting in the options
  SET_ZONE_TOGGLE_SETTING(state, val){
		state.options.zone_toggle_setting = val
	},
  SHOW_MESSAGE(state, payload) {
    state.snackbar = payload
  },
  SOCKET_CONNECTED(state, info) {
    state.socket.connectionInfo = info
    state.socket.connectionStatus = 'socket-connected'
  },
  SOCKET_DISCONNECTED(state) {
    state.socket.connectionStatus = 'socket-closed'
  },
  SOCKET_CHECK_CONNECTION(state, val) {
    state.checkConnection = val
  },
  ZOOM_IN(state) {
    if (state.zoomLevel < 1.2) {
      state.zoomLevel += 0.2
    } 
    else {
      state.zoomLevel += 0.5
    }
  },
  ZOOM_OUT(state) {
    if (state.zoomLevel > 1.5) {
      state.zoomLevel -= 0.5
    } else if (state.zoomLevel > 0.3) {
      state.zoomLevel -= 0.2
    }
  },
	SET_SHOW_SECOND_TOOLBAR(state, val){
		state.showSecondToolbar = val
	},
  SET_ZOOM_LEVEL(state, zoomLevel) {
    state.zoomLevel = zoomLevel
  },
  SET_MINI_MAIN_MENU(state, mini) {
    state.miniMainMenu = !mini
  },
	SET_MINI_MAIN_MENU_VAL(state, val){
		console.log('mini main value setting to: ' + val)
		state.miniMainMenu = val
	},
	SET_BUTTON_STYLE(state, val){
		state.buttonStyle.buttons = val
	},
	SET_ICON_VISIBLE(state, val){
		state.buttonStyle.icons.display = val
	},
	SET_LABEL_VISIBLE(state, val){
		state.buttonStyle.labels.display = val
	},
	SET_ICON_CLASS(state, val){
		state.buttonStyle.icons.class = val
	},
	SET_ICON_STYLE(state, val){
		state.buttonStyle.icons = val
	},
	SET_IDEAL(state, val){
		state.buttonStyle.ideal = val
	}
}
