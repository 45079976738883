
export default {
	page: {},
	layout: {},
	contrastZones: {},
	detectedLayouts: [],
	selectedZone: null,
	selectedZoneList: null,
	selectedListItem: null,
	focusedZone: null,
	sensitivity: null,
	mlTables: [],
	tableZone: null,
	showTableEditor: false,
	initialLayout: {},
	previousLayout: {},
	lastSavedLayout: {},
	undoredoDone: [],
	undoredoUndone: [],
}