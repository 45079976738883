import { diff } from 'deep-object-diff'

export default {
  vuexUndoRedo(state) {
    return state
  },
  getPage(state) {
    return state.page
  },
  getLayout(state) {
    return state.layout
  },
	getContrastZones(state){
		return state.contrastZones
	},
  getSelectedZone(state) {
    return state.selectedZone
  },
  getFocusedZone(state) {
    return state.focusedZone
  },
  getSelectedZoneList(state) {
    return state.selectedZoneList
  },
  getSelectedListItem(state) {
    return state.selectedListItem
  },
  getSensitivity(state) {
    return state.sensitivity
  },
  getTableZone(state) {
    return state.tableZone
  },
  getShowTableEditor(state) {
    return state.showTableEditor
  },
  getUndoRedoDone(state){
    return state.undoredoDone
  },
  canUndo(state){
    return state.undoredoDone.length > 0
  },
  canRedo(state){
    return state.undoredoUndone.length > 0
  },
  isPageDirty(state) {
    // Any diff between current state and loading state
    const res = diff(state.lastSavedLayout, state.layout)
    if (res) {

      if (Object.keys(res).length > 0) {
        // Found differences
        let realDiff = []
        for (var r in res) {

          if (res[r]) {
            // Skip selected and changed attributes, they are only needed for ui rendering
            if (!(Object.keys(res[r]).length == 1 && ('selected' in res[r] || 'changed' in res[r]))) {
              realDiff.push(res[r])
            }
          }
        }
  
        if (realDiff.length > 0) {
          // Found some real differences
          return true
        }
      }
      return false
    }
    return false
  }
}
