<!-- This is the top toolbar for the table editor-->
<template>
	<!--
	<v-app-bar id="tableEditorToolBar" color="base" class="mb1 custom-top " app extension-height="90px"
		elevation="0" height=90 style="border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;">
	-->
	<v-app-bar app color="base" class="custom-top" height="90" elevation="0"
		style="border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;">
		<v-toolbar-title class="mr-10 ml-6 mb-2">
			{{ $t("page.tableEditor.title") }}
		</v-toolbar-title>

		<v-card class="d-flex align-content flex pa-3 mt-n3 mb-n1" flat tile style="margin-left:135px;">
			<v-toolbar-items class="pa-3 toolbar-buttons">


				<v-toolbar-text class="ml-2 mr-4" v-show="selectedCell && !isRowSpan">{{ $t("page.tableToolBar.row") }} {{
						selected_row_index
				}}</v-toolbar-text>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="splitRow(selected_row_index)" elevation="1"
							v-show="selectedCell && !isRowSpan">
							<v-icon v-bind="attrs" v-on="on">mdi-table-row-plus-after</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("page.tableToolBar.splitRow") }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }" v-show="selectedCell && !isRowSpan">
						<v-btn large icon :ripple=defaultRipple @click="removeRow(selected_row_index)" elevation="1"
							v-show="selectedCell && !isRowSpan">
							<v-icon v-bind="attrs" v-on="on">mdi-table-row-remove</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("page.tableToolBar.deleteRow") }}</span>
				</v-tooltip>



				<v-toolbar-text class="ml-12 mr-4" v-show="selectedCell && !isSpan">{{ $t("page.tableToolBar.column") }} {{
						selected_col_index
				}}</v-toolbar-text>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="splitColumn(selected_col_index)" elevation="1"
							v-show="selectedCell && !isSpan">
							<v-icon v-bind="attrs" v-on="on">mdi-table-column-plus-after</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("page.tableToolBar.splitColumn") }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="removeColumn(selected_col_index)" elevation="1"
							v-show="selectedCell && !isSpan">
							<v-icon v-bind="attrs" v-on="on">mdi-table-column-remove</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("page.tableToolBar.removeColumn") }}</span>
				</v-tooltip>

				<v-tooltip bottom v-if="isSpan || isRowSpan">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" elevation="2" v-bind="attrs" v-on="on" @click="removeSpans">
							{{ $t("page.tableToolBar.removeSpan") }}
						</v-btn>
					</template>
					<span>{{ $t("page.tableToolBar.removeSpan") }}</span>
				</v-tooltip>


				<v-toolbar-text v-if="showSpanColumns" class="ml-2 mr-4">{{ $t("page.tableToolBar.spanColumns") }}
					{{ selectedCells.columns != null ? selectedCells.columns.start + 1 : '' }} - {{ selectedCells.columns != null
							?
							selectedCells.columns.end + 1 : ''
					}}</v-toolbar-text>

				<v-tooltip bottom v-if="showSpanColumns">
					<template v-slot:activator="{ on, attrs }" v-if="showSpanColumns">
						<v-btn large icon :ripple=defaultRipple @click="spanColumns(selectedCells)" elevation="1">
							<v-icon v-bind="attrs" v-on="on">mdi-arrow-left-right</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("page.tableToolBar.spanColumnsS") }}</span>
				</v-tooltip>



				<v-toolbar-text v-if="showSpanRows" class="ml-2 mr-4">{{ $t("page.tableToolBar.spanRows") }}
					{{ selectedCells.rows != null ? selectedCells.rows.start + 1 : '' }} - {{ selectedCells.rows != null ?
							selectedCells.rows.end + 1 : ''
					}}</v-toolbar-text>

				<v-tooltip bottom v-if="showSpanRows">
					<template v-slot:activator="{ on, attrs }" v-if="showSpanRows">
						<v-btn large icon :ripple=defaultRipple @click="spanRows(selectedCells)" elevation="1">
							<v-icon v-bind="attrs" v-on="on">mdi-arrow-up-down</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("page.tableToolBar.spanRows") }}</span>
				</v-tooltip>


			</v-toolbar-items>



		</v-card>
		<v-card class="d-flex align-content flex pa-3 mt-n3 ml-n4 justify-end" flat tile>
			<v-toolbar-items class="pa-3 toolbar-buttons">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="saveTable(false)">
							<v-icon v-bind="attrs" v-on="on">mdi-content-save-outline</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.save') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple>
							<v-icon v-bind="attrs" v-on="on" @click="previewTableLayout">mdi-monitor</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.tableToolBar.previewTable') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple>
							<v-icon v-bind="attrs" v-on="on" @click="zoomInTable">mdi-magnify-plus-outline</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.zoomIn') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple>
							<v-icon v-bind="attrs" v-on="on" @click="zoomOutTable">mdi-magnify-minus-outline</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.zoomOut') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on: onHover }">

						<v-dialog v-model="closeDialog" max-width="850px">
							<template v-slot:activator="{ on: onClose }">
								
									<v-btn large icon v-bind="attrs" v-on="{...onClose}" @click="promptSaveTable" :ripple=defaultRipple>
										<v-icon v-bind="attrs" v-on="{ ...onHover }" >mdi-close</v-icon>
									</v-btn>
							
							</template>

							<v-card>
								<v-card-title>
									<span class="text-h5">{{ $t("page.tableToolBar.saveTable") }}</span>
								</v-card-title>
								<v-card-text>
									<v-container>
										<v-row>
											<v-col cols="12" class="text-h6">
												<span>{{ $t("page.tableToolBar.tableNotSaved") }}</span>
											</v-col>
										</v-row>
									</v-container>
								</v-card-text>

								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text @click="closeTableEditor">
										{{ $t("page.tableToolBar.no") }}
									</v-btn>
									<v-btn color="blue darken-1" text @click="saveTable(true)">
										{{ $t("page.tableToolBar.yes") }}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>

					</template>
					<span>{{ $t('page.toolbar.close') }}</span>
				</v-tooltip>


			</v-toolbar-items>


		</v-card>

	</v-app-bar>

</template>

<script>
import EventBus from "@/eventBus"

export default {
	name: "ToolBars",
	props: ['page', 'document',
		'selected_row_index',
		'selected_col_index', 'selectedCells',
		'isSpan', 'isRowSpan', 'selectedCell',
		'tableZoneIsDirty'],
	data() {
		return {
			defaultRipple: { center: true },
			closeDialog: false,
		}
	},
	computed: {
		showSpanColumns() {
			var retval = false
			if (this.selectedCells != undefined && this.selectedCells.columns != undefined) {
				retval = !this.isSpan && !this.isRowSpan && this.selectedCells.columns.end > this.selectedCells.columns.start
			}
			return retval
		},

		showSpanRows() {
			var retval = false
			if (this.selectedCells != null && this.selectedCells.rows != undefined) {
				retval = !this.isSpan && !this.isRowSpan && this.selectedCells.rows != undefined && this.selectedCells.rows.end > this.selectedCells.rows.start
			}
			return retval
		},

	},
	components: {


	},

	watch: {

	},

	methods: {
		promptSaveTable(){
			if(this.tableZoneIsDirty == true){
				this.closeDialog = true
			}
			else{
				this.closeTableEditor()
				this.closeDialog = false
			}
		},
		/*
			Event emitters picked up by parent TableEditor
		*/
		splitRow(index) {
			EventBus.$emit("split-row", index)
		},
		removeRow(index) {
			EventBus.$emit("remove-row", index)
		},
		splitColumn(index) {
			EventBus.$emit("split-column", index)
		},
		removeColumn(index) {
			EventBus.$emit("remove-column", index)
		},
		spanColumns(selectedCells) {
			EventBus.$emit("span-columns", selectedCells)
		},
		spanRows(selectedCells) {
			EventBus.$emit("span-rows", selectedCells)
		},
		removeSpans() {
			EventBus.$emit("remove-spans", {})
		},
		zoomInTable() {
			EventBus.$emit("zoom-in-table", {})
		},
		zoomOutTable() {
			EventBus.$emit("zoom-out-table", {})
		},
		closeTableEditor() {
			this.closeDialog = false
			EventBus.$emit("close-table-editor", {})
		},
		saveTable(close) {
			this.closeDialog = false
			EventBus.$emit("save-table", {close: close})
		},
		previewTableLayout() {
			EventBus.$emit("preview-table-layout", {})
		}



	},
	mounted() {


	},
}


</script>

<style lang="scss" scoped>
#main {
	width: 100% !important;
}

.custom-top {
	margin-top: 72px !important;
	height: 80px !important;
	left: 78px !important;

}

.short-text {
	width: 80px;
	max-width: 80px;
}

.select-width {
	width: 150px;
}

.short-text {
	width: 80px;
	max-width: 80px;
}

.toolbar-buttons>button {
	margin-right: 15px;
}

#editorToolBar .v-toolbar__content,
#editorToolBar .v-toolbar__extension {
	border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>