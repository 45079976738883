import Vue from "vue"
import VueRouter from "vue-router"
import Dashboard from "../views/Dashboard.vue"
import { auth } from "../firebase"

import User from "../views/user/routes"
import Documents from "../views/documents/routes"
import Document from "../views/document/routes"
import Page from "../views/page/routes"
import Import from "../views/import/routes"
import Notifications from "../views/notifications/routes"
import Settings from "../views/settings/routes"
import Help from "../views/help/routes"
import user from "../store/modules/user"
import NotFound from "../views/NotFound.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/user/components/Login.vue"
      ),
  },
	{
    path: "/logout",
    name: "Logout",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/user/components/Logout.vue"
      ),
  },
	{
    path: "/switchcust",
    name: "SwitchCust",
		meta: {
			requiresAuth: true,
		},
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/user/components/SwitchCust.vue"
      ),

  },
	{
    path: "/reset-beta",
    name: "ResetBeta",
    component: () =>
      import(
        "../views/user/components/Reset-beta.vue"
      ),
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      i18Msg: "barTitle.dashboard",
    },
  },
  ...User,
  ...Documents,
  ...Document,
  ...Page,
  ...Import,
  ...Notifications,
  ...Settings,
  ...Help,
  {
    path: "/:notFound",
    name: "NotFound",
    component: NotFound,
  },
]

const router = new VueRouter({
 // mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
	console.log('in router going to:', to)
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
	console.log('auth.currentUser: ', auth.currentUser)
	console.log('user.state.authenticated', user.state.authenticated)
  if (requiresAuth && (!auth.currentUser && !user.state.authenticated)) {
		console.log('not authenticated - going to /login')
    next("/login")
  }
	//if the user trys to got to root/login, but they are already authed, let's pass them to the "/" ie dashboard
	if(to.name == 'Login' && (auth.currentUser && user.state.authenticated)){
		next("/")
	}
	next()
 
})

export default router
