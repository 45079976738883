<template>
	<v-tab-item key="Page" class="tab-item-wrapper">
		<v-card fluid fill-height class="side-bar-tab side-bar-bg tab-item-wrapper scrolling-tab">

			<div class="room-for-scrollbar pl-2">

				<v-expansion-panels>

					<v-expansion-panel>
						<v-expansion-panel-header disable-icon-rotate>

							<template v-slot:actions>
								<v-icon color="primary">
									mdi-information
								</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>

							<v-row>
								<v-col cols="4">
									{{ $t('page.sidebarPage.page') }}
								</v-col>
								<v-col>
									{{ page.page_no }}
								</v-col>
							</v-row>

							<!-- <v-row>
                <v-col>
                  Page size
                </v-col>
                <v-col>
                  8 inch x 11.5 inch
                </v-col>
              </v-row> -->

							<v-row>
								<v-col cols="4">
									{{ $t('page.sidebarPage.lastSaved') }}
								</v-col>
								<v-col v-if="language == 'en' && page.last_saved_on">
									<vue-moments-ago prefix="" suffix="ago" :date="page.last_saved_on" lang="en"></vue-moments-ago>
								</v-col>
								<v-col v-if="language == 'fr' && page.last_saved_on">
									<vue-moments-ago prefix="" :date="page.last_saved_on" suffix="" lang="fr"></vue-moments-ago>
								</v-col>
							</v-row>

							<v-row v-if="page.validated_on">
								<v-col cols="4" class="section-label">
									{{ $t('page.sidebarPage.lastSaved') }}
								</v-col>
								<v-col>
									{{ page.validated_on }}
								</v-col>
							</v-row>

							<v-row v-if="page.validated_by">
								<v-col cols="4" class="section-label">
									{{ $t('page.sidebarPage.validatedBy') }}
								</v-col>
								<v-col>
									{{ page.validated_by }}
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-textarea color="white" name="input-7-1" solo label="Notes" auto-grow v-model=page.notes></v-textarea>
								</v-col>
							</v-row>

						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>


				<v-container v-show="options.enable_ml_page_match && document.use_ml_page_match">
					<SectionTitle :title="$t('page.sidebarPage.mlPageMatch')" />
					<v-row>
						<v-col cols="4" class="section-label">
							{{ $t('page.sidebarPage.enable') }}
						</v-col>
						<v-checkbox class="mt-1 large-check" v-model="page.use_ml_page_match"
							@change="toggleMlPageMatch"></v-checkbox>
					</v-row>

					<v-row>
						<v-col cols="4" class="section-label">
							{{ $t('page.sidebarPage.sensitivity') }} {{ mlSensitivity }}
						</v-col>
						<v-col>
							<v-slider min="1" max="5" step="1" v-model="mlSensitivity" @input="changeMlSensitivity"
								:disabled="!page.use_ml_page_match"></v-slider>
						</v-col>
					</v-row>
				</v-container>

				<SectionTitle :title="$t('page.sidebarPage.zoneDetector')" />

				<v-row>
					<v-col cols="4" class="section-label">
						{{ $t('page.sidebarPage.sensitivity') }} {{ page.sensitivity }}
					</v-col>
					<v-col>
						<v-slider min="0" max="20" step="1" v-model="page.sensitivity" @input="changeSensitivity"></v-slider>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="4" class="section-label">
						{{ $t('page.sidebarPage.fields') }}
					</v-col>
					<v-col>
						<v-checkbox class="mt-1 large-check" v-model="page.fields_visible" @change="toggleFields"></v-checkbox>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="4" class="section-label">
						{{ $t('page.sidebarPage.images') }}
					</v-col>
					<v-col>
						<v-checkbox class="mt-1 large-check" v-model="page.images_visible" @change="toggleImages"></v-checkbox>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="4" class="section-label">
						{{ $t('page.sidebarPage.keepZoneTypes') }}
					</v-col>
					<v-col>
						<v-checkbox class="mt-1 ml-4 large-check" v-model="page.keep_zone_types"
							@change="toggleKeepZoneTypes"></v-checkbox>
					</v-col>
				</v-row>

				<v-row>
					<v-col class="text-center">
						<v-btn color="primary" small elevation="2" @click="clickSensitivityAllPages">
							{{ $t('page.sidebarPage.applyToAll') }}
						</v-btn>
					</v-col>
				</v-row>

				<template v-if="page.is_tagged">
					<SectionTitle title="PDF was tagged" />
				</template>

				<SectionTitle :title="$t('page.sidebarPage.readingOrder')" />

				<v-row>
					<v-col cols="4">
						{{ $t('page.sidebarPage.automatic') }}
					</v-col>
					<v-col>

						<v-select v-model="selectedReadingOrder" :items="$t('page.toolbar.readingOrders')" solo dense />
					</v-col>
				</v-row>

				<v-row>
					<v-col class="text-center">
						<v-btn color="primary" small elevation="2" @click="clickReorder">
							{{ $t('page.sidebarPage.reOrder') }}
						</v-btn>
						<v-btn color="primary" small elevation="2" @click="clickReorderAll">
							{{ $t('page.sidebarPage.applyToAll') }}
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="sidebar-bottom-padding">
					<br />
				</v-row>

			</div>
		</v-card>

	</v-tab-item>
</template>
<script>
import i18n from "@/plugins/i18n"
import { mapGetters, mapActions, mapMutations } from "vuex"
import VueMomentsAgo from "@/components/MomentsAgo"
import SectionTitle from "./SectionTitle"
import EventBus from "@/eventBus"

export default {
	props: ["page", "document"],
	data() {
		return {
			sensitivity: 5,
			selectedReadingOrder: "1 column",
			mlSensitivity: 1,
			allMlLayouts: [],
			currentMlRadius: 1,
			mlLayoutDocRef: [],
			mlLayoutPageNoRef: [],
		}
	},
	components: {
		VueMomentsAgo,
		SectionTitle
	},
	computed: {
		...mapGetters({
			language: "language/getLanguage",
			layout: "page/getLayout",
			options: "application/getOptions",
		}),
	},
	watch: {
		page: {
			handler(newVal, oldVal){
				//page has changed, reload the ml page match layouts
				console.log('page change. updating ml page match layouts')
				this.loadMlPageMatchLayouts()
			}
		}
	},
	methods: {
		...mapActions({
			setSensitivityAllPages: "page/setSensitivityAllPages",
			setOrderAllPages: "page/setOrderAllPages",
		}),
		...mapMutations({
			refreshImageZones: "page/REFRESH_IMAGE_ZONES",
			refreshFieldZones: "page/REFRESH_FIELD_ZONES",
			refreshLinkZones: "page/REFRESH_LINK_ZONES",
			setLayout: "page/SET_LAYOUT",
			updateZone: "page/UPDATE_LAYOUT_ZONE",
		}),
		//Same as onRadiusChange from classic
		changeSensitivity() {
			this.$store.commit("page/SET_SENSITIVITY", this.page.sensitivity)
		},
		changeMlSensitivity() {
			console.log('in change ml sensitivity')
			this.onMlRadiusChange(this.mlSensitivity)
		},
		toggleMlPageMatch() {
			if (this.page.use_ml_page_match == true) {
				this.onMlRadiusChange(1)
			}
		},
		onMlRadiusChange(mlRadius) {
			if (this.allMlLayouts !== undefined && this.allMlLayouts !== null) {
				this.currentMlRadius = mlRadius
				if (this.page !== undefined) {
					this.page.ml_page_match_sensitivity = mlRadius
				}
				//get the layout for the selection
				var ml_layout = this.allMlLayouts[mlRadius]
				//get the doc ref and the page ref
				this.mlLayoutDocRef = this.mlLayoutsDocRefs[mlRadius]
				this.mlLayoutPageNoRef = this.mlLayoutsPageNoRefs[mlRadius]
				console.log('in on ml radius change. ml layout is:', this.ml_layout)
				this.setLayout(ml_layout)

				//remove image, graphic, field, link zone types i.e. could be invalid
				this.removeInvalidMlZones(ml_layout)

				//roughly proceed like the sensitivity radius change routine
				this.refreshZones(ml_layout);

				//testing

				//refresh links
				this.refreshLinkZones()

				if (this.page !== undefined) {
					//refresh images
					if (this.page.images_visible) {
						this.refreshImageZones()
					}

					//refresh fields
					if (this.page.fields_visible) {
						this.refreshFieldZones()
					}
				}
			}
		},
		removeInvalidMlZones(ml_layout) {
			if (ml_layout != null && ml_layout.length > 0) {
				var i = ml_layout.length
				while (i--) {
					if (ml_layout[i].type == "image" ||
						ml_layout[i].type == "graphic" ||
						ml_layout[i].type == "field" ||
						ml_layout[i].type == "link") {
						ml_layout.splice(i, 1)
					}
				}
			}
		},
		refreshZones(layout) {
			
		},
		refreshLinkZones() {
			//this.refreshLinkZones()
		},
		clickSensitivityAllPages() {

			EventBus.$once('notification', (msg) => {
				this.$snackbar.showMessage({ content: msg.data.msg, color: 'success' })
			})

			this.setSensitivityAllPages({
				payload: {
					doc_key: this.$route.params.key,
					radius: this.page.sensitivity,
					page_no: this.page.page_no
				},
			})
		},
		clickReorder() {
			this.$store.commit("page/REORDER_PAGE", this.selectedReadingOrder)
			this.$snackbar.showMessage({ content: this.$t("page.sidebarPage.msg.reordered", [this.selectedReadingOrder]), color: 'success' })
		},
		clickReorderAll() {

			this.setOrderAllPages({
				payload: {
					doc_key: this.$route.params.key,
					page_no: this.page.page_no,
					order: this.document.autoReorder
				}
			}).then((response) => {
				this.$store.commit("page/REORDER_PAGE", this.document.autoReorder)
				this.$snackbar.showMessage({ content: this.$t("page.sidebarPage.msg.default", [this.document.autoReorder]), color: 'success' })
			})
		},
		toggleFields() {
			console.log('Fields visible:', this.page.fields_visible)
			if (!this.page.fields_visible) {
				var newZones = []
				for (var i = 0; i < this.layout.length; i++) {
					if (this.layout[i].type !== 'field') {
						newZones.push(this.layout[i])
					}
				}
				this.setLayout(newZones)

			}
			else {
				this.refreshFieldZones()
			}
		},
		toggleImages() {
			console.log('Images visible: ' + this.page.images_visible)
			for (var i = 0; i < this.layout.length; i++) {
				if (this.layout[i].type == 'image') {
					this.layout[i].visible = this.page.images_visible
					this.layout[i].hideZone = !this.page.images_visible
					this.updateZone(this.layout[i])
				}
			}

			this.refreshImageZones()
			console.log('after toggle show images, layout is now: ', this.layout)

		},
		toggleKeepZoneTypes() {
			console.log('Keep zone types: ' + this.page.keep_zone_types)
			//reset radius to 5
			this.page.sensitivity = 5
			this.changeSensitivity()
			this.adjustZoneBorders()
		},
		adjustZoneBorders() {
			var zones = [];
			var radiusInt = parseInt(this.page.sensitivity)
			var adjustment = radiusInt - 5
			var zone
			if (this.page.tagged_layout != null && this.page.tagged_layout != undefined) {
				var tagged_layout = JSON.parse(this.page.tagged_layout)
				for (var i = 0; i < tagged_layout.length; i++) {
					zone = tagged_layout[i]
					zone.x = tagged_layout[i].x - adjustment
					zone.w = tagged_layout[i].w + (2 * adjustment)
					zone.y = tagged_layout[i].y - adjustment
					zone.h = tagged_layout[i].h + (2 * adjustment)
					zones.push(zone)
				}
				this.setLayout(zones)
			}
		},
		
		/*
			Watch on page will cause this to be executed using the new page.
		*/
		loadMlPageMatchLayouts(){
			this.allMlLayouts = JSON.parse(this.page.ml_page_match_layout)
      this.mlLayoutsDocRefs = JSON.parse(this.page.ml_page_match_layout_ref_doc_key)
      this.mlLayoutsPageNoRefs = JSON.parse(this.page.ml_page_match_layout_ref_page_no)
		}
	
	}, 
	
	mounted(){
		this.loadMlPageMatchLayouts()
	}
	

}
</script>
<style lang="scss" scoped>
// .mb1 {
//   margin-bottom: 1px !important;
// }
// .full-height-sheet {
//   height: calc(90vh - 230x);
// }
// .full-height {
//   height: 90vh;
// }


.short-text {
	width: 50px;
	max-width: 50px;
}

.side-bar {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 300px;
	padding: 1px;
}

.tab-item-wrapper {
	/* vuetify sets the v-tabs__container height to 48px */
	height: calc(100vh - 48px)
}

.side-bar-tab {
	padding: 6px;

	&>* {
		font-size: 14px;

		.col {
			padding: 2px 10px;
			min-height: 55px;
		}

		.v-btn {
			margin: 10px;
		}
	}
}

.room-for-scrollbar {
	width: 97%;
}

.section-label {
	white-space: nowrap !important;
	margin-top: 10px;
}

.v-btn.v-size--small {
	margin-top: 1px;
	margin-right: 0px;
	height: 38px;
}

.theme--light.v-card.side-bar-bg {
	background-color: #f3f3f3;
}

.theme--dark.v-card.side-bar-bg {
	background-color: #3c3c3c;
}

.scrolling-tab {
	overflow-y: scroll;
	height: 100vh;
}

.scroll-margin-bottom {
	margin-bottom: 100px;
}

.btn-group-item {
	margin: 0 !important;
	padding: 0 !important;
}

.section-divider {
	margin-bottom: 5px;
	margin-top: -31px;
	padding-bottom: 20px;
}

.large-check {
	padding-bottom: 10px;

	>i {

		.mdi-checkbox-marked,
		.mdi-checkbox-blank-outline {
			font-size: 30px;
		}

	}
}

.theme--light.v-expansion-panels .v-expansion-panel {
	background-color: rgb(255 255 255 / 0%);
}

.v-expansion-panel::before {
	box-shadow: none;
}

.v-expansion-panel-header {
	padding: 0;
}
</style>

<style lang="scss">
.v-expansion-panel-content__wrap {
	padding: 0 !important;
}
</style>