<template>
  <v-tab-item key="properties">
    <v-container fluid class="pa-10">
      <v-row>
        <v-col cols="7">
          <v-row style="height: 65px">
            <v-col cols="3" class="text-right font-weight-bold">
              {{ $t("document.tabProperties.original") }}
            </v-col>
            <v-col cols="9">
              <!-- <a :href="document.original_doc">{{ document.name }}</a>
              <a :href="document.original_doc" style="text-decoration: none !important">
                <v-icon>mdi-download</v-icon> -->
              <a @click="downloadDocument(document)">{{ document.name }}</a>
              <!-- <a :href="document.original_doc" style="text-decoration: none !important">
                <v-icon>mdi-download</v-icon>
              </a> -->
              <a><v-icon medium class="mr-2" color="#003366" @click="downloadDocument(document)" :aria-label="$t('document.tabProperties.downloadOriginalDocument')">mdi-download</v-icon></a>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="text-right font-weight-bold">
              {{ $t("document.tabProperties.title") }}
            </v-col>
            <v-col cols="9">
              <v-text-field
                outlined
                dense
                v-model="document.title"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="text-right font-weight-bold">
              {{ $t("document.tabProperties.author") }}
            </v-col>
            <v-col cols="9">
              <v-text-field
                outlined
                dense
                v-model="document.author"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="text-right font-weight-bold">
              {{ $t("document.tabProperties.createdDate") }}
            </v-col>
            <v-col cols="9">
              {{ document.created_date }}
            </v-col>
          </v-row>

          <v-row v-if="document.modified_date">
            <v-col cols="3" class="text-right font-weight-bold">
              {{ $t("document.tabProperties.lastModificationDate") }}
            </v-col>
            <v-col cols="9">
              {{ document.modified_date }}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="text-right font-weight-bold">
               {{ $t("document.tabProperties.producer") }}
            </v-col>
            <v-col cols="9">
              {{ document.producer }}
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="1">

        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col cols="3" class="text-right font-weight-bold">
              <span id="language">{{ $t("document.tabProperties.language") }}</span>
            </v-col>
            <v-col cols="9">
              <v-select 
              outlined 
              dense
              :items=getLanguages()
              item-text="name"
              item-value="value"
              v-model="document.language"
							:aria-label="getLanguage"
							aria-labelledby="language"
							>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <LabelsDropdown :document="document" v-if="document" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"> </v-col>
            <v-col cols="9">
              <ValidatedProgress :document="document" v-if="document" />

            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              {{ $t("document.tabProperties.pages") }}
            </v-col>
            <v-col cols="9">
              <v-chip
              color="gray"
              label
              text-color="primary"
            >
              <v-icon left>
                mdi-file-document-outline
              </v-icon>
              {{ document.total_pages }}
            </v-chip>
              
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              {{ $t("document.tabProperties.images") }}
            </v-col>
            <v-col cols="3">
              <v-chip
                color="gray"
                label
                text-color="primary"
              >
                <v-icon left>
                  mdi-file-image
                </v-icon>
                {{ document.total_images }}
              </v-chip>

            </v-col>
            <v-col cols="4">
              <v-btn class="ma-2" color="primary" @click=saveDocument(document)>
                <v-icon class="mr-3">mdi-content-save-outline</v-icon>
                {{ $t("document.tabProperties.saveProperties") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="ma-5"/>

      <h4>{{ $t("document.tabProperties.metadata") }}</h4>

      <v-row>
        <v-col cols="3">
          {{ $t("document.tabProperties.metadataName") }}
        </v-col>
        <v-col cols="7">
          {{ $t("document.tabProperties.metadataValue") }}
        </v-col>
        <v-col cols="2">
          {{ $t("document.tabProperties.addDelete") }}
        </v-col>

      </v-row>

      <v-row v-for="item in metadatas" :key="item.key">
        <v-col cols="3">
           <v-text-field
                placeholder="Name (Alphanumeric only)"
                outlined
                dense
                v-model="item.name"
                @keypress="alphaNumericOnly"
                @paste.prevent
              ></v-text-field>
        </v-col>
        <v-col cols="7">
           <v-text-field
                outlined
                dense
                v-model="item.value"
              ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
            tile
            color="error"
            class="add-meta-button"
            @click="deleteMedata(item)"
						:aria-label="$t('document.tabProperties.deleteMetadata')"
          >
            <v-icon>
              mdi-delete-outline
            </v-icon>
          </v-btn>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="3">
           <v-text-field
                placeholder="Name (Alphanumeric only)"
                outlined
                dense
                v-model="newMetadata.name"
                @keypress="alphaNumericOnly"
                @paste.prevent
                ref="newMetadataName"
              ></v-text-field>
        </v-col>
        <v-col cols="7">
           <v-text-field
                placeholder="Value"
                outlined
                dense
                v-model="newMetadata.value"
              ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
            tile
            color="primary"
            class="add-meta-button"
            :disabled="newMetadata.name === '' || newMetadata.value === ''"
            @click="addNewMetadata"
						:aria-label="$t('document.tabProperties.addNewMetadata')"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>

      </v-row>

    </v-container>
  </v-tab-item>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import i18n from "@/plugins/i18n"
import ValidatedProgress from "@/components/ValidatedProgress"
import LabelsDropdown from "@/components/LabelsDropdown"
import StaticDataMixin from "@/mixins/staticData"

export default {
  props: ["document"],
  mixins: [StaticDataMixin],
  data() {
    return {
      newMetadata: { name: '', value: ''},
    }
  },
  components: {
    ValidatedProgress,
    LabelsDropdown
  },
  computed: {
    ...mapGetters({
      metadatas: "document/getMetadatas",
      labels: "user/getLabels"
    }),

		getLanguage(){
			var languages = StaticDataMixin.methods.getLanguages()
			var language = null
			console.log('languages is: ', languages)
			for(var i=0; i<languages.length;i++){
				if(languages[i].value == this.document.language){
					language = languages[i].name
					break
				}
			}
			return language
		}

  },  
  methods: {
    ...mapActions({
      fetchMetadatas: "document/fetchMetadatas",
      fetchLabels: "user/fetchLabels",
      deleteMetadata: "document/deleteMetadata",
      addMetadata: "document/addMetadata",
      saveDocumentProperties: "document/saveDocumentProperties",
    }),
    alphaNumericOnly(e) {
      let char = String.fromCharCode(e.keyCode) // Get the character
      if(/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault() // If not match, don't add to input text
    },
    saveDocument(doc) {
      this.saveDocumentProperties({
          payload: { document: doc }
      }).then(res => {
        this.$snackbar.showMessage({ content: this.$t("document.msg.documentSaved"), 
            color: 'info' })
      })
    },
    deleteMedata(metadataItem) {
      this.deleteMetadata({ payload: { key: this.$route.params.key, metadataKey: metadataItem.key } })
      .then((response) => {
        this.isLoading = false
        this.$snackbar.showMessage({ content: this.$t("document.tabProperties.msg.metadataRemoved", [metadataItem.name]), 
          color: 'info' })
      })
      .catch((error) => {
        this.isLoading = false
      })      
    },
    addNewMetadata() {
      if (this.newMetadata.name !== ''  && this.newMetadata.value !== '') {
        this.addMetadata({ payload: { key: this.$route.params.key, newMetadata: this.newMetadata } })
        .then((response) => {
          this.isLoading = false
          this.$snackbar.showMessage({ content: this.$t("document.tabProperties.msg.metadataSaved", [this.newMetadata.name]) , 
            color: 'info' })
          this.newMetadata = { name: '', value: ''}
          this.$refs.newMetadataName.focus()
        })
        .catch((error) => {
          this.isLoading = false
        })  
      }
    },
    downloadDocument(item) {
			console.log('in document download:', item)
			console.log('host url: ', process.env)
			window.open(process.env.VUE_APP_HOST_URL + '/download/pdf/' + item.key + '/' + encodeURI(item.name))
			
			/*
			this.key = item.doc_key
			this.name = item.doc_name
			this.fetchDocumentDownload({
				payload: { doc_key: this.key, doc_name: this.name }
			})
			*/
		},
  },
	
  mounted() {
    this.fetchMetadatas({ payload: { key: this.$route.params.key } })
      .then((response) => {
        this.isLoading = false
    })
    .catch((error) => {
      this.isLoading = false
    })

    this.fetchLabels()
  }

}
</script>
<style scoped>

.add-meta-button {
  height: 40px;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 8px;
  border-radius: 4px;
}
</style>
