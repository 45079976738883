<template>
  <v-sheet ref="pageArea" class="pa-5 fill-height">

    <v-card class="pa-2">
      <v-row class="mt-5">
        <v-col cols="2" class="ml-5">
          {{ $t("dashboard.groups") }}
        </v-col>
        <v-col cols="3">
          <v-select outlined dense 
          :items=groups 
          item-text="name" 
          item-value="key" 
          v-on:change="getReport" 
          v-model="selectedGroup">
          <option value="">{{ $t("dashboard.allGroups") }}</option>
          </v-select>
        </v-col>
        <v-col cols="2" offset="1">
          {{ $t("dashboard.activities") }}
        </v-col>
        <v-col cols="3">
          <v-select outlined dense 
          :items=reportTypes 
          item-text="name" 
          item-value="value" 
          v-on:change="getReport"
            v-model="selectedReportType">
          </v-select>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mt-20">
      <apexchart ref="chart" type="area" height="500" :options="chartOptions" :series="series"></apexchart>
    </v-card>

    <br/>

    <v-row>
      <v-col cols="3">
        
        <v-card>
          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              {{ $t("dashboard.users") }}
            </div>
                    
            <v-divider class="my-2"></v-divider>
            
            <div>
              <table>

                <tr class="center aligned">
                  <th scope="col"> {{ $t("dashboard.usersActive") }}</th>
                </tr>

                <tr class="center aligned" v-if="statsData">
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.users }}</td>
                </tr>
              </table>
            </div>

          </v-card-text>
        </v-card> 

      </v-col>
      <v-col cols="3">
        
        <v-card>
          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              {{ $t("dashboard.documents") }}
            </div>

            <v-divider class="my-2"></v-divider>
            
            <div>
              <table>

                <tr class="center aligned">
                  <th scope="col"> {{ $t("dashboard.documentsImported") }} </th>
                  <th scope="col"> {{ $t("dashboard.documentsActive") }} </th>
                  <th scope="col"> {{ $t("dashboard.documentsDeleted") }} </th>
                </tr>

                <tr class="center aligned" v-if="statsData">
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.documents_imported }}</td>
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.documents_active }}</td>
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.documents_deleted }}</td>
                </tr>
              </table>

            </div>
          </v-card-text>
        </v-card> 

      </v-col>
      
      <v-col cols="3">
        
        <v-card>
          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              {{ $t("dashboard.pages") }}
            </div>

            <v-divider class="my-2"></v-divider>
            
            <div>
              <table>

                <tr class="center aligned">
                  <th scope="col">{{ $t("dashboard.pagesActive") }} </th>
                  <th scope="col">{{ $t("dashboard.pagesModified") }} </th>
                  <th scope="col">{{ $t("dashboard.pagesValidated") }} </th>
                </tr>

                <tr class="center aligned" v-if="statsData">
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.pages_active }}</td>
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.pages_modified }}</td>
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.pages_validated }}</td>
                </tr>
              </table>
            </div>
          </v-card-text>
        </v-card> 

      </v-col>
      
      <v-col cols="3">
        
        <v-card>
          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              {{ $t("dashboard.progress") }}
            </div>

            <v-divider class="my-2"></v-divider>
            <div>
              <table>

                <tr class="center aligned">
                  <th scope="col"> {{ $t("dashboard.exportedPDF") }} </th>
                  <th scope="col"> {{ $t("dashboard.validatedPercentage") }} </th>
                </tr>

                <tr class="center aligned" v-if="statsData">
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.documents_exported }}</td>
                  <td v-if="statsData.total_stats">{{ statsData.total_stats.document_validated_percent }} %</td>
                </tr>
              </table>
            </div>

          </v-card-text>
        </v-card> 

      </v-col>                  
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  data() {
    return {
      selectedGroup: { key: null },
      selectedReportType: "tw",
      selectedView: "week",
      dayStats: null,
      chartHeight: 300,
      series: [],
    }
  },
  computed: {
    ...mapGetters({
      groups: "settings/getGroups",
      statsData: "stats/getStatsData",
      language: "language/getLanguage",
    }),
    reportTypes() {
      return [
        { value: "tw", name: this.$t("dashboard.reportTypes.thisWeek") },
        { value: "lw", name: this.$t("dashboard.reportTypes.lastWeek") },
        { value: "tm", name: this.$t("dashboard.reportTypes.thisMonth") },
        { value: "lm", name: this.$t("dashboard.reportTypes.lastMonth") },
        { value: "ty", name: this.$t("dashboard.reportTypes.thisYear") },
        { value: "ly", name: this.$t("dashboard.reportTypes.lastYear") },
        { value: "30", name: this.$t("dashboard.reportTypes.last30Days") },
        { value: "60", name: this.$t("dashboard.reportTypes.last60Days") },
        { value: "90", name: this.$t("dashboard.reportTypes.last90Days") },
        { value: "180", name: this.$t("dashboard.reportTypes.last180Days") },
        { value: "365", name: this.$t("dashboard.reportTypes.last365Days") },
        { value: "all", name: this.$t("dashboard.reportTypes.sinceOnline") },
      ]
    },
    chartOptions() {
      return {
        dataLabels: {
          enabled: false
        },
        chart: {
          toolbar: {
            show: false
          },          
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [
            this.$t("dashboard.months.jan"),
            this.$t("dashboard.months.feb"),
            this.$t("dashboard.months.mar"),
            this.$t("dashboard.months.apr"),
            this.$t("dashboard.months.may"),
            this.$t("dashboard.months.jun"),
            this.$t("dashboard.months.jul"),
            this.$t("dashboard.months.aug"),
            this.$t("dashboard.months.sep"),
            this.$t("dashboard.months.oct"),
            this.$t("dashboard.months.nov"),
            this.$t("dashboard.months.dec")
          ],
        },
        tooltip: {
          fixed: {
            enabled: false,
            position: 'topRight'
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchDayStats: "stats/fetchDayStats",
      fetchWeekStats: "stats/fetchWeekStats",
      fetchMonthStats: "stats/fetchMonthStats",
      fetchYearStats: "stats/fetchYearStats",
      fetchAllStats: "stats/fetchAllStats",
      fetchGroups: "settings/fetchGroups"
    }),

    getReport() {
      switch (this.selectedReportType) {
        case 'tw':
        case 'lw':
          this.getWeekData()
          break
        case 'tm':
        case 'lm':
          this.getMonthData()
          break
        case 'ty':
        case 'ly':
          this.getYearData()
          break
        case '30':
        case '60':
        case '90':
        case '180':
        case '365':
          this.getDailyData()
          break
        case 'all':
          this.getAllData()
          break
        default:
          this.getWeekData()
      }
    },

   
    updateData() {

      this.chartOptions.xaxis.categories = this.statsData.labels
      
      this.series = [{
        name: 'Documents',
        data: this.statsData.documents_data
      }, {
        name: 'New Users',
        data: this.statsData.user_data
      }, {
        name: 'Imported Pages',
        data: this.statsData.imported_data
      }, {
        name: 'Modified Pages',
        data: this.statsData.updated_data
      }, {
        name: 'Validated Pages',
        data: this.statsData.validated_data
      }, {
        name: 'Exported Documents',
        data: this.statsData.exported_doc_data
      }, {
        name: 'Exported Pages',
        data: this.statsData.exported_pages_data
      }]

      this.$refs.chart.refresh()
    },


    getDailyData() {
      this.selectedView = 'week'
      this.dayStats = this.fetchDayStats({
        payload:
        {
          reportType: this.selectedReportType,
          group: this.selectedGroup
        }
      })
        .then((response) => {
          this.updateData()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getWeekData() {
      this.selectedView = 'week'
      this.dayStats = this.fetchWeekStats({
        payload:
        {
          reportType: this.selectedReportType,
          group: this.selectedGroup
        }
      })
        .then((response) => {
          this.updateData()
        })
        .catch((error) => {
          console.log(error)
        })
    },

    getMonthData() {
      this.selectedView = 'month'
      this.dayStats = this.fetchMonthStats({
        payload:
        {
          reportType: this.selectedReportType,
          group: this.selectedGroup
        }
      })
        .then((response) => {
          this.updateData()
        })
        .catch((error) => {
          console.log(error)
        })
    },

    getYearData() {
      this.selectedView = 'year'
      this.dayStats = this.fetchYearStats({
        payload:
        {
          reportType: this.selectedReportType,
          group: this.selectedGroup
        }
      })
        .then((response) => {
          this.updateData()
        })
        .catch((error) => {
          console.log(error)
        })
    },

    getAllData(reportType) {
      this.selectedView = 'all'
      this.dayStats = this.fetchAllStats({
        payload:
        {
          reportType: this.selectedReportType,
          group: this.selectedGroup
        }
      })
        .then((response) => {
          this.updateData()
        })
        .catch((error) => {
          console.log(error)
        })
    },

  },
  mounted() {

    this.fetchGroups()
      .then((response) => {
        this.isLoading = false
        this.groups.unshift({name: this.$t("dashboard.allGroups"), key: null})
      })
      .catch((error) => {
        this.isLoading = false
      })

    this.getReport()

  },
}
</script>

<style>
table {
  width: 100%;
  text-align: center;
}
</style>