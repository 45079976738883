<template>
	<v-tab-item key="export" class="pa-10">
		<v-tabs v-model="exportTab" center-active show-arrows>
			<v-tab key="tabExportPDF">PDF
				<v-progress-circular class="ml-2" v-if="exportingPDF" :rotate="360" :size="35" :width="2"
					:value="pdfGeneration.progress" color="primary">
					{{ pdfGeneration.progress }}
				</v-progress-circular>
			</v-tab>
			<v-tab key="tabExportHtml">Html</v-tab>
			<v-tab key="tabExportEpub">EPub</v-tab>
			<v-tab key="tabExportZones">Zones</v-tab>
		</v-tabs>

		<v-tabs-items v-model="exportTab">
			<!-- Export pdf -- -- -- -- -- -- -- -- -- --- -- -- -- -->
			<v-tab-item key="tabExportPDF" class="pa-5 ma-5">
				<template v-if="!exportingPDF">
					<v-row>
						<v-col>
							<h3>{{ $t("document.tabExport.pdfDocument") }}</h3>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							{{ $t("document.tabExport.selectPageToDownload") }}
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="3">

							<v-radio-group v-model="pdfOptions.pageSelection" row class="radioGroup">
								<v-radio :label="$t('document.tabExport.allPages')" value="All">
								</v-radio>

								<v-radio label="" value="range" @click="focusPDFRange()">
								</v-radio>

								<v-text-field ref="pdfRange" v-model="pdfOptions.pageRangeSelected"
									:disabled="pdfOptions.pageSelection === 'All'" class="select-pages" placeholder="Example: 1-5, 8, 11-13"
									outlined dense>
								</v-text-field>
							</v-radio-group>

						</v-col>
						<v-col cols="2">
							<v-checkbox class="mt-0" :label="$t('document.tabExport.fixNoSpace')"
								v-model="pdfOptions.fixNoSpace"></v-checkbox>
						</v-col>
						<v-col cols="2">
							<v-checkbox class="mt-0" :label="$t('document.tabExport.flattenFormX')"
								v-model="pdfOptions.flattenXForms"></v-checkbox>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="2">
							{{ $t("document.tabExport.filename") }}
						</v-col>
						<v-col cols="6">
							<v-text-field ref="pdfFilename" outlined dense v-model="pdfOptions.filename"></v-text-field>
						</v-col>
					</v-row>

					<!-- selection for output engine here-->
					<v-row v-if="options.eq_pdf_engine">
						<v-col cols="3">
							<v-label id="pdf-output-engine">{{$t('document.tabExport.pdfOutputEngine')}}</v-label>
							<v-select v-model="pdfOptions.outputEngine" :items="outputEngineSelect" item-text="name" item-value="id"
							aria-labelledby="pdf-output-engine"></v-select>
						</v-col>
					</v-row>

					<!--New row for showing exported file link-->
					<v-row v-if="pdfOutputStatus == 2" class="mt-0 mb-2">
						<v-col cols="8">
							<span style="font-size:.9em;">Created on: {{ format_date(pdfOutputTs) }} &nbsp;</span>

							<v-btn text color="primary" @click="downloadPdf" class="pb-1">
								{{ pdfOutputFilename }}
								<v-icon class="ml-2">
									mdi-download-box
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>

					<v-row>
						<v-btn class="ml-3" color="primary" elevation="2" @click=generatePDF>
							{{ $t("document.tabExport.generatePdf") }}
						</v-btn>
					</v-row>

					<!--We need to show the errors post export-->
					<div v-if="pdfGeneration.errors.length > 0" style="font-size: .9em;">
						<v-card class="mt-8 errors" outlined>
							<v-row class="ml-3 mt-1">
								<v-col>
									The document might not be valid for the following reason(s):
								</v-col>
							</v-row>

							<v-row class="ml-3 mt-0">
								<v-col>
									<ul>
										<li v-for="(error, w) in pdfGeneration.errors" :key="w">
											{{ error.msg }}
											<p>
												{{ error.error_info }}
											</p>
										</li>
									</ul>
								</v-col>
							</v-row>
						</v-card>
					</div>

				</template>
				<!-- This is shown while exporting the pdf -->
				<template v-if="exportingPDF">
					<v-row>
						<v-col>
							Generating PDF file: {{ pdfGeneration.filename }}
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-progress-linear v-model="pdfGeneration.progress" color="blue-grey" height="25">
								<template v-slot:default="{ value }">
									<strong>{{ Math.ceil(value) }}%</strong>
								</template>
							</v-progress-linear>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							{{ pdfGeneration.message }}
						</v-col>
					</v-row>

					<div v-if="pdfGeneration.errors.length > 0" style="font-size: .9em;">
						<v-card class="mt-8 errors" outlined>
							<v-row class="ml-3 mt-3">
								<v-col>
									The document might not be valid for the following reason(s):
								</v-col>
							</v-row>

							<v-row class="ml-3 mt-0">
								<v-col>
									<ul>
										<li v-for="(error, w) in pdfGeneration.errors" :key="w">
											{{ error.msg }}
											<p>
												{{ error.error_info }}
											</p>
										</li>
									</ul>
								</v-col>
							</v-row>
						</v-card>
					</div>
				</template>

			</v-tab-item>

			<!-- Export html -- -- -- -- -- -- -- -- -- --- -- -- -- -->
			<v-tab-item key="tabExportHtml" class="pa-5 ma-5">
				<v-row>
					<v-col>
						<h3>{{ $t("document.tabExport.htmlDocument") }}</h3>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						{{ $t("document.tabExport.selectPageToDownload") }}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="5">
						<v-radio-group v-model="htmlOptions.pageSelection" row class="radioGroup">
							<v-radio :label="$t('document.tabExport.allPages')" value="All">
							</v-radio>

							<v-radio label="" value="selected" @click="focusHtmlRange()">
							</v-radio>

							<v-text-field ref="htmlRange" v-model="htmlOptions.pageRangeSelected"
								:disabled="htmlOptions.pageSelection === 'All'" class="select-pages" placeholder="Example: 1-5, 8, 11-13"
								outlined dense>
							</v-text-field>
						</v-radio-group>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="1">
						{{ $t("document.tabExport.filename") }}
					</v-col>
					<v-col cols="6">
						<v-text-field v-model="htmlOptions.filename" outlined dense>
						</v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="3">
						<v-checkbox :label="$t('document.tabExport.generatePageIDS')" v-model="htmlOptions.pageIds"></v-checkbox>
					</v-col>
					<v-col cols="1" class="text-right font-weight-bold">
						{{ $t("document.tabExport.css") }}
					</v-col>
					<v-col cols=3>
						<v-select outlined dense :items=getCSS item-text="name" item-value="url" v-model="htmlOptions.css_url">
						</v-select>
					</v-col>
				</v-row>

				<v-row>
					<v-btn class="mr-5" color="primary" elevation="2" @click="previewHtml" :disabled="generatingHtml == true">
						{{ $t("document.tabExport.previewHtml") }}
					</v-btn>
					<v-btn color="primary" elevation="2" @click="downloadHtml" :disabled="generatingHtml == true">
						{{ $t("document.tabExport.downloadHtml") }}
					</v-btn>
				</v-row>
			</v-tab-item>

			<!-- Export epub -- -- -- -- -- -- -- -- -- --- -- -- -- -->
			<v-tab-item key="tabExportEpub" class="pa-5 ma-5">
				<v-row>
					<v-col>
						<h3>{{ $t("document.tabExport.epubDocument") }}</h3>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						{{ $t("document.tabExport.selectPageToDownload") }}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="5">
						<v-radio-group v-model="epubOptions.pageSelection" row class="radioGroup">

							<v-radio :label="$t('document.tabExport.allPages')" value="All"></v-radio>

							<v-radio label="" value="selected" @click="focusEpubRange()">
							</v-radio>

							<v-text-field class="select-pages" outlined dense v-model="epubOptions.pageRangeSelection"
								:disabled="epubOptions.pageSelection === 'All'" ref="epubRange"
								placeholder="Example: 1-5, 8, 11-13"></v-text-field>
						</v-radio-group>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="1">
						{{ $t("document.tabExport.filename") }}
					</v-col>
					<v-col cols="6">
						<v-text-field outlined dense v-model="epubOptions.filename"></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-btn class="mr-5" color="primary" elevation="2" @click="downloadEpub" :disabled="generatingEpub == true">
						{{ $t("document.tabExport.downloadEpub") }}
					</v-btn>
				</v-row>
			</v-tab-item>
			<!-- Need Zone export here-->
			<v-tab-item key="tabExportZones" class="pa-5 ma-5">
				<v-row>
					<v-col>
						<h3>{{ $t("document.tabExport.documentZones") }}</h3>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						{{ $t("document.tabExport.selectPageToDownload") }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="5">
						<v-radio-group v-model="zoneOptions.pageSelection" row class="radioGroup">
							<v-radio :label="$t('document.tabExport.allPages')" value="All">
							</v-radio>
							<v-radio label="" value="selected" @click="focusZoneRange()">
							</v-radio>
							<v-text-field ref="zoneRange" v-model="zoneOptions.pageRangeSelected"
								:disabled="zoneOptions.pageSelection === 'All'" class="select-pages" placeholder="Example: 1-5, 8, 11-13"
								outlined dense>
							</v-text-field>
						</v-radio-group>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="1">
						{{ $t("document.tabExport.filename") }}
					</v-col>
					<v-col cols="6">
						<v-text-field ref="zoneFilename" v-model="zoneOptions.filename" outlined dense>
						</v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn color="primary" elevation="2" @click="downloadZones" :disabled="generatingZones == true">
							{{ $t("document.tabExport.downloadZones") }}
						</v-btn>
					</v-col>
				</v-row>
			</v-tab-item>
		</v-tabs-items>
	</v-tab-item>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import i18n from "@/plugins/i18n"
import EventBus from "@/eventBus"
import moment from 'moment'

export default {
	props: ["document"],
	data() {
		return {
			outputEngineSelect:[{id: 'default', name: 'Default'},
													{id: 'wcag21', name: 'WCAG 2.1 Beta'}],
			MAX_PAGES: 30,
			exportTab: "tabExportPDF",
			generatingHtml: false,
			generatingEpub: false,
			generatingPdf: false,
			generatingZones: false,
			htmloutData: null,
			epuboutData: null,
			eventHtml: false,
			eventEpub: false,
			pdfOutputStatus: null,
			pdfOutputFilename: null,
			pdfOutputTs: null,
			htmlOptions: {
				pageSelection: "All",
				pageIds: false,
				pageRangeSelected: "",
				filename: "",
				preview: true,
				css_url: null,
			},
			pdfOptions: {
				pageSelection: "All",
				pageIds: false,
				pageRangeSelected: "",
				fixNoSpace: false,
				flattenXForms: false,
				filename: "",
				outputEngine: 'default',
			},
			pdfGeneration: {
				filename: undefined,
				message: undefined,
				progress: 25,
				errors: []
			},
			epubOptions: {
				pageSelection: "All",
				pageRangeSelection: "",
				filename: ""
			},
			zoneOptions: {
				pageSelection: "All",
				pageRangeSelected: "",
				filename: ""
			},
		}
	},
	components: {},
	computed: {
		...mapGetters({
			exportingPDF: "document/getExportingPDF",
			getCSS: "document/getCSS",
			options: "application/getOptions",
		}),
	},
	methods: {
		...mapActions({
			fetchPDFOutputStatus: "document/fetchPDFOutputStatus",
			generatePDFOutput: "document/generatePDFOutput",
			htmlPreview: "document/htmlPreview",
			htmlDownload: "document/htmlDownload",
			epubDownload: "document/epubDownload",
			fetchCSS: "document/fetchCSS",
		}),
		format_date(value) {
			if (value) {
				return moment(String(value)).format('MM/DD/YYYY, hh:mm A')
			}
		},
		focusHtmlRange() {
			this.$nextTick(function () {
				this.$refs.htmlRange.focus()
			})
		},
		focusPDFRange() {
			this.$nextTick(function () {
				this.$refs.pdfRange.focus()
			})
		},
		focusEpubRange() {
			this.$nextTick(function () {
				this.$refs.epubRange.focus()
			})
		},
		focusZoneRange() {
			this.$nextTick(function () {
				this.$refs.zoneRange.focus()
			})
		},
		paramSerializer(params) {
			if (!params) return ''
			var parts = []
			for (var key in params) {
				const value = params[key]
				//don't add anything without a value
				if (value != null && value != "" && Object.prototype.hasOwnProperty.call(params, key)) {
					if (Array.isArray(value)) {
						Array.forEach(value, function (v) {
							parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(v))
						});
					} else {
						parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
					}

				}
			}
			return parts.join('&');
		},
		//download exported pdf
		downloadPdf() {
			window.open(process.env.VUE_APP_HOST_URL + '/download/output/pdf/' + this.document.key + '/' + this.pdfOutputFilename, '_new')
		},
		//in original function, document and htmlOptions are passed.  this isn't necessary here.
		//this handles large documents
		generateDownloadHtml() {
			console.log('in generate')
			this.generatingHtml = true
			this.htmloutData = null
			var paramFilename = ''

			if (this.htmlOptions.filename !== undefined && this.htmlOptions.filename != '') {
				paramFilename = encodeURIComponent(this.htmlOptions.filename)
			}
			else {
				paramFilename = encodeURIComponent(this.document.name.substr(0, this.document.name.lastIndexOf(".")) + ".html")
				console.log('document filename:', this.document.name)
				console.log('paramFilename: ', paramFilename)
			}

			//inner function
			var progressFn = function () {
				EventBus.$on('htmlout/progress', (msg) => {
					console.log('generate download html message: ', msg.data.msg)
				})
			}

			if (!this.eventHtml) {
				this.eventHtml = true

				EventBus.$on('html_ready', (msg) => {
					console.log('in eventbus on html_ready')
					progressFn()
					if (this.htmlOptions.preview !== undefined && this.htmlOptions.preview) {
						setTimeout(function () {
							window.open(process.env.VUE_APP_HOST_URL + '/preview/output/html/' + this.document.key + '/' + encodeURI(msg.filename), '_new')
						}.bind(this), 500)
					}
					else {
						setTimeout(function () {
							window.open(process.env.VUE_APP_HOST_URL + '/download/output/html/' + this.document.key + '/' + encodeURI(msg.filename), '_new')
						}.bind(this), 500)
					}

					this.generatingHtml = false
					this.htmloutData = null
					this.eventHtml = false
				})
			}

			var sendOptions = Object.assign({}, this.htmlOptions)
			sendOptions.htmlFilename = paramFilename
			sendOptions.preview = this.htmlOptions.preview

			if (this.htmlOptions.preview !== null && this.htmlOptions.preview) {
				//post preview method
				this.htmlPreview({
					payload: {
						doc_key: this.document.key,
						sendOptions: sendOptions,
					}
				})
					.then((results) => {

					}).catch((error) => {
						this.$snackbar.showMessage({
							content: this.$t("document.tabExport.msg.errorHtml"),
							color: 'error'
						})
					})

			}
			else {
				//post download method
				this.htmlDownload({
					payload: {
						doc_key: this.document.key,
						sendOptions: sendOptions,
					}
				})
					.then((results) => {

					}).catch((error) => {
						this.$snackbar.showMessage({
							content: this.$t("document.tabExport.msg.errorHtml"),
							color: 'error'
						})
					})
			}



		},
		previewHtml() {
			this.generatingHtml = true
			this.htmlOptions.preview = true
			const params = this.paramSerializer(this.htmlOptions)

			if (this.document.total_pages > this.MAX_PAGES) {
				this.generateDownloadHtml()
			}
			else {
				setTimeout(function () {
					window.open(process.env.VUE_APP_HOST_URL + '/preview/' + this.document.key + '?' + params, '_new')
					this.generatingHtml = false
				}.bind(this), 500)

			}
		},
		errorExists(arr, msg) {
			return arr.some(function (arrVal) {
				return msg === arrVal.msg
			})
		},
		downloadHtml() {
			this.generatingHtml = true
			this.htmlOptions.preview = false
			var paramFilename = null
			if (this.document.total_pages > this.MAX_PAGES) {
				this.generateDownloadHtml()
			}
			else {
				if (this.htmlOptions.filename !== undefined && this.htmlOptions.filename != '') {
					paramFilename = encodeURIComponent(this.htmlOptions.filename)
				}
				else {
					paramFilename = encodeURIComponent(this.document.name.substr(0, this.document.name.lastIndexOf(".")) + ".html")
				}

				var sendOptions = Object.assign({}, this.htmlOptions)
				sendOptions.htmlFilename = paramFilename
				var params = this.paramSerializer(sendOptions)
				window.open(process.env.VUE_APP_HOST_URL + '/download/' + this.document.key + '?' + params, '_new')
				this.generatingHtml = false
			}
		},
		downloadEpub() {
			this.generatingEpub = true
			this.epuboutData = null
			var paramFilename = null
			if (this.epubOptions.filename !== undefined && this.epubOptions.filename != '') {
				paramFilename = encodeURIComponent(this.epubOptions.filename)
			}
			else {
				paramFilename = encodeURIComponent(this.document.name.substr(0, this.document.name.lastIndexOf(".")) + ".html")
			}

			var progressFn = function () {
				EventBus.$on("epubout/progress", (msg) => {
					this.epuboutData = msg.data
				})
			}

			if (!this.eventEpub) {
				this.eventEpub = true

				EventBus.$on("epub_ready", (msg) => {
					progressFn()
					window.open(process.env.VUE_APP_HOST_URL + '/download/output/epub/' + this.document.key + '/' + encodeURI(paramFilename), '_new')
					this.generatingEpub = false
					this.epuboutData = null
					this.eventEpub = false

					//clear state
					this.epubOptions.filename = ""
					this.epubOptions.pageSelection = "All"
					this.epubOptions.pageRangeSelection = ""
				})
			}

			var sendOptions = Object.assign({}, this.epubOptions)
			sendOptions.EPubFilename = paramFilename

			this.epubDownload({
				payload: {
					doc_key: this.document.key,
					sendOptions: sendOptions,
				}
			}).then((results) => {

			}).catch((error) => {

			})
		},
		downloadZones() {
			var zonePages = null
			var zonePageSelection = "All"
			if (this.zoneOptions.pageSelection != "All") {
				zonePageSelection = "range"
				zonePages = this.zoneOptions.pageRangeSelected
			}
			if (this.zoneOptions.pageSelection == "All" || zonePages != null) {
				this.generatingZones = true
				window.open(process.env.VUE_APP_HOST_URL + '/api/v1/documents/' + this.document.key + '/page_layouts' +
					'/?pageSelectionZones=' + zonePageSelection +
					'&pageRangeSelectedZones=' + encodeURIComponent(zonePages) +
					'&zonesFilename=' + encodeURIComponent(this.zoneOptions.filename), '_new')
				this.generatingZones = false
				//clear state
				this.zoneOptions.filename = ""
				this.zoneOptions.pageSelection = "All"
				this.zoneOptions.pageRangeSelected = ""
			}
		},
		getPDFOutputStatus() {
			this.fetchPDFOutputStatus({
				payload: {
					key: this.$route.params.key
				}
			}).then((response) => {
				console.log('in getPDFOutputStatus.  response is: ', response.data)
				this.pdfOutputStatus = response.data.pdf_output_status
				this.pdfOutputFilename = response.data.pdf_output_filename
				this.pdfOutputTs = response.data.pdf_output_ts
			}).catch((error) => {

			})
		},
		generatePDF() {
			this.pdfGeneration.message = ''
			this.pdfGeneration.progress = 0
			this.pdfGeneration.errors = []

			if (this.pdfOptions.filename !== undefined && this.pdfOptions.filename != '') {
				if (this.pdfOptions.filename.match(/^.*[\\\/]/)) { // eslint-disable-line
					this.pdfOptions.filenameError = true
					this.pdfOptions.filenameErrorMessage = 'Filename contains invalid characters'
					this.$refs.pdfFilename.focus()
					return
				}
				this.pdfOptions.filenameError = false
				this.pdfOptions.filenameErrorMessage = undefined
				this.pdfGeneration.filename = encodeURIComponent(this.pdfOptions.filename)
			}
			else {
				this.pdfGeneration.filename = encodeURIComponent(this.document.name.substr(0, this.document.name.lastIndexOf(".")) + ".pdf")
			}

			EventBus.$on('pdfout/progress', (ws) => {
				console.log(ws)
				this.pdfGeneration.message = ws.data.msg
				this.pdfGeneration.progress = ws.data.progress
			})

			EventBus.$on('pdfout/error', (msg) => {
				console.log('pdfout/error =====>>>>>!', msg)
				if (!this.errorExists(this.pdfGeneration.errors, msg.data.msg)) {
					this.pdfGeneration.errors.push(msg.data)
				}
			})

			EventBus.$on('pdf_ready', (msg) => {
				EventBus.$off('pdfout/progress')
				EventBus.$off('pdfout/error')
				EventBus.$off('pdf_ready')
				window.open(process.env.VUE_APP_HOST_URL + '/download/output/pdf/' + this.document.key + '/' + encodeURI(this.document.name), '_new')
				this.$store.commit("document/SET_EXPORTING_PDF", false)
				this.getPDFOutputStatus()
			})

			var options = {
				filename: this.pdfGeneration.filename,
				pageSelectionPDF: this.pdfOptions.pageSelection,
				pageRangeSelectedPDF: encodeURIComponent(this.pdfOptions.pageRangeSelected),
				loadText: this.pdfOptions.fixNoSpace,
				flatten_xforms: this.pdfOptions.flattenXForms,
				pdf_engine: this.pdfOptions.outputEngine,
			}

			this.generatePDFOutput({ payload: { key: this.$route.params.key, options } })
				.then((response) => {
					console.log('Generating pdf response', response)
					this.pdfGeneration.message = 'Extracting zones, please wait'
					this.pdfGeneration.progress = 2
				})
				.catch((error) => {

					if (error.status !== undefined && error.status === 400) {
						this.$snackbar.showMessage({
							content: this.$t("document.tabExport.msg.unableToExport"),
							color: 'error'
						})
					}
					else {
						this.$snackbar.showMessage({
							content: this.$t("document.tabExport.msg.errorPDF"),
							color: 'error'
						})
					}

				})

		}
	},
	mounted() {
		EventBus.$on('GENERATE_PDF', (msg) => {
			// This can be triggered from a parent or child component
			this.generatePDF()
		})

		EventBus.$on('SOCKET_MSG', (msg) => {
			console.log('SOCKET MESSAGE HERE!', msg)
		})

		this.fetchCSS()
	}
}
</script>
<style lang="scss" scoped>
.v-tab {
	border: 1px solid lightgray;
	font-weight: bold;
	color: var(--v-primary-base);
	min-width: 200px;
}

.v-tabs-slider-wrapper {
	min-width: 200px;
}

.v-tabs-items {
	border: 1px solid lightgray;
}

.v-tab--active {
	background-color: lightgray;
}

.radioGroup {
	height: 50px !important;
	margin: 0 !important;
	padding: 0 !important;
}

.v-radio {
	align-items: baseline;
}

.filename {
	text-align: right;
}

.errors {
	background-color: #FFF6F6;
	color: #9F3A38;
	border-color: #9F3A38;
}
</style>
