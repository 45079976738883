<template>
  <v-sheet class="pa-5">
    <h2>Help</h2>
  </v-sheet>

</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      user: "user/userProfile",
    }),
  },
}
</script>
<style lang="scss" scoped>

</style>
