<template>
	<v-app-bar id="editorToolBar" color="base" class="mb1 custom-top toolbar-border" app extension-height="66px"
		elevation="0" height=66 style="border: thin solid rgba(0, 0, 0, 0.12) !important;">

		<!-- Show until screen size reduced -->
		<v-toolbar-items class="hidden-md-and-down">
			<GoToPageArrows :document="document" :pageNo="page.page_no" />

			<v-card class="d-flex flex-column align-content-center" min-width="175" outlined tile>
				<span class="custom-label" style="">{{ $t('page.toolbar.sensitivity') }} {{ page.sensitivity }}</span>
				<v-slider class="pa-3" min="0" max="20" step="1" style="margin-top: -8px;" v-model="page.sensitivity"
					@input="changeSensitivity">
				</v-slider>
			</v-card>

			<!-- old version
			<v-card class="align-content-center pl-2 pr-2" style="min-width:365px;" outlined tile>
 
				<span class="float-left mt-6 ml-2 mr-3" >{{ $t('page.toolbar.readingOrder') }}</span>

				<div class="d-flex align-center align-content-center flex-no-wrap pl-2 pr-2 mt-4 mr-1">

					<v-select class="float-left pr-2 mr-3" style="max-width:140px;" v-model="selectedReadingOrder"
						:items="$t('page.toolbar.readingOrders')" solo dense />
					<v-btn small class="float-right" style="margin-top: -22px;" color="primary" @click="clickReorder">
						{{ $t('page.toolbar.reOrder') }}
					</v-btn>
				</div>
			</v-card>
			-->

			<v-card class="d-flex align-center align-content-center flex-no-wrap pa-2" style="" outlined tile>

				<span class="pr-2 mr-3">{{ $t('page.toolbar.readingOrder') }}</span>

				<v-select class="float-left pr-2 mr-3 mt-6" style="max-width:140px;" v-model="selectedReadingOrder"
					:items="$t('page.toolbar.readingOrders')" solo dense />
				<v-btn small class="float-right mr-2" color="primary" @click="clickReorder">
					{{ $t('page.toolbar.reOrder') }}
				</v-btn>

			</v-card>

		</v-toolbar-items>

		<!-- Hidden until screen size reduced -->
		<v-toolbar-items class="hidden-lg-and-up" style="border: thin solid rgba(0, 0, 0, 0.12) !important;">
			<v-menu bottom right :close-on-content-click="false">
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="primary" icon v-bind="attrs" v-on="on">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>

				<v-card min-width="300">
					<GoToPageArrows :document="document" :pageNo="page.page_no" />
				</v-card>

				<v-card class="d-flex align-center align-content-center flex-no-wrap pl-4 pr-2" min-width="300" outlined tile>
					<div style="margin-top: -6px;">{{ $t('page.toolbar.sensitivity') }} {{ page.sensitivity }}</div>

					<v-slider class="mt-2 pa-2" min="0" max="20" step="1" v-model="page.sensitivity" @input="changeSensitivity">
					</v-slider>
				</v-card>

				<v-card class="d-flex align-center align-content-center flex-no-wrap pl-2 pr-2" outlined tile>
					{{ $t('page.toolbar.readingOrder') }}
					<v-select class="mt-6 ml-5 mr-5 select-width" v-model="selectedReadingOrder"
						:items="$t('page.toolbar.readingOrders')" solo dense />

					<v-btn small color="primary" @click="clickReorder">
						{{ $t('page.toolbar.reOrder') }}
					</v-btn>
				</v-card>

				<v-card class="d-flex align-center align-content-center flex-no-wrap pa-3" outlined tile>
					<v-btn small tile @click=clickAddZone>
						<v-icon>mdi-plus-thick</v-icon>
						{{ $t('page.toolbar.extension.newZone') }}
					</v-btn>
				</v-card>

			</v-menu>
		</v-toolbar-items>
		<!-- End hidden -->

		<v-card class="d-flex align-content-center flex pa-1" outlined tile height=66  style="border-right: none;">
			<v-toolbar-items class="pa-3 flex justify-space-between" style="max-width:700px !important;">

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="clickUndo" :disabled="!canUndo">
							<v-icon v-bind="attrs" v-on="on">mdi-undo</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.undo') }}</span>
				</v-tooltip>

				<!-- we don't have a redo function -->
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="clickRedo" :disabled="!canRedo">
							<v-icon v-bind="attrs" v-on="on">mdi-redo</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.redo') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="toggleZoneInfo">
							<v-icon v-bind="attrs" v-on="on">mdi-circle-half-full</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.toggleZoneInfo') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="clickSavePage">
							<v-icon v-bind="attrs" v-on="on">mdi-content-save-outline</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.save') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple :class="page.validated ? 'green-button' : ''" @click="clickMarkDone">
							<v-icon v-bind="attrs" v-on="on">{{page.validated ? 'mdi-check-underline' : 'mdi-check'}}</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.validate') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="clickPreviewPage">
							<v-icon v-bind="attrs" v-on="on">mdi-monitor</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.preview') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="clickFixedPreviewPage">
							<v-icon v-bind="attrs" v-on="on">mdi-monitor-dashboard</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.fixedPreview') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="clickZoomIn">
							<v-icon v-bind="attrs" v-on="on">mdi-magnify-plus-outline</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.zoomIn') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="clickZoomOut">
							<v-icon v-bind="attrs" v-on="on">mdi-magnify-minus-outline</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.zoomOut') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span class="pr-3 pl-1" v-on="on" v-bind="attrs">
							<Shortcuts />
						</span>
					</template>
					<span>{{ $t('page.toolbar.shortcuts') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn large icon :ripple=defaultRipple @click="clickClose">
							<v-icon v-bind="attrs" v-on="on">mdi-close</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('page.toolbar.close') }}</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-switch	class="mt-2" color="indigo" v-model="showToolbar" v-bind="attrs" v-on="on" dense
							@change="toggleShowSecondToolbar">
						</v-switch>
					</template>
					<span>Show extended toolbar</span>
				</v-tooltip>

			</v-toolbar-items>
		</v-card>

		<!-- Second Toolbar -->
		<template v-slot:extension v-if="showToolbar">

			<v-toolbar-items v-if="selectedZone == null">
				<v-card class="d-flex align-center align-content-center flex-no-wrap pt-1 pl-4 pr-6" outlined tile>
					<v-btn small tile @click=clickAddZone>
						<v-icon>mdi-plus-thick</v-icon>
						{{ $t('page.toolbar.extension.newZone') }}
					</v-btn>
				</v-card>
			</v-toolbar-items>

			<!-- Show until screen size reduced -->
			<v-toolbar-items v-if="selectedZone != null && !isContrastZone(selectedZone)" class="hidden-md-and-down">
				<v-card class="d-flex align-center align-content-center flex-no-wrap pt-1 pl-4 pr-6" outlined tile>
					<v-btn small tile @click=clickAddZone class="mr-n2">
						<v-icon>mdi-plus-thick</v-icon>
						{{ $t('page.toolbar.extension.newZone') }}
					</v-btn>
				</v-card>

				<v-card v-if="selectedZone && !isContrastZone(selectedZone)" class="d-flex align-center align-content-center flex-no-wrap pt-1 pl-4 pr-6" outlined
					tile>
					{{ $t('page.toolbar.extension.order') }}
					<v-text-field class="short-text mt-6 ml-5" v-model="selectedZone.order" dense solo></v-text-field>

					<v-btn small color="primary" dense class="ml-4 mr-n2" @click="clickRemoveZone"
						v-if="selectedZone.type !== 'image' && selectedZone.type !== 'field'">
						{{ $t('page.toolbar.extension.removeZone') }}
					</v-btn>
				</v-card>
			</v-toolbar-items>

			<!-- Hide until screen size reduced -->
			<v-toolbar-items v-if="selectedZone != null && !isContrastZone(selectedZone)" class="hidden-lg-and-up"
				style="border: thin solid rgba(0, 0, 0, 0.12) !important;">
				<v-menu bottom right :close-on-content-click="false">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" icon v-bind="attrs" v-on="on">
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>

					<v-card class="d-flex align-center align-content-center flex-no-wrap pt-1 pl-4 pr-6" outlined tile
						style="height:50px;">
						<v-btn small tile @click=clickAddZone>
							<v-icon>mdi-plus-thick</v-icon>
							{{ $t('page.toolbar.extension.newZone') }}
						</v-btn>
					</v-card>

					<v-card v-if="selectedZone" class="d-flex align-center align-content-center flex-no-wrap pt-1 pl-4 pr-6"
						outlined tile>
						{{ $t('page.toolbar.extension.order') }}
						<v-text-field class="short-text mt-6 ml-5" v-model="selectedZone.order" dense solo></v-text-field>

						<v-btn small color="primary" dense class="ml-4" @click="clickRemoveZone" v-if="selectedZone.type !== 'image'">
							{{ $t('page.toolbar.extension.removeZone') }}
						</v-btn>
					</v-card>
				</v-menu>
			</v-toolbar-items>

			<v-toolbar-items>
				<v-card v-if="selectedZone && isContrastZone(selectedZone)" class="d-flex align-center align-content-center flex-no-wrap pa-2" outlined tile
					>
					<v-switch dense v-model="showContrastZones" class="mt-4" label="Show Contrast Zones"
						@change="toggleShowContrastZones"></v-switch>
					<v-switch dense v-model="showOtherZones" class="mt-4 ml-4" label="Show Other Zones"
						@change="toggleShowOtherZones"></v-switch>
				</v-card>
			</v-toolbar-items>

			<v-toolbar-items>
				<v-card v-if="selectedZone && !isContrastZone(selectedZone)" class="d-flex align-center align-content-center flex-no-wrap pa-2" outlined tile>
					<span class="pr-2 mr-3"> {{ $t('page.toolbar.extension.zoneSource') }} </span>

					<v-btn-toggle v-model="selectedZone.source" @change="sourceChanged">
						<v-btn class="btn-group-item mr-2" value="PDF">
							<v-icon x-large>
								$vuetify.icons.SourcePDF
							</v-icon>
						</v-btn>

						<v-btn class="btn-group-item mr-2" value="OCR">
							<v-icon x-large>
								$vuetify.icons.SourceOCR
							</v-icon>
						</v-btn>

						<v-btn class="btn-group-item mr-2" value="Custom">
							<v-icon x-large>
								$vuetify.icons.SourceCustom
							</v-icon>
						</v-btn>

						<v-btn class="btn-group-item mr-2" value="Actual" v-if="selectedZone.type !== 'list'">
							<v-icon x-large>
								$vuetify.icons.SourceActual
							</v-icon>
						</v-btn>
					</v-btn-toggle>

				</v-card>

				<v-card v-if="selectedZone && !isContrastZone(selectedZone)" class="d-flex align-center align-content-center justify-space-between flex-no-wrap pa-2" outlined tile >
					<span>{{ $t('page.toolbar.extension.width') }}</span>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field class="short-text mt-6 ml-3 mr-3" v-model="selectedZone.w" type="number" min="1" max="9999"
								step="1" dense solo :readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"
								@input="updateWidth" v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<span>{{ $t('page.sidebarZone.zonePosition') + ' ' + $t('page.toolbar.extension.width') }}</span>
					</v-tooltip>

					<span>{{ $t('page.toolbar.extension.height') }}</span>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field class="short-text  mt-6 ml-3 mr-3" v-model="selectedZone.h" type="number" min="1" max="9999"
								step="1" dense solo :readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"
								@input="updateHeight" v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<span>{{ $t('page.sidebarZone.zonePosition') + ' ' + $t('page.toolbar.extension.height') }}</span>
					</v-tooltip>

					<span>{{ $t('page.toolbar.extension.top') }}</span>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field class="short-text  mt-6 ml-3 mr-3" v-model="selectedZone.y" type="number" min="1" max="9999"
								step="1" dense solo :readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"
								@input="updateTop" v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<span>{{ $t('page.sidebarZone.zonePosition') + ' ' + $t('page.toolbar.extension.top') }}</span>
					</v-tooltip>

					<span>{{ $t('page.toolbar.extension.left') }}</span>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field class="short-text mt-6 ml-3 mr-3" v-model="selectedZone.x" type="number" min="1" max="9999"
								step="1" dense solo :readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"
								@input="updateLeft" v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<span>{{ $t('page.sidebarZone.zonePosition') + ' ' + $t('page.toolbar.extension.left') }}</span>
					</v-tooltip>
				</v-card>
			</v-toolbar-items>
		</template>
	</v-app-bar>
</template>

<script>
import router from "@/router"
import { mapGetters, mapActions, mapMutations } from "vuex"
import StaticDataMixin from "@/mixins/staticData"
import GoToPageArrows from "../../../components/GoToPageArrows.vue"
import Shortcuts from "../../../components/Shortcuts.vue"
import EventBus from "@/eventBus"

export default {
	props: ['page', 'document'],
	mixins: [StaticDataMixin],
	data() {
		return {
			showOtherZones: true,
			showContrastZones: true,
			isLoading: true,
			defaultRipple: { center: true },
			errors: null,
			sensitivity: 5,
			goto_page_no: this.$route.params.pageno,
			selectedList: [],
			search: null,
			sortBy: null,
			orderDirection: "asc",
			selectedReadingOrder: "1 column",
			selectedTab: "Page",
			zoneInfoState: null,
			showToolbar: true,
		}
	},
	computed: {
		...mapGetters({
			csrf_token: "user/getCSRFToken",
			zones: "page/getLayout",
			selectedZone: "page/getSelectedZone",
			focusedZone: "page/getFocusedZone",
			canUndo: "page/canUndo",
			canRedo: "page/canRedo",
			showSecondToolbar: "application/getShowSecondToolbar"
		}),
		
		isSmallScreen() {
			var isSmall

			// console.log("Current size =>", this.$vuetify.breakpoint.name)
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
				case 'md':
					isSmall = true
					break
				case 'lg':
				case 'xl':
					isSmall = false
					break
				default:
					isSmall = false
					break
			}
			return isSmall
		}
	},
	components: {
		GoToPageArrows,
		Shortcuts,
	},
	watch: {
		$route(to, from) {
			this.goto_page_no = to.params.pageno
		},
		showToolbar(newVal, oldVal){
			console.log('showToolbar is: ', newVal)
		}
	},
	
	methods: {
		...mapActions({
			savePage: "page/savePage",
			customZone: "page/customZone",
			previewPage: "page/previewPage",
			fixedPreviewPage: "page/fixedPreviewPage",
			markDone: "page/markDone",
			zoomIn: "application/zoomIn",
			zoomOut: "application/zoomOut",
			updateZoneToggleSetting: "application/updateZoneToggleSetting",
			undo: "page/Undo",
			redo: "page/Redo",
		}),
		...mapMutations({
			setSelectedZone: "page/SET_SELECTED_ZONE",
			setShowSecondToolbar: "application/SET_SHOW_SECOND_TOOLBAR",
		}),
		isContrastZone(zone) {
			if (zone.name.includes("Contrast")) {
				return true
			}
			return false
		},
		toggleShowContrastZones(){
			EventBus.$emit("SHOW_CONTRAST_ZONES")
		},
		toggleShowOtherZones(){
			EventBus.$emit("SHOW_OTHER_ZONES")
		},
		toggleShowSecondToolbar(){
			console.log('in toggle toolbar')
			this.setShowSecondToolbar(this.showToolbar)
		},
		updateHeight(){
			this.selectedZone.h = parseInt(this.selectedZone.h)
			this.updateSelectedZone()
		},
		updateWidth(){
			this.selectedZone.w = parseInt(this.selectedZone.w)
			this.updateSelectedZone()
		},
		updateTop(){
			this.selectedZone.y = parseInt(this.selectedZone.y)
			this.updateSelectedZone()
		},
		updateLeft(){
			this.selectedZone.x = parseInt(this.selectedZone.x)
			this.updateSelectedZone()
		},
		updateSelectedZone() {
			this.setSelectedZone(this.selectedZone)
		},
		prevPage() {
			if (this.page.page_no > 1) {
				router.push({ name: 'Page', params: { key: this.document.key, pageno: this.page.page_no - 1 } })
			}
		},
		nextPage() {
			if (this.page.page_no < this.document.total_pages) {
				router.push({ name: 'Page', params: { key: this.document.key, pageno: this.page.page_no + 1 } })
			}
		},
		gotoPage(e) {
			if (e.keyCode === 13) {
				if (this.goto_page_no < 1) {
					this.goto_page_no = 1
				}
				if (this.goto_page_no > this.document.total_pages) {
					this.goto_page_no = this.document.total_pages
				}

				if (this.goto_page_no > 0 && this.goto_page_no <= this.document.total_pages) {
					router.push({ name: 'Page', params: { key: this.document.key, pageno: this.goto_page_no } })
				}
			}
		},
		changeSensitivity() {
			this.$store.commit("page/SET_SENSITIVITY", this.page.sensitivity)
		},
		clickReorder() {
			this.$store.commit("page/REORDER_PAGE", this.selectedReadingOrder)
			this.$snackbar.showMessage({ content: this.$t("page.toolbar.msg.reordered", [this.selectedReadingOrder]), color: 'success' })
		},
		clickAddZone() {
			this.$store.commit("page/ADD_ZONE", { x: 1, y: 1, w: 100, h: 100 })
		},
		clickUndo() {
			this.undo()
		},
		clickRedo() {
			this.redo()
		},
		clickSavePage() {
			//old
			//this.onSavePage()

			//new.  emit SAVE_PAGE to be picked up on Page component
			EventBus.$emit("SAVE_PAGE")
		},
		/*
			savePage in the page action is calling the same endpoint as document_controllers does
			to update the layout ie /api/v1/document/doc_key/page/page_no, json zones/layout 
		*/

		/*
		 this exists already in Page component

		onSavePage() {
			const payload = {
				key: this.document.key,
				pageno: this.page.page_no,
				csrf_token: this.csrf_token,
				page: this.page,
				layout: this.zones
			}

			this.savePage({ payload })
				.then((response) => {
					this.$snackbar.showMessage({ content: this.$t("page.msg.saved"), color: 'success' })
				})
				.catch((error) => {
					this.isLoading = false
				})
		},
		*/
		clickMarkDone() {
			this.onMarkDone()
		},
		clickZoomIn() {
			this.zoomIn()
			// var transLeft = '200px';
			// if ($scope.zoomLevel == 1) {
			//     transLeft = '0px';
			// }
			// else if ($scope.zoomLevel == 1.5){
			//     transLeft = '100px';
			// }

			//   $("#imgPreview").css('zoom',$scope.zoomLevel);
			//   $("#imgPreview").css('-moz-transform', 'translate(' + transLeft + ', 20px) scale(' + $scope.zoomLevel + ')');
			//   $("#imgPreview").css('-moz-transform-origin', '0 0');

			//   var newLeft = 320 / $scope.zoomLevel;
			//   $("#imgPreview").css('left', newLeft + 'px');
		},
		clickZoomOut() {
			this.zoomOut()
		},
		clickClose() {
			this.$router.push('/document/' + this.document.key)
		},
		clickPreviewPage() {
			this.previewPage(this.document.key).then((response) => {
				setTimeout(function () {
					window.open(process.env.VUE_APP_HOST_URL + '/document/' + this.document.key + '/preview/' + this.page.page_no, 'pagePreview')
				}.bind(this), 500)
			})
		},
		clickFixedPreviewPage() {
			this.fixedPreviewPage(this.document.key).then((response) => {
				setTimeout(function () {
					window.open(process.env.VUE_APP_HOST_URL + '/document/' + this.document.key + '/fixedpreview/' + this.page.page_no, 'pagePreview')
				}.bind(this), 500)
			})
		},
		clickRemoveZone() {
			//new.  emit REMOVE_ZONE to be picked up on Page component
			EventBus.$emit("REMOVE_ZONE", this.selectedZone)
		},
		onMarkDone() {
			this.markDone(this.document.key).then((response) => {
				if (this.page.validated) {
					this.$snackbar.showMessage({ content: this.$t("page.toolbar.msg.markedValidated"), color: 'success' })
				}
				else {
					this.$snackbar.showMessage({ content: this.$t("page.toolbar.msg.unmarkedValidated"), color: 'success' })
				}

			})
		},
		sourceChanged() {
			if (this.selectedZone.source === 'Custom' &&
				(this.selectedZone.customText === undefined || this.selectedZone.customText === '')) {
				// this.selectedZone.customInProgress = true

				this.customZone({ payload: { doc_key: this.$route.params.key, zone: this.selectedZone } })
					.then((response) => {
						console.log(response)
					})
					.catch((error) => {
						this.isLoading = false
					})
			}
			EventBus.$emit("source-changed", this.selectedZone)
		},
		toggleZoneInfo() {
			console.log('current state:' + this.zoneInfoState)
			var nextState = 1
			switch (this.zoneInfoState) {
				case 1:
					nextState = 2
					break
				case 2:
					nextState = 3
					break
				case 3:
					nextState = 4
					break
				case 4:
					nextState = 1
					break
				default:
					nextState = 1
			}

			this.zoneInfoState = nextState
			//zoneToggleSettings = nextState
			console.log('next state: ' + this.zoneInfoState)
			this.updateZoneToggleSetting({
				payload: {
					zoneToggleSetting: this.zoneInfoState
				}
			}).then((response) => {
				this.$snackbar.showMessage({
					content: this.$t("page.toolbar.messageZoneInfo"),
					color: 'success'
				})
			}).catch((error) => {

			})
		},

		goToShortcuts() {
			//nothing here
		}
	},
	created() {

	},
	mounted() {
		this.showToolbar = this.showSecondToolbar
		console.log('showToolbar is: ', this.showToolbar)
		//events coming from Shortcuts.vue
		EventBus.$on("previous-page", (msg) => {
			this.prevPage()
		})

		EventBus.$on("next-page", (msg) => {
			this.nextPage()
		})

		EventBus.$on("mark-done", (msg) => {
			this.onMarkDone()
		})

		/*
		EventBus.$on("save-page", (msg) => {
			this.onSavePage()
		})
		*/
	},
	beforeDestroy() {
		EventBus.$off('previous-page')
		EventBus.$off('next-page')
		EventBus.$off('mark-done')
		//EventBus.$off('save-page')

	}

}
</script>

<style lang="scss" scoped>
.custom-top {
	margin-top: 72px !important;
	//height: 161px !important;  //testing
	left: 78px !important;

}

.custom-label {
	text-align: center;
	margin-top: 6px;
	font-size: smaller;
}

.select-width {
	width: 150px;
}

.short-text {
	width: 80px;
	max-width: 80px;
}

.second-bar {
	height: 65px;
}

.page-selector {
	width: 48px;
	border: thin solid rgba(0, 0, 0, 0.12) !important;
	text-align: right;
	padding: 0 5px;
}

#editorToolBar .v-toolbar__content,
#editorToolBar .v-toolbar__extension {
	border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}

.toolbar-buttons>button {
	margin-right: 16px;
}

.zone-source {
	width: 62px !important;
	height: 62px !important;
}

.green-button {
	background-color: #4fc54f;
}


</style>
