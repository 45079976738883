<template>
	<v-container class="no-margin-padding">
		<!-- Editor toolbar -->
		<ToolBars v-if="page && !showTableEditor" :page="page" :document="document" />

		<SideBar v-if="page && !showTableEditor" :page="page" :document="document" :zones="zones" :contrastZones="contrastZones"/>

		<div :class="showSecondToolbar ? 'editor-container' : 'editor-container-up'" ref="editorContainer"
			v-show="!showTableEditor" :style="{ width: editorWidth }">
			<div id="imgPreview" v-if="page && page.page_no" ref="pageEditor"
				:style="{ transform: `translate(0px, 0px) scale(${zoomLevel})` }">
				<div class="svg"
					:style="{ width: page.width + 'px', height: page.height + 'px', 'background-image': 'url(' + page.svg_url + ')' }"
					tabindex="0">
					<EditorZones v-if="zones" :key="sensitivity" />
				</div>
			</div>
		</div>

		<TableEditor v-if="showTableEditor" :page="page" :document="document" :zones="zones"/>
		<!--Off if table editor-->
		<SideBarPageList v-if="page && !showTableEditor" :page="page" :document="document" @sideBarToggled="sideBarToggle"
			style="margin-top:-28px;" /> <!-- was -22 -->

	</v-container>
</template>

<script>
import i18n from "@/plugins/i18n"
import ToolBars from "./ToolBars"
import SideBar from "./SideBar"
import SideBarPageList from "./SideBarPageList"
import EditorZones from "./Editor/SVGZones"
//for table
import TableEditor from "./TableEditor/TableEditor"
import EventBus from "@/eventBus"

import { mapGetters, mapActions, mapMutations } from "vuex"
import TabHistory from "../../document/components/tabs/TabHistory.vue"

export default {
	beforeRouteUpdate(to, from, next) {
		// This will trigger only when the parameters of the route changes
		this.promptSave(to, from, next)
	},
	beforeRouteLeave(to, from, next) {
		this.promptSave(to, from, next)
	},
	data() {
		return {
			isLoading: true,
			selectedTab: "Page",
			zoom: 0, //local var for zoom 
			editorWidth: "59%",
			//new
			changed_zones: [],
			changed_zone_names: [],
		}
	},
	components: {
		ToolBars,
		SideBar,
		EditorZones,
		SideBarPageList,
		//for table
		TableEditor,
	},
	computed: {
		...mapGetters({
			page: "page/getPage",
			zones: "page/getLayout",
			contrastZones: "page/getContrastZones",
			document: "document/getDocument",
			documentPages: "document/getDocumentPages",
			zoomLevel: "application/getZoomLevel",
			sensitivity: "page/getSensitivity",
			showTableEditor: "page/getShowTableEditor",
			isPageDirty: "page/isPageDirty",
			showSecondToolbar: "application/getShowSecondToolbar",
		}),

	},
	methods: {
		...mapActions({
			fetchPage: "page/fetchPage",
			fetchDocument: "document/fetchDocument",
			fetchDocumentPages: "document/fetchDocumentPages",
			addUndo: "page/addUndo",
			savePage: "page/savePage",
			deleteIgnoreZones: "page/deleteIgnoreZones",

		}),
		...mapMutations({
			setMiniMainMenu: "application/SET_MINI_MAIN_MENU_VAL",
			setPage: "page/SET_PAGE",
			setShowTableEditor: "page/SET_SHOW_TABLE_EDITOR",
			refreshLinkZones: "page/REFRESH_LINK_ZONES",
			initUndo: "page/INIT_UNDO",
		}),
		/*
		** Important **
		This will be the method we call to load the page, which will in turn call fetch page.
		We need any call to fetch page handled the same way with .then post-processing.
		*/
		loadPage() {
			console.log('in load page')
			this.fetchPage({
				payload: {
					key: this.$route.params.key,
					pageno: this.$route.params.pageno,
				},
			})
				.then((response) => {
					this.isLoading = false
					this.svg_url = this.page.svg_url
					console.log('Get page returned ===>', this.page)

					// Reorder the zones if the page was not saved
					if (!this.page.last_saved_by_key) {
						if (this.page.defaultReorder !== undefined) {
							this.$store.commit("page/REORDER_PAGE", this.page.defaultReorder)
						}
						else {
							this.$store.commit("page/REORDER_PAGE", 1)
						}

						//now we can refresh link zones if page wasn't already saved
						this.refreshLinkZones()

						//reset the save stack so the above changes won't trigger dirty state
						this.initUndo(this.zones)
					}

					//now change the zoom level to 1.3.  this function ignores the page width
					this.setZoomLevelForPage(this.page.width)

					console.log('is page dirty: ', this.isPageDirty)

				})
				.catch((error) => {
					console.log('Error ===>', error)
					this.isLoading = false
				})
		},
		setZoomLevelForPage(pageWidth) {
			//old stuff commented out here
			// Set zoom level for this page
			// var z =  parseFloat((this.$refs.editorContainer.clientWidth - 500) / pageWidth)
			// z = parseFloat( (Math.round(z * 2) / 2).toFixed(1)) - 0.5
			// if (z > 4) {
			//   z = 4
			// }
			//end old stuff

			//this was just recently commented out.  zoom level of 1.3 is set in the state.  this is reseting the zoom each time a page is loaded
			//the zoom level is set to 1.3 instead of considering page width
			//this.$store.commit('application/SET_ZOOM_LEVEL', 1.3)
		},
		closeTableEditor() {
			this.setShowTableEditor(false)
		},
		preventNav(event) {
			if (!this.isPageDirty) return
			event.preventDefault()
			const message = "You have unsaved changes. Are you sure you wish to leave?"
			event.returnValue = message
			return message
		},
		promptSave(to, from, next) {
			if (this.isPageDirty) {
				//test: if we have repeated zone changes, return the prompt to save them
				if (this.hasRepeatedZoneChanges()) {
					return this.promptIgnoreZoneChangedSave()
				}

				this.$root.$confirm.open(this.$t("page.confirmSaveTitle"),
					this.$t("page.confirmChangedSaveMsg"),
					{ color: 'blue', no: { enabled: true, prompt: "No" } }).then((confirm) => {
						if (confirm === true) {
							//use a promise so we don't proceed until the save is complete
							this.onSavePage().then((response) =>{			
									next()			
							})
						}
						else if (confirm === false) {
							next()
						}

					})
			} else {
				next()
			}
		},
		//prompt to accept change across pages when an ignore zone has changed
		promptIgnoreZoneChangedSave(to, from, next) {
			console.log('has repeated zone changes.  prompting to save changes.')
			this.$root.$confirm.open(this.$t("page.confirmIgnoreZoneTitle"),
				this.$t("page.confirmIgnoreZoneChangeMsg"),
				{ color: 'blue', no: { enabled: true, prompt: "No" } }).then((confirm) => {
					if (confirm === true) {
						//if confirm is true, set update=true for each zone in this.changed_zones
						for (var i = 0; i < this.changed_zones.length; i++) {
							this.changed_zones[i].update = true
						}
						////use a promise so we don't proceed until the save is complete
						this.onSavePage().then((response)=> {
							next()
						})
					}
					else if (confirm === false) {
						//if false, don't set update=true on this.changed_zones
						//don't save
						next()
					}
				})
		},
		/*
			Determine if any of the page.repeat zones have changed.
			Populate changed_zones, and changed_zone_names if true
		*/
		hasRepeatedZoneChanges() {
			console.log('checking for repeated zone changes')
			var retval = false
			for (var i = 0; i < this.zones.length; ++i) {
				if (this.zones[i]['type'] === 'ignore' && (this.page.repeats !== undefined && this.page.repeats != null)) {
					for (var j = 0; j < this.page.repeats.length; ++j) {
						if (this.zones[i].id === this.page.repeats[j].id) {
							if (this.zones[i].x !== this.page.repeats[j].x ||
								this.zones[i].y !== this.page.repeats[j].y ||
								this.zones[i].w !== this.page.repeats[j].w ||
								this.zones[i].h !== this.page.repeats[j].h) {
								this.changed_zones.push(this.zones[i])
								this.changed_zone_names.push(this.zones[i].name)
								//if at least one is true, return true
								retval = true
							}
						}
					}
				}
			}
			return retval
		},
		/*
			This is the main entry point for page saving.
			Clicking save icon on the toolbar will send an event picked up by this component
			which will call this method

			need to return a promise here 
		*/
		onSavePage() {
			return new Promise((resolve, reject) => {
				//first handle repeats and zone type 'ignore'
				console.log('in onSavePage')
				this.prepLayoutForSavePage()

				const payload = {
					key: this.document.key,
					pageno: this.page.page_no,
					csrf_token: this.csrf_token,
					page: this.page,
					layout: this.zones,
					contrastZones: this.contrastZones,
				}

				this.savePage({ payload })
				.then((response) => {
					this.$snackbar.showMessage({ content: this.$t("page.msg.saved"), color: 'success' })
					resolve('success')
				})
				.catch((error) => {
					this.isLoading = false
					reject(error)
				})
			})
		},
		/* 
			need a way to prep the layout before saving. 
			need to handle changed_zones, changed_zone_names, and zone.type === 'ignore'
		*/
		prepLayoutForSavePage() {
			console.log('in prepLayoutForSavePage')
			if (this.page.repeats !== undefined && this.page.repeats.length > 0) {
				console.log('*** in prepLayoutForSavePage.  page.repeats is: ', this.page.repeats)
				this.changed_zones = [];
				this.changed_zone_names = [];
				for (var i = 0; i < this.zones.length; ++i) {
					if (this.zones[i]['type'] === 'ignore') {
						for (var j = 0; j < this.page.repeats.length; ++j) {
							if (this.zones[i].id === this.page.repeats[j].id) {

								if (this.zones[i].x !== this.page.repeats[j].x ||
									this.zones[i].y !== this.page.repeats[j].y ||
									this.zones[i].w !== this.page.repeats[j].w ||
									this.zones[i].h !== this.page.repeats[j].h) {
									this.changed_zones.push(this.zones[i]);
									this.changed_zone_names.push(this.zones[i].name);
								}
							}
						}
					}
				}

				if (this.changed_zone_names !== undefined && this.changed_zone_names.length > 0) {
					//if (prompt === undefined) {
					for (i = 0; i < this.changed_zones.length; ++i) {
						console.log('*** adding update=true to changed zone: ', this.changed_zones[i])
						this.changed_zones[i].update = true;
					}
					//$scope.promptSavePage(location);
					//}
					/*
					else {
						$scope.onIgnoreChanged = function () {
							for (var i = 0; i < $scope.changed_zones.length; ++i) {
								$scope.changed_zones[i].update = true;
							}
							$scope.commitLayout();
							return true;
						};
						$scope.onIgnoreCancelChanges = function () {
							$scope.commitLayout();
							return true;
						}
						$scope.zone_name_list = $scope.changed_zone_names.join(", ");
						$('#confirmIgnoreZoneChanged')
							.modal('setting', 'closable', false)
							.modal('setting', 'detachable', false)
							.modal('show');
					}
					*/
				}
				/*
				else {
					$scope.commitLayout();
				}
				*/
			}
			/*
			else {
				$scope.commitLayout();
			}
			*/
		},
		isContrastZone(zone) {
			if (zone.name.includes("Contrast")) {
				return true
			}
			return false
		},
		/*
			Primary method for removing/deleting zone
		*/
		removeZone(zone) {
			console.log('removing zone: ', zone.type)
			if (zone.type === 'ignore') {
				//need prompt for ignore zone delete
				this.promptIgnoreZoneDelete(zone)
			}
			else { //otherwise, just remove zone
				this.$store.commit("page/REMOVE_ZONE", zone)
			}

		},
		promptIgnoreZoneDelete(zone) {
			this.$root.$confirm.open(' Removing repeated zone', 'Removing this zone will remove it from all pages', { color: 'red' }).then((confirm) => {
				if (confirm === true) {
					this.$store.commit("page/REMOVE_ZONE", zone)
					//need to call handler to remove the ignore zones from pages
					this.removeIgnoreZones(zone)
				}
				else if (confirm === false) {
					//nothing here
				}
			})

		},

		removeIgnoreZones(zone) {
			console.log('in remove ignore zones.  zone is: ', zone)
			//call deleteIgnoreZones
			const payload = {
				key: this.document.key,
				zone: zone,
			}

			this.deleteIgnoreZones({ payload })
				.then((response) => {
					this.$snackbar.showMessage({ content: this.$t("page.msg.saved"), color: 'success' })
				})
				.catch((error) => {
					this.isLoading = false
				})
		},
		sideBarToggle(visible) {
			if (visible) {
				this.editorWidth = "59%"
			}
			else {
				this.editorWidth = "72%"
			}
			console.log("New width", this.$refs.editorContainer.style.width)
			console.log("Window width", (window.innerWidth - 412) - 36)
		}
	},
	inject: {
		theme: {
			default: { isDark: false },
		},
	},
	watch: {
		//triggered when clicking on page from righthand selector within editor
		$route(to, from) {
			if (to.params.pageno !== this.page.pageno) {

				//call the fetch page routine in loadPage
				this.loadPage()

				/*
				this.fetchPage({
					payload: {
						key: this.$route.params.key,
						pageno: to.params.pageno,
					},
				})
					.then((response) => {
						this.isLoading = false
						console.log(response)
						//this.setZoomLevelForPage(this.page.width)

					})
					.catch((error) => {
						this.isLoading = false
						console.log('Error ===>', error)
					})
					*/
			}
		},
		//this is a watched variable to tell when zoomlevel changed
		zoomLevel: function () {
			console.log("Zoomlevel changed to ", this.zoomLevel)
			this.zoom = this.zoomLevel
			// var transLeft = '0px'
			// if (this.zoomLevel == 1) {
			//     transLeft = '0px'
			// }
			// else if (this.zoomLevel == 1.5){
			//     transLeft = '100px'
			// }

			// this.$refs.pageEditor.style.zoom = this.zoomLevel
			// this.$refs.pageEditor.style.webkitTransform = 'translate(' + transLeft + ', 20px) scale(' + this.zoomLevel + ')'
			this.$refs.pageEditor.style.webkitTransformOrigin = '0 0'

			// const newLeft = 320 / this.zoomLevel
			// this.$refs.pageEditor.style.left = newLeft + 'px'

		},
		'$store.state.page.layout': function (x) {
			this.addUndo({
				payload: {
					layout: x,
				}
			})
		}
	},
	//triggered when clicking on page in doc details
	created() {
		//set page to null
		this.setPage(null)
		//need to load the page with zoom level at one, then change it to 1.3, otherwise the position of the image svg is incorrect
		this.$store.commit('application/SET_ZOOM_LEVEL', 1)

		//call the fetch page routine in loadPage
		this.loadPage()

		this.fetchDocument({ payload: { key: this.$route.params.key } })
			.then((response) => {
				this.isLoading = false
			})
			.catch((error) => {
				this.isLoading = false
			})

		this.fetchDocumentPages({ payload: { key: this.$route.params.key } })
			.then((response) => {
				this.isLoading = false
			})
			.catch((error) => {
				this.isLoading = false
			})
	},
	updated() {
		// console.log('Something updated!?', this.page, this.page.svg_url)
	},
	beforeMount() {
		window.addEventListener("beforeunload", this.preventNav)
	},
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.preventNav)
		//need to remove event listener on destroy so it isn't created multiple times
		EventBus.$off('SAVE_PAGE')
		EventBus.$off('EDIT_TABLE')
		EventBus.$off('exit-table-editor')
		EventBus.$off('REMOVE_ZONE')
	},
	mounted() {
		this.$store.commit("application/SET_MINI_MAIN_MENU_VAL", true)

		/*
			when we come to this page, if table editor was on, we want to turn it off
		*/
		this.setShowTableEditor(false)

		/*
			showTableEditor starts as false.  
			when clickEditTable happens in TableZone, EDIT_TABLE is emitted.
			showTableEditor is set to true, which triggers the mount of the TableEditor
		*/
		EventBus.$on("EDIT_TABLE", (msg) => {
			console.log("EDIT_TABLE picked up on event bus from Page.vue")
			this.setShowTableEditor(true)
			//test forcing update
			this.$forceUpdate()
		})

		EventBus.$on("exit-table-editor", (msg) => {
			console.log("exit table editor picked up by page")
			this.closeTableEditor()
		})

		/*
			Will pick up event from toolbar save.
			In the case we have repeatedZone changes, we need to prompt to save those.
			this.onSavePage() should be the main entry point for saving a page since we need one central
			method that does everything needed.  The separate save on the toolbar component was missing
			some important routines.
		*/
		EventBus.$on("SAVE_PAGE", (msg) => {
			console.log("save page picked up by page")

			//if we have repeated zone changes, return the prompt to save them
			if (this.hasRepeatedZoneChanges()) {
				return this.promptIgnoreZoneChangedSave()
			}
			else { //otherwise, no prompt needed, just save
				this.onSavePage()
			}
		})

		/*
			New. Will pickup remove/delete zone event.  we need a special method which includes the handling
			of deleting ignore zones.  Moving the remove/delete from sidebarZones and toolbars to here.
		*/
		EventBus.$on("REMOVE_ZONE", (zone) => {
			//skip for contrast zone
			if(this.isContrastZone(zone)){
				return
			}
			console.log("remove zone picked up by page.  zone is:", zone)
			this.removeZone(zone)
		})

	},
	unmounted() {
		console.log("Unmounted")
	}
}
</script>

<style lang="scss" scoped>
.slider-width {
	width: 200px;
	min-width: 200px;
}

.mb1 {
	margin-bottom: 1px !important;
}

.editor-container {
	position: fixed;
	left: 412px;
	top: 214px; //testing top: 240px then 222 then 214;
	overflow: auto;
	height: 83%;
	overflow: scroll;
}

.editor-container-up {
	position: fixed;
	left: 412px;
	top: 148px; //testing top: 240px then 222 then 214;
	overflow: auto;
	height: 83%;
	overflow: scroll;
}

#imgPreview {
	position: absolute;
	box-shadow: 0 0 3px 1px rgb(0 0 0 / 35%);
	background-color: #ffffff;
	left: 10px;
	top: 10px;
	/* overflow-y: auto; */
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
}
</style>
<style lang="scss">
.sidebar-bottom-padding {
	min-height: 400px;
}
</style>
<style>
::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>