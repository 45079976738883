<!-- This is the left hand sidebar container for the table editor-->
<template>
	<div class="side-bar">
		<v-tabs fixed-tabs v-model="selectedTab">
			<v-tab key="Table">Table</v-tab>
			<v-tab key="Cells">Cells</v-tab>
		</v-tabs>

		<v-tabs-items v-model="selectedTab">
			<SideBarTable :tableZone="tableZone" :autoDetect="autoDetect" :ocrInProgress="ocrInProgress"
				:ocrPageProgress="ocrPageProgress" :ocrPageProgress_msg="ocrPageProgress_msg"
				:ocrCellProcessed="ocrCellProcessed" :ocrTotalCells="ocrTotalCells" />

			<SideBarCells :tableZone="tableZone" :selected_row_index="selected_row_index"
				:selected_col_index="selected_col_index" :selectedCells="selectedCells" :isSpan="isSpan"
				:isRowSpan="isRowSpan" :selectedCell="selectedCell" :selectedHead="selectedHead"/>
		</v-tabs-items>
	</div>
</template>

<script>
import SideBarTable from "./SideBarTable"
import SideBarCells from "./SideBarCells"
import EventBus from "@/eventBus"

export default {
	name: "TableEditorSideBar",
	props: ["tableZone", "autoDetect", "ocrInProgress",
		"ocrPageProgress", "ocrPageProgress_msg", "ocrCellProcessed",
		"ocrTotalCells", "selected_row_index",
		"selected_col_index", "selectedCells", "selectedCell",
		"isSpan", "isRowSpan", "selectedHead"], //not sure what we need just yet

	data() {
		return {
			isLoading: true,
			errors: null,
			selectedTab: null,
		}
	},
	components: {
		SideBarTable,
		SideBarCells,
	},
	mounted() {

	}
}

</script>

<style lang="scss" scoped>
.short-text {
	width: 50px;
	max-width: 50px;
}

.side-bar {
	position: fixed;
	top: 152px;
	left: 79px;
	width: 320px;
	padding: 0;
	height: 100% !important;
	border-right: 1px solid lightgray;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
}

.side-bar>div.v-window {
	overflow: auto !important;
}

.side-bar>>>.v-window__container,
.side-bar>>>.v-window-item {
	height: 100%;
}

/* customise the dimensions of the card content here */
.side-bar>>>.v-card {
	height: 100%;
}

.side-bar-tab {
	padding: 6px;

	&>* {
		font-size: 14px;

		.col {
			padding: 2px 10px;
			min-height: 55px;
		}

		.v-btn {
			margin: 10px;
		}
	}
}

.v-tab {
	border: 1px solid lightgray;
	font-weight: bold;
	color: var(--v-primary-base);
}

.v-tab.v-tab--active {
	background-color: lightgray;
}

// .v-tabs-items
//     {
//         min-height: 100vh;
//     }

.btn-group-item {
	margin: 0 !important;
	padding: 0 !important
}

.section-divider {
	margin-bottom: 5px;
	margin-top: -31px;
	padding-bottom: 20px;
}
</style>