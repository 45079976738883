import * as fb from "@/firebase"
import router from "@/router"
import HTTP from "@/helpers/http-common"
import EventBus from "@/eventBus"


export default {
	async login({ dispatch, commit }, form) {
		return new Promise((resolve, reject) => {
			// sign user with username/password
			HTTP.post("/login-vue", form)
				.then((response) => {
					commit("loginSuccess", {
						user: form.email
					})

					commit('setUserProfile', { email: form.email })
					dispatch("fetchUserChannel")

					if (router.currentRoute.path === "/login") {
						router.push("/")
					}
					resolve('success')
				})
				.catch((error) => {
					console.log("error trying to login:", error.response.data)

					reject(error.response.data)
					//don't need to logout because we're not logged in
					//dispatch("logout")

					if (router.currentRoute.path !== "/login") {
						router.push("/login")
					}

					if (error.response !== undefined) {
						return Promise.reject('failed')
						//return error.response.status
					} else {
						return 500
						// return Promise.reject(500)
					}
				})
		})

	},
	async loginGoogle({ dispatch, commit }, form) {
		// Signin with the google popup
		await fb.auth
			.signInWithPopup(fb.google_provider)
			.then((result) => {
				console.log("login google", result)
				// Result will be handle with the onAuthStateChanged in main.js
			})
			.catch((err) => {
				console.log("Err", err)
				this.loading = false

				//this is causing an error because of this.$router
				//this.$router.replace("login").catch(() => { })


			})

		// fetch user profile and set in state
		//   dispatch('fetchUserProfile', user)
	},

	async loginMicrosoft({ dispatch, commit }, form) {
		// Signin with the google popup
		await fb.auth
			.signInWithPopup(fb.microsoft_provider)
			.then((result) => {
				console.log("login microsoft", result)
				// Result will be handle with the onAuthStateChanged in main.js
			})
			.catch((err) => {
				console.log("Err", err)
				this.loading = false

				//this is causing an error because of this.$router
				//this.$router.replace("login").catch(() => { })


			})

		// fetch user profile and set in state
		//   dispatch('fetchUserProfile', user)
	},

	async switchCustomer({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.post("/api/v1/switchcustomer/" + payload.doc_key)
				.then((response) => {
					if (response) {
						resolve('success')
					}

				}).catch((error) => {
					reject(error)
				})
		})
	},

	async triggerError( {dispatch, commit},{payload}){
		console.log('in trigger 401 error')
		return new Promise( (resolve, reject) => {
			HTTP.get("/api/v1/test")
				.then( (response) => {
					resolve('success')
				}).catch( (error) => {
					reject(error)
				})
		})
	},

	async backendLogin({ dispatch, commit }, loginInfo) {
		// Login with the token to the backend
		return new Promise((resolve, reject) => {
			HTTP.defaults.headers.common["Authorization"] =
				"Bearer " + loginInfo.token
			HTTP.post("logingoogle")
				.then((response) => {
					commit("loginSuccess", {
						user: loginInfo.user,
						token: loginInfo.token,
					})
					commit("setUserProfile", {
						displayName: loginInfo.user.displayName,
						email: loginInfo.user.email,
						photoURL: loginInfo.user.photoURL,
					})

					dispatch("fetchUserChannel")
					//dispatching action from different module ie 'application'
					console.log('fetching user statuses')
					dispatch("application/fetchOptions", null, { root: true })

					if (router.currentRoute.path === "/login") {
						router.push("/")
					}
					resolve("success")
				})
				.catch((error) => {
					dispatch("logout")
					console.log("in backend google login error:", error.response.data)
					router.push("/login")

					//send a message to be picked up by login
					EventBus.$emit("authentication-failed", error.response.data)

					reject(error.response.data)

					if (error.response !== undefined) {
						return Promise.reject(error)
						// return error.response.status
					} else {
						// return 500
						return Promise.reject(500)
					}
				})
		})
	},
	async fetchUserProfile({ commit }, user) {
		// fetch user profile
		// set user profile in state
		return HTTP.get("/api/v1/profile")
			.then((response) => {
				commit("setUserProfile", response.data.data)
				console.log('profile data:', response.data.data)
				if (router.currentRoute.path === "/login") {
					router.push("/")
				}
			})

	},
	async saveUserProfile({ state }, { payload }) {
		return HTTP.post("/api/v1/profile",
			{
				name: payload.name,
				last_name: payload.last_name,
				description: payload.description,
				location: payload.location,
				phone: payload.phone,
				customer_key: payload.customer_key,
				ring_width: payload.ring_width,
				autosummarize_default: payload.autosummarize_default,
				output_default: payload.output_default
			})
			.then((response) => {
				//nothing here
			})
	},
	async fetchUserChannel({ dispatch, commit }) {
		return HTTP.get("/user/channel")
			.then((response) => {
				commit("setUserChannel", response.data.data)
				// Todo: Should move that somewhere
				HTTP.defaults.headers.common["X-CSRF-TOKEN"] = response.data.data.csrf_token
				commit("SET_CSRF", response.data.data.csrf_token)
				// dispatch("connectCentrifuge", response.data.data)

			})
	},
	async fetchLabels({ dispatch, commit }) {

		return HTTP.get("api/v1/label")
			.then((response) => {
				if (response.data) {
					commit("SET_LABELS", response.data.data.results)
				}
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},
	async fetchNotifications({ dispatch, commit }) {

		return HTTP.get("api/v1/usernotice?reverse=true")
			.then((response) => {
				if (response.data) {
					var data = response.data.data.results
					commit("SET_NOTIFICATIONS", data)
				}
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},
	async deleteNotice({ dispatch }, noticeKey) {
		return HTTP.delete("api/v1/usernotice/" + noticeKey)
			.then((response) => {
				dispatch("fetchNotifications")
			})
	},
	async logout({ commit }) {
		//use mutex for locking the ability to log in
		commit("SET_LOGOUT_IN_PROCESS", true)
		// log user out
		await fb.auth.signOut()

		HTTP.get('/logout-vue/')
			.then((response) => {
				console.log('logged out on server', response)
			}).catch((error) => {
				console.log('error logging out')
			}).finally(() => {
				//set to false to allow login
				commit("SET_LOGOUT_IN_PROCESS", false)
				// clear user data from state
				commit("setUserProfile", {})
				commit("logoutSuccess", {})
				//remove options values. use this syntax for calling a mutator from another module in the store
				commit("application/SET_OPTIONS", null, { root: true })
				window.open('/', "_self")
			})
	},

	/* 
		Function to check if a user is authenticated on the server.
		Called when the app is mounted.  Used when app is refreshed in browser
		and store is re-initialized.  
	*/
	async checkAuth({ dispatch, commit }) {
		return new Promise((resolve, reject) => {

			HTTP.get("/check_auth")
				.then((response) => {
					console.log('auth check success: ', response)
					commit("AUTHENTICATION_CHECK_SUCCESS", {
						user: response.data.data.user_id,
					})
					resolve(response)
				}).catch((error) => {
					console.log('auth check error:  user is not authenticated.')
					reject(error)
				})
		})
	}

}
