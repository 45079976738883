<template>
	<v-container class="no-margin-padding">

		<TableToolBar :selected_row_index="selected_row_index" :selected_col_index="selected_col_index"
			:selectedCells="selectedCells" :isSpan="isSpan" :isRowSpan="isRowSpan" :selectedCell="selectedCell" 
			:tableZoneIsDirty="tableZoneIsDirty"/>

		<TableEditorSideBar :tableZone="tableZone" :autoDetect="autoDetect" :ocrInProgress="ocrInProgress"
			:ocrPageProgress="ocrPageProgress" :ocrPageProgress_msg="ocrPageProgress_msg" :ocrCellProcessed="ocrCellProcessed"
			:ocrTotalCells="ocrTotalCells" :selected_row_index="selected_row_index" :selected_col_index="selected_col_index"
			:selectedCells="selectedCells" :selectedCell="selectedCell" :isSpan="isSpan" :isRowSpan="isRowSpan"
			:selectedHead="selectedHead" />

		<div style="margin-left:650px;margin-top:125px;">
			<v-progress-circular
        indeterminate
        color="primary"
				v-show="loading"
				:size="60"
      ></v-progress-circular>
		</div>

		<!--The actual table editor canvas-->
		<div id="table-editor-container" v-show="!loading">

			<div id="tableEditor" v-if="tableZone" ref="tableEditor" 
				:style="{ transform: `translate(0px, 0px) scale(${zoomLevel})` }">

				<!--
				<div v-for="(zdiv, index) in placeholderDivs" style="position: absolute;background-color: #3f51b594"
					:key="index"
					:style="{ width: zDiv.w + 'px', top: zDiv.y + 'px', height: zDiv.h + 'px', left: zDiv.x + 'px' }"></div>
				-->
				<svg id="svgTable" ref="TableEditor" version="1.1" xmlns="http://www.w3.org/2000/svg"
					preserveAspectRatio="xMinYMin meet"
					style="background-color: white; z-index: 1000;overflow: hidden; border: 0px;" :height="tableZone.h"
					:width="tableZone.w">
					<!--This is the svg of the table image-->
					<svg v-html="svgContent">
					</svg>

				</svg>

			</div>
		</div>

	</v-container>

</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import EventBus from "@/eventBus"
import helpers from "../../../../helpers/common.js"
import TableEditorSideBar from "./TableEditorSideBar"
import TableToolBar from "./TableToolBar"

export default {
	name: "TableEditor",
	props: ["page", "document", "zones"], //passed from the page component

	/*
		Question: is it better to pass page, document, and zones into the component from
		the parent component (Page.vue), or use the getters? 
	*/
	data() {
		return {
			autoDetect: {
				tableRadius: 1,
				rowRadius: 1,
				columnRadius: 1,
			},
			autoSummarizeDefault: null,
			bg_cells: null,
			currentColCount: null,
			currentRowCount: null,
			currentDocument: null, //use document from props instead.  this is always null.
			disableTableRadiusSlider: false,
			dragging: false,
			isSpan: false,
			isRowSpan: false,
			first_selected_col_index: null,
			first_selected_row_index: null,
			last_selected_col_index: null,
			last_selected_row_index: null,
			loading: false,
			mlTables: [],

			ocrInProgress: false,
			ocrPageProgress: null,
			ocrPageProgress_msg: null,
			ocrCellProcessed: null,
			ocrTotalCells: null,

			placeholderDivs: [],
			pristineZone: null,
			pristineZoneChanged: false,
			rowcol_group: null,
			savePrompt: false,
			selected_col_index: null,
			selected_row_index: null,
			selectedCell: null,
			selectedCells: null,
			showHeaderMessage: false,
			svg_span_group: null,
			svg_selected_cells: null,
			svgContent: null,
			sTable: null,  //ie. the Snap table
			tableImageSvgUrl: null,
			tableLoading: false,
			tableZone: {},
			tableZoneIsDirty: false,
			toRemove: [],
			updatingSensitivity: false,
			underZones: [],
			zoomLevel: 1,
			svg_styles: {
				CELL_COLOR: 'rgba(0,0,0,0.11)',
				SPAN_CELL_COLOR: 'rgba(0,0,0,0.11)',
				SELECTED_CELL_COLOR: 'green',
				CELL_BORDER: 'gray',
				SPAN_CELL_BORDER: 'blue',
				ROWCOL_SELECTOR: 'green',
				ROWCOL_SELECTOR_HOVER: 'red',
				ROW_DIVIDER_HEIGHT: 2,
				COL_DIVIDER_WIDTH: 2,
				ANIMATION_TIME: 150
			}
		}
	},
	components: {
		TableEditorSideBar,
		TableToolBar,
	},
	computed: {
		...mapGetters({
			//zoomLevel: "application/getZoomLevel", //passed in as prop
			selectedZone: "page/getSelectedZone",
		}),
		//moved this to a computed property since it's passed into the sideBarCells child component
		selectedHead() {
			console.log('selectedHead getting called. tableZone:', this.tableZone)
			if ((this.tableZone === undefined || this.tableZone == null) ||
				(this.tableZone.rows === undefined || this.tableZone.rows.length == 0)) {
				console.log("selectedHead called before table loaded")
				return null
			}

			//previously passing this conditional because selected_row_index is actually null, not undefined
			if ((this.selected_row_index === undefined || this.selected_row_index == null) ||
				this.selected_row_index >= this.tableZone.rows.length ||
				this.selected_col_index === undefined ||
				this.selected_col_index >= this.tableZone.cols.length) {
				console.log("invalid selected row/col")
				return null
			}
			var selected = this.tableZone.rows[this.selected_row_index].cells[this.selected_col_index]
			if (selected.rowSpanStart !== undefined && selected.colSpanStart !== undefined) {
				console.log("Unexpected starts");
			} else if (selected.rowSpanStart !== undefined) {
				selected = this.tableZone.rows[selected.rowSpanStart].cells[this.selected_col_index]
			} else if (selected.colSpanStart !== undefined) {
				selected = this.tableZone.rows[this.selected_row_index].cells[selected.colSpanStart]
			}
			console.log('selectedHead return value: ', selected)
			return selected
		},
	},
	watch: {
		zoomLevel: function () {
			console.log("Zoomlevel changed to ", this.zoomLevel)
			this.$refs.tableEditor.style.webkitTransformOrigin = '0 0'
		},

		'tableZone.colHeader': function (newVal, oldVal) {
			this.checkAndSummarize()
			if (newVal != undefined) {
				if (!this.validateHeaderVals()) {
					this.tableZone.colHeader = 1
				}
			}
		},
		'tableZone.rowHeader': function (newVal, oldVal) {
			this.checkAndSummarize()
			if (newVal != undefined) {
				if (!this.validateHeaderVals()) {
					this.tableZone.rowHeader = 1
				}
			}
		},
		'tableZone.autoSummarize': function (newVal, oldVal) {
			this.checkAndSummarize()
		},
		'tableZone.rows': function (newVal, oldVal) {
			this.checkAndSummarize()
		},
		'tableZone.cols': function (newVal, oldVal) {
			this.checkAndSummarize()
		},
		'pristineZone': {
			handler(newVal, oldVal) {
				//make sure we're not detecting null to val change
				if (oldVal != null && newVal != oldVal) {
					console.log('pristine zone changed')
					this.pristineZoneChanged = true
				}
			},
			deep: true
		},
		'selectedCell': function (newVal, oldVal) {
			console.log('selectedCell changed:', newVal)
		},
		selectedCells: {
			handler(newVal, oldVal) {
				console.log('selectedCells changed:', newVal)
			},
			deep: true
		},
		//for testing
		tableZone: {
			handler(newVal, oldVal) {
				this.tableZoneIsDirty = true
				console.log('tableZone has changed!', newVal)
				this.setSelectedZone(this.tableZone)
			},
			deep: true
		},
		//for testing
		
		selectedHead: {
			handler(newVal, oldVal) {
				console.log('selectedHead changed in tableEditor:', newVal)
				EventBus.$emit('selected-head-changed', this.selectedHead)
				this.$forceUpdate()
			},
			deep: true
		},

	},
	methods: {
		...mapActions({
			fetchMlTables: "page/fetchMlTables",
			fetchTablePreview: "page/fetchTablePreview",
			previewTableLayout: "page/previewTableLayout",
			ocrTable: "page/ocrTable",  //for ocrAllCells
			customTable: "page/customTable", //for customAllCells
			customSource: "page/customSource", //for sourceChanged
			ocrTableZone: "page/ocrTableZone" //for getOcr
		}),
		...mapMutations({
			setSelectedZone: "page/SET_SELECTED_ZONE",
		}),
		getMlTables(zone, from_tags) {
			this.fetchMlTables({
				payload: {
					page_no: this.page.page_no,
					doc_key: this.document.key,
					name: zone.name,
					left: zone.x,
					top: zone.y,
					width: zone.w,
					height: zone.h,
					tagged: from_tags,
					zones: JSON.stringify(this.zones),
				}
			}).then((response) => {
				//console.log('getMlTables response: ', response)
				this.mlTables = response.data.tables
				console.log('mlTables: ', this.mlTables)

			}).catch((error) => {

			})
		},
		//probably gets the image of the table zone
		getTablePreview(zone, from_tags) {
			this.loading = true
			var addSnapSvg = this.addSnapSvg
			this.fetchTablePreview({
				payload: {
					page_no: this.page.page_no,
					doc_key: this.document.key,
					name: zone.name,
					left: zone.x,
					top: zone.y,
					width: zone.w,
					height: zone.h,
					radius: this.autoDetect.tableRadius,
					tagged: from_tags,
					zones: JSON.stringify(this.zones),
				}
			}).then((response) => {
				console.log('getTablePreview response:', response)
				var preview_data = response.data.preview_data

				//response.data also includes:
				//tableLayout, zone, & zoneName

				if (zone.src_layout !== undefined) {
					this.tableZone = zone
				}
				// Got the svg of the table, we will use as a background and draw lines over
				//must set v-html on svg element
				this.svgContent = preview_data

				this.sTable = new Snap("#svgTable")

				console.log('sTable (snap table): ', this.sTable)
				addSnapSvg()

				//console.log('svgContent end: ', svgContent)

				if (zone.rows.length < 1) {
					//brand new table, initialize rows & cols
					this.setTableData(response.data.tableLayout)
				}
				else {
					//previously edited table. just build graphics
					this.setTableData(zone)
				}

				this.zoomLevel = 1.5
				this.loading = false
				this.pristineZone = JSON.parse(JSON.stringify(this.tableZone))
				this.tableZoneIsDirty = false


			}).catch((error) => {
				//need something here
				this.loading = false
			})
		},

		setTableData(data) {
			console.log('in setTableData', data)
			if (this.svg_span_group) {
				this.svg_span_group.remove()
				this.svg_selected_cells.remove()
				this.bg_cells.remove()  // not used
			}

			if (this.rowcol_group) {
				this.rowcol_group.remove()
			}


			// Draw the column dividers
			// Put all row, column dividers in the same svg group
			this.svg_span_group = this.sTable.g()
			this.bg_cells = this.sTable.g()
			this.rowcol_group = this.sTable.g()
			this.svg_selected_cells = this.sTable.g() // selection group. Multi-selection is one rect.
			this.tableZone.rows = data.rows
			this.tableZone.cols = data.cols
			this.currentColCount = this.tableZone.cols.length
			this.currentRowCount = this.tableZone.rows.length
			this.tableZone.rowHeader = data.rowHeader
			this.tableZone.colHeader = data.colHeader
			this.changeRowHeader(this.tableZone.rowHeader)

			//console.log('made it here 1')

			this.refreshCells()

			var bg_layer = this.sTable.rect(0,
				0,
				this.tableZone.w,
				this.tableZone.h
			).bglayer()

			bg_layer.attr({ 'fill': 'gray', 'fill-opacity': '0.1' })
			this.rowcol_group.add(bg_layer)

			for (var col = 0; col < this.tableZone.cols.length; col++) {
				// Create a rect for each dividers
				var rect = this.sTable.rect(this.tableZone.cols[col].x,
					2,
					2,
					this.tableZone.h
				).limitCol(
					{
						col_obj: this.tableZone.cols[col],
						col: this.tableZone.cols[col]['id'],
						x: this.tableZone.cols[col].x,
						y: 0,
						minx: this.tableZone.x,
						miny: 0,
						maxx: this.tableZone.w,
						maxy: 0
					})

				rect.attr({ 'col': col, 'fill': 'green' })
				// Add the class for resize mouse pointer
				rect.addClass('ui-svg-ew')
				// Add the rect to the group
				this.rowcol_group.add(rect)
			}

			// Draw the row dividers
			// Put all column dividers in the same svg group
			for (var row = 0; row < this.tableZone.rows.length; row++) {
				// Create a rect for each dividers
				rect = this.sTable.rect(0,
					this.tableZone.rows[row].y,
					this.tableZone.w,
					2
				).limitRow(
					{
						col: this.tableZone.rows[row],
						x: 0,
						y: 0,
						minx: 320,
						miny: 0,
						maxx: this.tableZone.w,
						maxy: this.tableZone.h
					})

				rect.attr({ 'col': row, 'fill': 'green' })

				// Add the class for resize mouse pointer
				rect.addClass('ui-svg-ns')
				// Add the rect to the group
				this.rowcol_group.add(rect)
			}
			// We have to refresh the cells again once everything is drawn
			this.refreshCells()
			this.updateTableControl()
			this.refreshSpans()

			console.log('sTable after setTable:', this.sTable)
		},

		refreshGridSelectors() {
			this.rowcol_group.clear()
			var bg_layer = this.sTable.rect(0,
				0,
				this.tableZone.w,
				this.tableZone.h
			).bglayer()

			bg_layer.attr({ 'fill': 'gray', 'fill-opacity': '0.1' })
			this.rowcol_group.add(bg_layer)

			for (var col = 0; col < this.tableZone.cols.length; col++) {
				// Create a rect for each dividers
				// console.log(this.tableZone.cols[col])
				var rect = this.sTable.rect(this.tableZone.cols[col].x,
					1,
					2,
					this.tableZone.h
				).limitCol(
					{
						col_obj: this.tableZone.cols[col],
						col: this.tableZone.cols[col]['id'],
						x: this.tableZone.cols[col].x,
						y: 0,
						minx: this.tableZone.x,
						miny: 0,
						maxx: this.tableZone.w,
						maxy: 0
					})

				rect.attr({ 'col': col, 'fill': 'green' })
				// Add the class for resize mouse pointer
				rect.addClass('ui-svg-ew')
				// Add the rect to the group
				this.rowcol_group.add(rect)
			}

			// Draw the row dividers
			// Put all column dividers in the same svg group
			//            var row_group = this.sTable.g()
			for (var row = 0; row < this.tableZone.rows.length; row++) {
				// Create a rect for each dividers
				rect = this.sTable.rect(0,
					this.tableZone.rows[row].y,
					this.tableZone.w,
					2
				).limitRow(
					{
						col: this.tableZone.rows[row],
						x: 0,
						y: 0,
						minx: 320,
						miny: 0,
						maxx: this.tableZone.w,
						maxy: this.tableZone.h
					})

				rect.attr({ 'col': row, 'fill': 'green' })

				// Add the class for resize mouse pointer
				rect.addClass('ui-svg-ns')
				// Add the rect to the group
				this.rowcol_group.add(rect)
			}
		},

		refreshSpans() {
			this.svg_span_group.clear()
			// Print all the table cells
			for (var row = 0; row < this.tableZone.rows.length; row++) {
				// Group by row
				var current_row = this.tableZone.rows[row]

				for (var col_idx = 0; col_idx < current_row.cells.length; col_idx++) {

					if (current_row.cells[col_idx].colspan) {
						// Create a rect for each span cells of this row
						rect = this.sTable.rect(current_row.cells[col_idx].x + 1,
							current_row.cells[col_idx].y,
							current_row.cells[col_idx].width,
							current_row.cells[col_idx].height)

						rect.attr({
							'class': 'table_cell',
							'cell_r': row,
							'cell_c': col_idx,
							'fill': 'green',
							'fill-opacity': '0.2',
							'stroke': this.svg_styles.SPAN_CELL_BORDER,
							'strokeWidth': 2
						})
						// Add the click event to the cell
						// rect.click(cellClicked);
						this.svg_span_group.add(rect)

					} else if (current_row.cells[col_idx].rowspan) {
						var start_y = current_row.cells[col_idx].y
						var end_y = this.tableZone.rows[row + parseInt(current_row.cells[col_idx].rowspan) - 1].cells[col_idx].y
						end_y += this.tableZone.rows[row + parseInt(current_row.cells[col_idx].rowspan) - 1].cells[col_idx].height
						var rect = this.sTable.rect(current_row.cells[col_idx].x + 1,
							current_row.y + 1,
							current_row.cells[col_idx].width - 1,
							(end_y - start_y) - 1
						);

						rect.attr({
							'class': 'table_cell',
							'cell_r': row,
							'cell_c': col_idx,
							'fill': 'green',
							'fill-opacity': '0.2',
							'stroke': this.svg_styles.SPAN_CELL_BORDER,
							'strokeWidth': 2
						})
						// Add the click event to the cell
						//rect.click(cellClicked);
						this.svg_span_group.add(rect)
					}
				}
			}
		},

		refreshSelection(col, row) {
			console.log('refreshing selection. col:', col)
			console.log('row', row)
			if (col !== undefined && row !== undefined) {
				var r = parseInt(row.id.replace('r_', ''))
				var c = parseInt(col.id.replace('c_', ''))

				var col_w = this.tableZone.w
				var col_neighbors = this.getColBoundaries(col.id)
				if (col_neighbors !== undefined && col_neighbors.next) {
					col_w = col_neighbors.next.x - col.x
				}

				var row_h = this.tableZone.h
				var row_neighbors = this.getRowBoundaries(row.id)
				if (row_neighbors !== undefined && row_neighbors.next) {
					row_h = row_neighbors.next.y - row.y
				}

				var new_cell = undefined  // a selection rect
				var cell = this.tableZone.rows[r].cells[c]
				if (cell !== undefined) {
					if (cell.colSpanStart !== undefined) {
						new_cell = this.sTable.rect(this.tableZone.rows[r].cells[cell.colSpanStart].x + 1,
							row.y + 1,
							this.tableZone.rows[r].cells[cell.colSpanStart].width - 1,
							row_h - 1
						)
					}
					else if (cell.colspan !== undefined) {
						new_cell = this.sTable.rect(col.x + 1,
							row.y + 1,
							cell.width - 1,
							row_h - 1
						)

					}
					else if (cell.rowSpanStart !== undefined) {
						var rowSpan = this.tableZone.rows[cell.rowSpanStart].cells[c].rowspan
						var start_y = this.tableZone.rows[cell.rowSpanStart].cells[c].y
						var end_y = this.tableZone.rows[cell.rowSpanStart + rowSpan - 1].cells[c].y
						end_y += this.tableZone.rows[cell.rowSpanStart + rowSpan - 1].cells[c].height
						new_cell = this.sTable.rect(this.tableZone.rows[cell.rowSpanStart].cells[c].x + 1,
							start_y + 1,
							this.tableZone.rows[cell.rowSpanStart].cells[c].width - 1,
							(end_y - start_y) - 1
						)
					}
					else if (cell.rowspan !== undefined) {
						start_y = cell.y
						end_y = this.tableZone.rows[r + cell.rowspan - 1].cells[c].y
						end_y += this.tableZone.rows[r + cell.rowspan - 1].cells[c].height
						new_cell = this.sTable.rect(col.x + 1,
							row.y + 1,
							cell.width - 1,
							(end_y - start_y) - 1
						)

					}
					else {
						new_cell = this.sTable.rect(col.x + 1,
							row.y + 1,
							col_w - 1,
							row_h - 1
						)

					}
					this.isSpan = cell.colspan !== undefined && cell.colspan > 0 || cell.colSpanStart !== undefined
					this.isRowSpan = cell.rowspan !== undefined && cell.rowspan > 0 || cell.rowSpanStart !== undefined
				}
				else {
					new_cell = this.sTable.rect(col.x + 1,
						row.y + 1,
						col_w - 1,
						row_h - 1

					)
				}

				new_cell.attr({ 'fill': 'blue', 'fill-opacity': '0.5' })
				this.svg_selected_cells.clear()
				this.svg_selected_cells.add(new_cell)
				this.selected_row_index = r
				this.selected_col_index = c
				this.selectedCell = { id: 'r' + r + '_c' + c }
				this.selectedCells = undefined
			}
			else {
				// Missing col or row, clear the selection instead of showing an invalid selection
				this.svg_selected_cells.clear()
			}

			this.refreshSpans()
		},
		refreshMultiSelection(fromCol, fromRow, toCol, toRow) {
			fromCol = parseInt(fromCol)
			fromRow = parseInt(fromRow)
			toCol = parseInt(toCol)
			toRow = parseInt(toRow)

			if (fromCol !== undefined && toCol !== undefined) {

				if (fromCol == toCol && fromRow == toRow) {
					this.refreshSelection(this.tableZone.cols[this.selected_col_index], this.tableZone.rows[this.selected_row_index])
					return
				}

				this.first_selected_col_index = fromCol
				this.first_selected_row_index = fromRow

				this.last_selected_col_index = toCol
				this.last_selected_row_index = toRow

				var col_w = this.tableZone.w
				var first_column = this.tableZone.cols[fromCol]
				var last_column = this.tableZone.cols[toCol]

				if (fromCol == toCol) {
					var col_neighbors = this.getColBoundaries(first_column.id)
					if (col_neighbors !== undefined && col_neighbors.next) {
						col_w = col_neighbors.next.x - first_column.x
					}

				}
				else {

					if (last_column.x < first_column.x) {
						// Swap objects
						var old_first = first_column
						first_column = last_column
						last_column = old_first
						var old_from = fromCol
						fromCol = toCol
						toCol = old_from
					}

					if (toCol - fromCol >= 1) {
						toRow = fromRow
					}

					col_neighbors = this.getColBoundaries(last_column.id)
					if (col_neighbors !== undefined && col_neighbors.next) {
						col_w = col_neighbors.next.x - first_column.x
					}
					else {
						col_w = col_w - first_column.x
					}
				}

				var row_h = this.tableZone.h
				var first_row = this.tableZone.rows[fromRow]
				var last_row = this.tableZone.rows[toRow]

				if (fromRow == toRow) {
					var row_neighbors = this.getRowBoundaries(first_row.id)
					if (row_neighbors !== undefined && row_neighbors.next) {
						row_h = row_neighbors.next.y - first_row.y
					}
				}
				else {

					if (last_row.y < first_row.y) {
						// Swap objects
						old_first = first_row
						first_row = last_row
						last_row = old_first
						old_from = fromRow
						fromRow = toRow
						toRow = old_from
					}

					row_neighbors = this.getRowBoundaries(last_row.id)
					if (row_neighbors !== undefined && row_neighbors.next) {
						row_h = row_neighbors.next.y - first_row.y
					}
					else {
						row_h = row_h - first_row.y
					}
				}

				var new_cell = this.sTable.rect(first_column.x + 1,
					first_row.y + 1,
					col_w - 1,
					row_h - 1

				)
				new_cell.attr({ 'fill': 'blue', 'fill-opacity': '0.5' })
				this.svg_selected_cells.clear()
				this.svg_selected_cells.add(new_cell)

				this.selectedCells = { columns: [], rows: [] }
				this.isSpan = false
				this.isRowSpan = false

				if (toCol - fromCol >= 1) {
					// Column selection
					this.selectedCells = { columns: { start: fromCol, end: toCol }, rows: { start: fromRow, end: fromRow } }
					for (var col = fromCol; col <= toCol; col++) {
						if (this.tableZone.rows[fromRow].cells[col].colspan !== undefined ||
							this.tableZone.rows[fromRow].cells[col].colSpanStart !== undefined) {
							this.isSpan = true
						}
						if (this.tableZone.rows[fromRow].cells[col].rowspan !== undefined ||
							this.tableZone.rows[fromRow].cells[col].rowSpanStart !== undefined) {
							this.isRowSpan = true
						}
					}
				}
				else {
					// Row selection
					this.selectedCells = { columns: { start: fromCol, end: fromCol }, rows: { start: fromRow, end: toRow } }

					for (var row = fromRow; row <= toRow; row++) {
						if (this.tableZone.rows[row].cells[fromCol].rowspan !== undefined ||
							this.tableZone.rows[row].cells[fromCol].rowSpanStart !== undefined) {
							this.isRowSpan = true
						}
						if (this.tableZone.rows[row].cells[fromCol].colspan !== undefined ||
							this.tableZone.rows[row].cells[fromCol].colSpanStart !== undefined) {
							this.isSpan = true
						}
					}
				}
			}
			else {
				// Missing col or row, clear the selection instead of showing an invalid selection
				this.svg_selected_cells.clear()
				this.selectedCells = undefined
			}
		},

		refreshCells() {
			var redraw_span = false
			var last_x = this.tableZone.w
			for (var col = this.tableZone.cols.length - 1; col > -1; col--) {
				if (this.tableZone.cols.length == 1) {
					this.tableZone.cols[col].width = this.tableZone.w
					this.tableZone.cols[col].x = 0
				}
				else {
					if (col == 0) {
						// Make sure the first column is always correct
						this.tableZone.cols[col].x = this.tableZone.cols[col].svg_x || 0

					}
					this.tableZone.cols[col].width = Math.abs(last_x - this.tableZone.cols[col].x)
				}

				this.tableZone.cols[col].id = 'c_' + col

				// Start from the bottom.  here is where it's reversed.
				var last_y = this.tableZone.h
				for (var row = this.tableZone.rows.length - 1; row > -1; row--) {

					this.tableZone.rows[row].id = 'r_' + row
					if (this.tableZone.rows[row].cells.length > this.tableZone.cols.length) {
						this.tableZone.rows[row].cells = this.tableZone.rows[row].cells.slice(0, this.tableZone.cols.length)
					}
					var new_y = this.tableZone.rows[row].y
					if (row == 0) {
						this.tableZone.rows[row].y = 0
					}
					else {
						if (new_y === 0) {
							if (this.tableZone.rows[row].height !== undefined) {
								new_y = Math.abs(last_y - this.tableZone.rows[row].height)
							} else {

								if (this.tableZone.rows[row].highest_pt !== undefined && this.tableZone.rows[row].highest_pt > 0) {
									this.tableZone.rows[row].height = this.tableZone.rows[row].highest_pt - this.tableZone.rows[row].lowest_pt
									new_y = Math.abs(last_y - this.tableZone.rows[row].height)
								}

							}
						}
					}

					if (this.tableZone.rows[row].cells[col] !== undefined && this.tableZone.rows[row].cells[col].colspan) {
						var colspan = this.tableZone.rows[row].cells[col].colspan
						var col_w = this.tableZone.w
						if (col + colspan > this.tableZone.cols.length) {
							col_w = Math.abs(col_w - this.tableZone.rows[row].cells[col].x)
						}
						else {
							if (this.tableZone.cols[col + colspan] !== undefined) {
								col_w = Math.abs(this.tableZone.cols[col + colspan].x - this.tableZone.rows[row].cells[col].x)
							}
						}

						this.tableZone.rows[row].cells[col].width = col_w
						this.tableZone.rows[row].cells[col].y = new_y
						this.tableZone.rows[row].cells[col].x = this.tableZone.cols[col].x
						this.tableZone.rows[row].cells[col].id = 'r' + row + '_c' + col
						this.tableZone.rows[row].cells[col].height = last_y - new_y
					}
					else if (this.tableZone.rows[row].cells[col] !== undefined && this.tableZone.rows[row].cells[col].rowspan) {
						var rowspan = this.tableZone.rows[row].cells[col].rowspan
						var row_h = this.tableZone.h
						if (row + rowspan >= this.tableZone.rows.length) {
							row_h = row_h - this.tableZone.rows[row].cells[col].y
						}
						else {
							var end_y = this.tableZone.rows[row + rowspan - 1].cells[col].y
							end_y += this.tableZone.rows[row + rowspan - 1].cells[col].height

							if (this.tableZone.rows[row + rowspan].cells[col] !== undefined) {
								row_h = this.tableZone.rows[row + rowspan].cells[col].y - this.tableZone.rows[row].cells[col].y
							}
						}

						this.tableZone.rows[row].cells[col].width = this.tableZone.cols[col].width
						this.tableZone.rows[row].cells[col].y = this.tableZone.rows[row].y
						this.tableZone.rows[row].cells[col].id = 'r' + row + '_c' + col
						this.tableZone.rows[row].cells[col].height = row_h
					}
					else if (this.tableZone.rows[row].cells[col] !== undefined) {
						this.tableZone.rows[row].cells[col].width = this.tableZone.cols[col].width
						this.tableZone.rows[row].cells[col].y = new_y
						this.tableZone.rows[row].cells[col].x = this.tableZone.cols[col].x
						this.tableZone.rows[row].cells[col].id = 'r' + row + '_c' + col
						this.tableZone.rows[row].cells[col].height = last_y - new_y
					}
					this.tableZone.rows[row].height = last_y - new_y
					last_y = new_y
				}

				last_x = this.tableZone.cols[col].x
			}

			this.currentColCount = this.tableZone.cols.length
			this.currentRowCount = this.tableZone.rows.length
		},

		updateTableControl() {
			this.bg_cells.clear()
		},

		validateHeaderVals() {
			var retval = true
			if (this.tableZone.colHeader == 0 && this.tableZone.rowHeader == 0) {
				retval = false
				this.showHeaderMessage = true
			}
			return retval
		},
		checkAndSummarize() {
			if (this.tableZone && this.tableZone.rows && this.tableZone.autoSummarize) {
				var hasRowHeaderColSpans = false
				var hasColHeaderColSpans = false
				var hasDataColSpans = false
				var hasRowHeaderRowSpans = false
				var hasColHeaderRowSpans = false
				var hasDataRowSpans = false

				// Scan for spans
				for (var row = 0; row < this.tableZone.rows.length; row++) {
					for (var col = 0; col < this.tableZone.cols.length; col++) {
						if (this.tableZone.rows[row].cells[col].colspan !== undefined) {
							if (row < this.tableZone.rowHeader)
								hasRowHeaderColSpans = true
							if (col < this.tableZone.colHeader)
								hasColHeaderColSpans = true
							if (row >= this.tableZone.rowHeader && col >= this.tableZone.colHeader)
								hasDataColSpans = true
						}
						if (this.tableZone.rows[row].cells[col].rowspan !== undefined) {
							if (row < this.tableZone.rowHeader)
								hasRowHeaderRowSpans = true
							if (col < this.tableZone.colHeader)
								hasColHeaderRowSpans = true
							if (row >= this.tableZone.rowHeader && col >= this.tableZone.colHeader)
								hasDataRowSpans = true
						}
					}
				}

				this.tableZone.summary =
					"Rows:" + this.tableZone.rows.length +
					"\nColumns:" + this.tableZone.cols.length +
					(this.tableZone.colHeader > 1 ? "\nMultiple header columns" : "") +
					(this.tableZone.colHeader == 0 ? "\nNo header columns" : "") +
					(this.tableZone.rowHeader == 0 ? "\nNo header rows" : "") +
					(hasRowHeaderColSpans ? "\nHeader rows contain column spans" : "") +
					(hasColHeaderColSpans ? "\nHeader columns contain column spans" : "") +
					(hasDataColSpans ? "\nData rows contain column spans" : "") +
					(hasRowHeaderRowSpans ? "\nHeader rows contain row spans" : "") +
					(hasColHeaderRowSpans ? "\nHeader columns contain row spans" : "") +
					(hasDataRowSpans ? "\nData rows contain row spans" : "")
			}

		},
		getChromeVersion() {
			var pieces = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/)
			if (pieces == null || pieces.length != 5) {
				return undefined
			}
			pieces = pieces.map(piece => parseInt(piece, 10))
			return {
				major: pieces[1],
				minor: pieces[2],
				build: pieces[3],
				patch: pieces[4]
			}
		},

		getColBoundaries(id) {
			var previous_col = undefined
			var next_col = undefined
			for (var i = 0; i < this.tableZone.cols.length; i++) {
				if (this.tableZone.cols[i].id.toString() === id.toString()) {
					if (i + 1 < this.tableZone.cols.length) {
						next_col = this.tableZone.cols[i + 1]
					}
					break
				}
				else {
					previous_col = this.tableZone.cols[i]
				}
			}
			return { previous: previous_col, next: next_col }
		},

		getRowBoundaries(id) {
			var previous_row = undefined
			var next_row = undefined

			for (var i = 0; i < this.tableZone.rows.length; i++) {
				if (this.tableZone.rows[i].id.toString() === id.toString()) {
					if (i + 1 < this.tableZone.rows.length) {
						next_row = this.tableZone.rows[i + 1]
					}
					break;
				}
				else {
					previous_row = this.tableZone.rows[i]
				}
			}

			return { previous: previous_row, next: next_row }
		},

		changeRowHeader(rowHeader) {
			for (var r = 0; r < this.tableZone.rows.length; r++) {
				this.tableZone.rows[r].rhc = rowHeader;
			}
		},

		initTableEditor() {
			// Make a copy of the zone so we don't overwrite the original and can cancel operations
			// Param is selectedZone, written back to when we save tableZone
			// originalZone is the zone as of loading table editor
			// tableZone is the working copy
			var zone = this.selectedZone
			if (zone == null || zone == undefined) {
				return
			}
			var originalZone = JSON.parse(JSON.stringify(zone))

			// Set zoom level for this table
			var z = parseFloat((document.documentElement.offsetWidth - 500) / zone.w)
			z = parseFloat((Math.round(z * 2) / 2).toFixed(1)) - 0.5
			if (z > 4) {
				z = 4
			}

			this.tableZone = {
				id: originalZone.id,
				name: originalZone.name,
				type: originalZone.type,
				order: originalZone.order,
				merge: originalZone.merge,
				breakLine: originalZone.breakLine,
				x: originalZone.x,
				y: originalZone.y,
				w: originalZone.w,
				h: originalZone.h,
				headerlevel: originalZone.headerlevel,
				visible: originalZone.visible,
				cols: originalZone.cols,
				rows: originalZone.rows,
				rowcolAttribute: originalZone.rowcolAttribute || 'scope', //TODO: remove 'scope' or rename
				caption: originalZone.caption,
				summary: originalZone.summary,
				rowHeader: originalZone.rowHeader,
				colHeader: originalZone.colHeader,
				source: originalZone.source,
				autoSummarize: originalZone.autoSummarize
			}
			if (this.tableZone.autoSummarize === undefined) {
				this.tableZone.autoSummarize = this.autoSummarizeDefault;
			}

			var from_tags = false;
			if (originalZone.s !== undefined || originalZone.src_layout !== undefined) {
				this.tableZone.s = originalZone.s;
				from_tags = true;
			}

			// Get the zones that are under the current table
			//TODO: this function returns the zone elements, but the function 
			//that uses the return value just looks for the zone id, so not sure if the element
			//is actually needed
			//this.toRemove = this.findIntersectorsByZone(originalZone, 'eq-zone');
			//testing
			this.toRemove = helpers.findIntersectors(originalZone, this.zones);

			this.underZones = helpers.findIntersectors(originalZone, this.zones)
			this.placeholderDivs = [];

			for (var i = 0; i < this.underZones.length; i++) {
				if (this.underZones[i]['type'] != 'text') {
					this.placeholderDivs.push({
						x: this.underZones[i].x - this.tableZone.x,
						y: this.underZones[i].y - this.tableZone.y,
						w: this.underZones[i].w,
						h: this.underZones[i].h
					})
				}

			}
			var mlTableOption = true
			if (mlTableOption) {
				this.getMlTables(originalZone, from_tags)
			}

			this.getTablePreview(originalZone, from_tags)

		},
		setTableSensitivity() {
			this.disableTableRadiusSlider = true
			console.log('New radius = ' + this.autoDetect.tableRadius)
			this.updatingSensitivity = true
			var zone = this.tableZone

			this.fetchTablePreview({
				payload: {
					page_no: this.page.page_no,
					doc_key: this.document.key,
					name: zone.name,
					left: zone.x,
					top: zone.y,
					width: zone.w,
					height: zone.h,
					radius: this.autoDetect.tableRadius,
					tagged: null,
					zones: null,
					auto_detect: 2
				}
			}).then((response) => {
				this.setTableData(response.data.tableLayout)
				this.disableTableRadiusSlider = false
				this.updatingSensitivity = false

			}).catch((error) => {
				this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.errorNoTable"), color: 'error' })
				this.updatingSensitivity = false
			})
		},
		onRadiusMLTableChange() {
			if (!this.tableLoading) {
				this.setTableData(this.mlTables[this.autoDetect.columnRadius][this.autoDetect.rowRadius])
			}
		},
		approveTable() {
			this.saveTableLayout()
			this.hideTableEditor()
		},
		hideTableEditor() {
			//call out to page toolbars to hide the table editor
		},
		denyTable() {
			this.hideTableEditor()
		},
		
		closeTableEditor() {
			//user is closing the editor, but not saving.  need to restore the original zone, which is pristineZone
			console.log('reverting to original table zone')
			this.tableZone = this.pristineZone
			this.setSelectedZone(this.tableZone)
			EventBus.$emit("exit-table-editor")
		},
		setSelectedZone(zone) {
			this.$store.commit("page/SET_SELECTED_ZONE", zone)
		},
		saveTableLayout() {
			//tableZone has a val here
			console.log('in table save. tableZone: ', this.tableZone)

			//try this to change props on selected zone
			this.$store.state.page.selectedZone = this.tableZone
			this.$store.state.page.selectedZone.type = this.tableZone.type
			this.$store.state.page.selectedZone.name = this.tableZone.name
			this.$store.state.page.selectedZone.cols = this.tableZone.cols
			this.$store.state.page.selectedZone.rows = this.tableZone.rows
			this.$store.state.page.selectedZone.caption = this.tableZone.caption
			this.$store.state.page.selectedZone.summary = this.tableZone.summary
			this.$store.state.page.selectedZone.rowHeader = this.tableZone.rowHeader
			this.$store.state.page.selectedZone.colHeader = this.tableZone.colHeader
			this.$store.state.page.selectedZone.rowcolAttribute = this.tableZone.rowcolAttribute
			this.$store.state.page.selectedZone.autoSummarize = this.tableZone.autoSummarize
			this.$store.state.page.selectedZone.has_ocr = false

			for (var row = 0; row < this.selectedZone.rows.length; row++) {
				if (this.selectedZone.rows[row].cells !== undefined) {
					for (var c = 0; c < this.selectedZone.rows[row].cells.length; c++) {
						if (this.selectedZone.rows[row].cells[c].source !== 'PDF') {
							this.$store.state.page.selectedZone.has_ocr = true
							console.log('Has ocr!!!!')
							break;
						}
					}
				}
				if (this.selectedZone.has_ocr === true) {
					break
				}
			}

			for (var d = 0; d < this.toRemove.length; d++) {
				for (var i = 0; i < this.zones.length; i++) {
					if (this.zones[i].id.toString() === this.toRemove[d].id.toString()) {
						if (this.zones[i].id.toString() != this.selectedZone.id.toString()) {
							if ((this.zones[i].type != 'footnotelink') &&
								(this.zones[i].type != 'link') &&
								(this.zones[i].type != 'list') &&
								(this.zones[i].type != 'field') &&
								(this.zones[i].type != 'image') &&
								(this.zones[i].type != 'graphic')) {
								this.zones.splice(i, 1)
							}
						}
					}
				}
			}

			console.log('in table save2. tableZone: ', this.tableZone)

			for (i = 0; i < this.zones.length; i++) {
				if (this.zones[i].id.toString() === this.tableZone.id.toString()) {
					this.zones[i] = this.tableZone;
					//TODO: figure this out
					//$scope.$parent.zoneSelected($scope.zones[i]);
					break;
				}
			}

			this.pristineZone = JSON.parse(JSON.stringify(this.tableZone));
			this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.tableSaved") , color: 'success' })
			return false
		},
		clearTableLayout() {
			this.tableZone.cols = []
			this.tableZone.rows = []
			this.currentColCount = 0
			this.currentRowCount = 0
		},
		changeCol(newColCount) {
			if (newColCount > this.tableZone.cols.length) {
				for (var col = this.tableZone.cols.length - 1; col > -1; col--) {
					if (this.splitCol(col)) {
						break
					}
				}

			}
			else {
				this.removeCol(this.tableZone.cols.length - 1)
			}
		},
		changeRow(newRowCount) {
			if (newRowCount > this.tableZone.rows.length) {
				for (var row = this.tableZone.rows.length - 1; row > -1; row--) {
					if (this.splitRow(row)) {
						break
					}
				}
			}
			else {
				if (newRowCount >= 1) {
					this.removeRow(this.tableZone.rows.length - 1)
				}
			}
		},
		flattenTable(table) {
			// Make a full deep copy, not a reference
			var flatTable = JSON.parse(JSON.stringify(table))

			for (var row = 0; row < flatTable.rows.length; row++) {
				// Group by row
				var current_row = flatTable.rows[row]
				delete current_row.cells
				current_row.cells = []

				for (var col_idx = 0; col_idx < table.rows[row].cells.length; col_idx++) {

					if (table.rows[row].cells[col_idx].colSpan !== undefined) {
						flatTable.rows[row].cells.push({
							id: 'r' + row + '_c' + col_idx,
							colspan: table.rows[row].cells[col_idx].colspan,
							x: table.rows[row].cells[col_idx].x,
							y: table.rows[row].cells[col_idx].y,
							width: table.rows[row].cells[col_idx].width,
							height: table.rows[row].cells[col_idx].height
						})
					}
					else if (table.rows[row].cells[col_idx].colSpanStart === undefined) {
						flatTable.rows[row].cells.push({
							id: 'r' + row + '_c' + col_idx,
							x: table.rows[row].cells[col_idx].x,
							y: table.rows[row].cells[col_idx].y,
							width: table.rows[row].cells[col_idx].width,
							height: table.rows[row].cells[col_idx].height,
						})
					}
				}
			}
			return flatTable
		},
		previewTable() {
			this.tableZone.version = 2
			var layout = JSON.stringify(this.tableZone)
			var url = '/api/v1/document/' + this.document.key + '/previewtable/' + this.page.page_no + '/' + this.tableZone.id
			//console.log('in preview table')
			//console.log('layout: ', layout)
			//console.log('underZones:', this.underZones)
			this.previewTableLayout({
				payload: {
					doc_key: this.document.key,
					page_no: this.page.page_no,
					layout: layout,
					tableZone: this.tableZone,
					childZones: this.underZones
				}
			}).then((response) => {
				//window.open(url, 'pagePreview')
				setTimeout(function () {
					window.open(process.env.VUE_APP_HOST_URL + url, '_new')
				}.bind(this), 500)
			}).catch((error) => {

			})
		},

		zoomIn() {

			console.log('ZoomIn');
			if (this.zoomLevel < 4) {
				this.zoomLevel += 0.5;
			}

			var transLeft = '200px';
			if (this.zoomLevel == 1) {
				transLeft = '0px';
			}
			else if (this.zoomLevel == 1.5) {
				transLeft = '100px';
			}

			//$("#tableEditor").css('zoom', this.zoomLevel);
			//$("#tableEditor").css('-moz-transform', 'translate(' + transLeft + ', 20px) scale(' + this.zoomLevel + ')');
			//$("#tableEditor").css('-moz-transform-origin', '0 0');

			//s/b
			document.getElementById("#tableEditor").style.zoom = this.zoomLevel
			document.getElementById("#tableEditor").style.MozTransform = 'translate(' + transLeft + ', 20px) scale(' + this.zoomLevel + ')'

			var newLeft = 320 / this.zoomLevel;
			//$("#tableEditor").css('left', newLeft + 'px');
			//s/b
			document.getElementById("#tableEditor").style.left = newLeft + 'px'
			return false;

		},

		zoomOut() {

			if (this.zoomLevel > 1) {
				this.zoomLevel -= 0.5;
			}
			var transLeft = '200px';
			if (this.zoomLevel == 1) {
				transLeft = '0px';
			}
			else if (this.zoomLevel == 1.5) {
				transLeft = '100px';
			}

			//$("#tableEditor").css('zoom', this.zoomLevel);
			//$("#tableEditor").css('-moz-transform', 'translate(' + transLeft + ', 20px) scale(' + this.zoomLevel + ')');
			//$("#tableEditor").css('-moz-transform-origin', '0 0');
			//s/b
			document.getElementById("#tableEditor").style.zoom = this.zoomLevel
			document.getElementById("#tableEditor").style.MozTransform = 'translate(' + transLeft + ', 20px) scale(' + this.zoomLevel + ')'


			var newLeft = 320 / this.zoomLevel;
			//$("#tableEditor").css('left', newLeft + 'px');
			//s/b
			document.getElementById("#tableEditor").style.left = newLeft + 'px'

			return false;

		},
		removeRow(index) {
			// We need at least 2 row and not the last row
			if (this.tableZone.rows.length > 1 && index < this.tableZone.rows.length) {
				if (index == this.tableZone.rows.length - 1) {
					index--;
				}
				var otherIndex = index + 1
				this.tableZone.rows[index].height += this.tableZone.rows[otherIndex].height

				for (var col_idx = 0; col_idx < this.tableZone.cols.length; col_idx++) {
					if (this.tableZone.rows[index] != undefined && this.tableZone.rows[index].cells[col_idx] !== undefined) {
						var cell = this.tableZone.rows[index].cells[col_idx]
						var cleanup_index = index + 1

						if (this.tableZone.rows[otherIndex].cells[col_idx].rowspan !== undefined) { // just above the rowspan is selected, delete the whole span
							for (var i = otherIndex + 1; i < this.tableZone.rows.length; i++) {
								delete this.tableZone.rows[i].cells[col_idx].rowSpanStart
							}
						} else if (cell.rowspan !== undefined) { // topmost row in a rowspan is selected. Shorten the span by 1
							cell.rowspan--
							if (cell.rowspan < 2) {
								delete cell.rowspan
							} else {
								cleanup_index = index + cell.rowspan + 1
							}
						} else if (cell.rowSpanStart !== undefined) { // another row in a rowspan is selected. Shorten by 1
							this.tableZone.rows[cell.rowSpanStart].cells[col_idx].rowspan--
							if (this.tableZone.rows[cell.rowSpanStart].cells[col_idx].rowspan < 2) {
								delete this.tableZone.rows[cell.rowSpanStart].cells[col_idx].rowspan
								delete cell.rowSpanStart
							} else if (this.tableZone.rows[otherIndex].cells[col_idx].rowSpanStart === undefined) { // the last row of a span is selected
								delete cell.rowSpanStart
							} else {
								cleanup_index = cell.rowSpanStart + this.tableZone.rows[cell.rowSpanStart].cells[col_idx].rowspan + 1
							}
						}

						for (var r = cleanup_index; r < this.tableZone.rows.length; r++) {
							if (this.tableZone.rows[r].cells[col_idx].rowSpanStart !== undefined) {
								this.tableZone.rows[r].cells[col_idx].rowSpanStart--
							}
						}
					}
				}
				this.tableZone.rows.splice(otherIndex, 1)
				this.refreshCells();
				this.updateTableControl();
				this.currentRowCount = this.tableZone.rows.length
				this.refreshGridSelectors();
				if (this.selected_row_index >= this.tableZone.rows.length) {
					this.selected_row_index = this.tableZone.rows.length - 1
				}
				this.refreshSelection(this.tableZone.cols[this.selected_col_index], this.tableZone.rows[this.selected_row_index]);

			}

		},
		splitRow(index) {
			var newHeight = (this.tableZone.rows[index].height / 2)

			if (newHeight > 5) {
				this.tableZone.rows[index].height = newHeight
				this.tableZone.rows.splice(index + 1, 0, { id: 'nr_s_' + index + 1, x: 0, y: this.tableZone.rows[index].y + newHeight, height: newHeight, rhc: this.tableZone.rowHeader, cells: [] })

				for (var col_idx = 0; col_idx < this.tableZone.cols.length; col_idx++) {

					if (this.tableZone.rows[index].cells[col_idx].rowspan !== undefined) {
						// If previous row was span
						this.tableZone.rows[index].cells[col_idx].rowspan++
						this.tableZone.rows[index + 1].cells.push({
							'y': this.tableZone.rows[index].y + newHeight,
							'x': this.tableZone.cols[col_idx].x,
							'rowSpanStart': index,
							'id': 'r' + index + 1 + '_c' + col_idx,
							'source': 'PDF'
						});
					}
					else if (this.tableZone.rows[index].cells[col_idx].rowSpanStart !== undefined) {
						// If previous row was part of a span
						this.tableZone.rows[index + 1].cells.push({
							'y': this.tableZone.rows[index].y + newHeight,
							'x': this.tableZone.cols[col_idx].x,
							'rowSpanStart': this.tableZone.rows[index].cells[col_idx].rowSpanStart,
							'id': 'r' + index + 1 + '_c' + col_idx,
							'source': 'PDF'
						});
						this.tableZone.rows[this.tableZone.rows[index].cells[col_idx].rowSpanStart].cells[col_idx].rowspan++

					}
					else {
						// Normal cell creation
						this.tableZone.rows[index + 1].cells.push({
							'y': this.tableZone.rows[index].y + newHeight,
							'x': this.tableZone.cols[col_idx].x, 'id': 'r' + index + 1 + '_c' + col_idx,
							'source': 'PDF'
						});
						for (var r = index + 1; r < this.tableZone.rows.length; r++) {
							if (this.tableZone.rows[r].cells[col_idx] !== undefined && this.tableZone.rows[r].cells[col_idx].rowSpanStart !== undefined) {
								this.tableZone.rows[r].cells[col_idx].rowSpanStart++
							}
						}
					}
				}
				this.refreshCells()
				this.currentRowCount = this.tableZone.rows.length
				this.refreshGridSelectors()
				this.updateTableControl()
				this.refreshSelection(this.tableZone.cols[this.selected_col_index], this.tableZone.rows[this.selected_row_index])
				return true
			}
			return false
		},
		splitCol(index) {
			var newWidth = this.tableZone.cols[index].width / 2

			if (newWidth > 5) {
				this.tableZone.cols[index].width = newWidth;
				this.tableZone.cols.splice(index + 1, 0, { id: 'nr_s_' + index, x: this.tableZone.cols[index].x + newWidth, y: 0, width: newWidth })
				for (var r = 0; r < this.tableZone.rows.length; r++) {

					if (index >= this.tableZone.rows[r].cells.length) {
						// some col span need to be splitted
						var lastCell = this.tableZone.rows[r].cells[this.tableZone.rows[r].cells.length - 1]
						if (lastCell !== undefined && lastCell.colspan !== undefined) {
							lastCell.colspan += 1
						}
					}
					else {
						if (this.tableZone.rows[r].cells[index].colspan !== undefined) {
							++this.tableZone.rows[r].cells[index].colspan
							this.tableZone.rows[r].cells.splice(index + 1, 0, {
								id: 'nr_s_' + index, x: this.tableZone.cols[index].x + newWidth,
								colSpanStart: index,
								y: 0, width: newWidth,
								'source': 'PDF'
							})
						} else if (this.tableZone.rows[r].cells[index].colSpanStart !== undefined) {
							++this.tableZone.rows[r].cells[this.tableZone.rows[r].cells[index].colSpanStart].colspan;
							this.tableZone.rows[r].cells.splice(index + 1, 0, {
								id: 'nr_s_' + index, x: this.tableZone.cols[index].x + newWidth,
								colSpanStart: this.tableZone.rows[r].cells[index].colSpanStart,
								y: 0, width: newWidth,
								'source': 'PDF'
							})
						} else {
							this.tableZone.rows[r].cells.splice(index + 1, 0, {
								id: 'nr_s_' + index, x: this.tableZone.cols[index].x + newWidth,
								y: 0, width: newWidth,
								'source': 'PDF'
							})
							// We now have a new cell, we need to check if any colspan after and update
							for (var c = index + 1; c < this.tableZone.rows[r].cells.length; c++) {
								if (this.tableZone.rows[r].cells[c].colSpanStart !== undefined) {
									this.tableZone.rows[r].cells[c].colSpanStart++
								}
							}
						}
					}
				}
				this.refreshCells()
				this.currentColCount = this.tableZone.cols.length
				this.refreshGridSelectors()
				this.updateTableControl()
				this.refreshSelection(this.tableZone.cols[this.selected_col_index], this.tableZone.rows[this.selected_row_index])
				return true
			}

			return false;
		},
		removeCol(index) {
			var otherIndex = null;
			if (this.tableZone.cols.length > 1) {
				if (index == this.tableZone.cols.length - 1) {
					// If the given column is the last one, merge with previous
					index--
				}
				otherIndex = index + 1
				this.tableZone.cols[index].width += this.tableZone.cols[otherIndex].width;
				this.tableZone.cols.splice(otherIndex, 1)

				for (var r = 0; r < this.tableZone.rows.length; r++) {
					var row = this.tableZone.rows[r]
					var cleanup_index = index + 1

					if (row.cells[otherIndex].colspan !== undefined) { // just left of a span is selected, delete the whole span.
						for (var i = otherIndex + 1; i < row.cells.length; i++) {
							delete row.cells[i].colSpanStart
						}
					}
					if (row.cells[index].colspan !== undefined) { // leftmost column of a span is selected. span becomes one shorter
						row.cells[index].colspan--
						if (row.cells[index].colspan < 2) {
							delete row.cells[index].colspan
						} else {
							cleanup_index = index + row.cells[index].colspan + 1
						}
					}
					else if (row.cells[index].colSpanStart !== undefined) { // other column of a span is selected.
						row.cells[row.cells[index].colSpanStart].colspan--
						if (row.cells[row.cells[index].colSpanStart].colspan < 2) {
							delete row.cells[row.cells[index].colSpanStart].colspan
							delete row.cells[index].colSpanStart
						} else if (row.cells[row.cells[otherIndex].colSpanStart] === undefined) { // the last column of a span is selected
							delete row.cells[index].colSpanStart
						} else {
							cleanup_index = row.cells[index].colSpanStart + row.cells[row.cells[index].colSpanStart].colspan + 1
						}
					}

					for (var c = cleanup_index; c < row.cells.length; c++) {
						if (row.cells[c].colSpanStart !== undefined) {
							row.cells[c].colSpanStart--
						}
					}
					row.cells.splice(otherIndex, 1)
				}

				this.refreshCells()
				this.updateTableControl()
				this.refreshGridSelectors()
				this.currentColCount = this.tableZone.cols.length

				if (this.selected_col_index >= this.tableZone.cols.length) {
					this.selected_col_index = this.tableZone.cols.length - 1
				}
				this.refreshSelection(this.tableZone.cols[this.selected_col_index], this.tableZone.rows[this.selected_row_index])
			}
		},
		spanColumns() {
			if (this.selectedCells !== undefined) {
				var row = this.tableZone.rows[this.selectedCells.rows.start]
				// First verify that no cells is currently span
				var isSpan = false
				for (var col = this.selectedCells.columns.start; col <= this.selectedCells.columns.end; col++) {
					if (row.cells[col].rowspan !== undefined
						|| row.cells[col].colspan !== undefined
						|| row.cells[col].rowSpanStart !== undefined
						|| row.cells[col].colSpanStart !== undefined) {
						isSpan = true
						this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.columnAlreadySpanned", [col]), color: 'error' })
						break
					}
				}

				if (!isSpan) {
					this.isSpan = true
					row.cells[this.selectedCells.columns.start].colspan = (this.selectedCells.columns.end - this.selectedCells.columns.start) + 1

					for (col = this.selectedCells.columns.start; col <= this.selectedCells.columns.end; col++) {
						if (col != this.selectedCells.columns.start) {
							row.cells[col].colSpanStart = this.selectedCells.columns.start
						}
					}
					this.refreshCells();
					this.refreshGridSelectors()
					this.updateTableControl()
					this.checkAndSummarize() // col spans impact summary
				}
			}
		},
		spanRows(cellSelection) {
			if (this.selectedCells !== undefined) {
				var col_index = this.selectedCells.columns.start
				var isRowSpan = false
				for (var r = this.selectedCells.rows.start; r <= this.selectedCells.rows.end; r++) {
					if (this.tableZone.rows[r].cells[col_index].rowspan !== undefined
						|| this.tableZone.rows[r].cells[col_index].colspan !== undefined
						|| this.tableZone.rows[r].cells[col_index].rowSpanStart !== undefined
						|| this.tableZone.rows[r].cells[col_index].colSpanStart !== undefined) {
						isRowSpan = true
						this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.rowAlreadySpanned", [r]), color: 'error' })
						break
					}
				}

				if (!isRowSpan) {
					var row = this.tableZone.rows[this.selectedCells.rows.start]
					this.isRowSpan = true
					row.cells[this.selectedCells.columns.start].rowspan = (this.selectedCells.rows.end - this.selectedCells.rows.start) + 1

					for (r = this.selectedCells.rows.start; r <= this.selectedCells.rows.end; r++) {
						if (r != this.selectedCells.rows.start) {
							this.tableZone.rows[r].cells[col_index].rowSpanStart = this.selectedCells.rows.start
						}
					}
					this.refreshCells()
					this.refreshGridSelectors()
					this.updateTableControl()
				}
			}
		},
		removeSpans() {
			if (this.tableZone === undefined)
				return console.error("Bad tableZone in removeSpans")
			var rowStart = this.selected_row_index
			var rowEnd = this.selected_row_index
			var colStart = this.selected_col_index
			var colEnd = this.selected_col_index

			if (this.selectedCells !== undefined) {
				rowStart = this.selectedCells.rows.start
				rowEnd = this.selectedCells.rows.end
				colStart = this.selectedCells.columns.start
				colEnd = this.selectedCells.columns.end
			}

			if (rowStart === undefined || rowEnd === undefined || colStart === undefined || colEnd === undefined || rowEnd < rowStart || colEnd < colStart)
				return console.error("Bad start/end in removeSpans")

			for (var row_index = rowStart; row_index <= rowEnd; row_index++) {
				for (var col_index = colStart; col_index <= colEnd; col_index++) {
					var currentCell = this.tableZone.rows[row_index].cells[col_index]

					// if the selection contains any colSpans, remove them too
					if (currentCell.colspan || currentCell.colSpanStart !== undefined) {
						var spanColStart = currentCell.colspan ? col_index : currentCell.colSpanStart;
						var spanColEnd = spanColStart + this.tableZone.rows[row_index].cells[spanColStart].colspan - 1
						if (spanColStart === undefined || spanColEnd === undefined || spanColEnd < spanColStart)
							return console.error("Bad row start/end in removeSpans")

						delete this.tableZone.rows[row_index].cells[spanColStart].colspan
						for (var i = spanColStart + 1; i <= spanColEnd; i++) {
							delete this.tableZone.rows[row_index].cells[i].colSpanStart
						}
						if (rowStart == rowEnd) { // if this is a horizontal selection as well as a column span, ff the cursor
							col_index = spanColEnd
						}
					} else if (currentCell.rowspan || currentCell.rowSpanStart !== undefined) {
						var spanRowStart = currentCell.rowspan ? row_index : currentCell.rowSpanStart
						var spanRowEnd = spanRowStart + this.tableZone.rows[spanRowStart].cells[col_index].rowspan - 1

						delete this.tableZone.rows[spanRowStart].cells[col_index].rowspan
						for (i = spanRowStart + 1; i <= spanRowEnd; i++) {
							delete this.tableZone.rows[i].cells[col_index].rowSpanStart
						}
						if (colStart == colEnd) { // if this is a vertical selection as well as a row span ff the cursor
							row_index = spanRowEnd
						}
					}
				}
			}
			this.isSpan = false
			this.isRowSpan = false
			this.refreshCells()
			this.updateTableControl()
			this.refreshSpans()
			this.checkAndSummarize()
		},
		isRowHeader(index, rowHeaderCount, cell) {
			if (cell.withspan !== undefined) {
				return (cell.withspan < rowHeaderCount)
			}
			else {
				return (index < rowHeaderCount)
			}
		},

		/*
			callbacks
		*/
		callbackOcr(message) {
			if (this.tableZone !== undefined && message !== undefined) {
				console.log('Message', message)

				for (var row = 0; row < this.tableZone.rows.length; row++) {
					if (this.tableZone.rows[row].cells !== undefined) {
						for (var c = 0; c < this.tableZone.rows[row].cells.length; c++) {
							if (this.tableZone.rows[row].cells[c] !== undefined && this.tableZone.rows[row].cells[c].ocrStatus === message.data.transactId) {
								this.tableZone.rows[row].cells[c].ocrText = message.data.ocrText
								this.tableZone.rows[row].cells[c].ocrStatus = undefined
								if (this.tableZone.rows[row].cells[c].ocrText == "") {
									this.tableZone.rows[row].cells[c].ocrError = 'No content, resize or move the zone and retry'
								}
								else {
									this.tableZone.rows[row].cells[c].source = 'OCR'
								}
								break;
							}
						}
					}
				}
			}
			if (this.ocrInProgress) {
				this.ocrCellProcessed++
				this.ocrPageProgress = (100 / this.ocrTotalCells) * this.ocrCellProcessed
				this.ocrPageProgress_msg = this.ocrCellProcessed + ' of ' + this.ocrTotalCells

				if (this.ocrZoneProcessed >= this.ocrTotalCells || this.ocrPageProgress > 99) {
					this.ocrInProgress = false
					this.ocrPageProgress_msg = ''
				}
			}
		},
		callbackImportOcrCell(message) {
			console.log('callback importocr cell', message)
			if (message != null && message.data != null) {
				console.log('callback message not null. data: ', message.data)
				if (message.data.document_key !== undefined && this.document !== undefined && message.data.document_key === this.document.key) {
					if (message.data.progress !== undefined) {
						this.ocrInProgress = true
						this.ocrPageProgress = Math.min(message.data.progress, 100);
						this.ocrPageProgress_msg = message.data.msg

					}
				}

			}
		},
		callbackTable(message) {
			if (message !== undefined) {
				if (message.data !== undefined && message.data.table_zone !== undefined) {
					console.log(message.data.table_zone)
					this.tableZone = message.data.table_zone
					this.ocrInProgress = false
					this.ocrPageProgress_msg = ''
					this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.tableCellsOCRUpdated"), color: 'success' })
				}
			}
			else {
				this.ocrInProgress = false
				this.ocrPageProgress_msg = ''
			}
		},

		//END Callbacks

		ocrAllCells() {
			this.ocrInProgress = true
			this.ocrCellProcessed = 0
			this.ocrPageProgress = 0
			this.ocrPageProgress_msg = 'Preparing cells...';
			this.ocrTotalCells = 0
			console.log('tableZone: ', this.tableZone)

			this.ocrTable({
				payload: {
					doc_key: this.document.key,
					page_no: this.page.page_no,
					table: JSON.stringify(this.tableZone),
				}
			}).then((response) => {
				var data = undefined;
				if (response.data !== undefined && response.data.data !== undefined) {
					data = response.data.data;
				}
				else {
					data = response.data;
				}
			}).catch((error) => {
				console.log('Unable to detect ocr for table')
				this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.unableOCRTable"), color: 'error' })
				EventBus.$emit("callback-table", undefined)

			})

			setTimeout(function () {
				if (this.ocrInProgress) {
					if (this.ocrPageProgress >= 100) {
						this.ocrInProgress = false;
						this.ocrPageProgress_msg = ''
					}
				}
			}, 6000);
		},
		customAllCells() {
			this.customInProgress = true
			this.customCellProcessed = 0
			this.ocrPageProgress = 0
			this.ocrPageProgress_msg = 'Preparing cells...'
			this.ocrTotalCells = 0

			this.customTable({
				payload: {
					doc_key: this.document.key,
					page_no: this.page.page_no,
					zone: JSON.stringify(this.tableZone),
				}
			}).then((response) => {
				var data = undefined;
				if (response.data !== undefined && response.data.data !== undefined) {
					data = response.data.data;
				}
				else {
					data = response.data;
				}
				if (data !== undefined && data.table != undefined) {
					this.tableZone = data.table
					this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.cellsCustomText"), color: 'success' })
				}
			}).catch((error) => {
				this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.unableTextContentTable"), color: 'error' })
			})
		},
		sourceChanged(tableZone, selectedCell) {
			var correctedCell = null
			//only making the call if custom

			if (this.selectedCell && this.selectedCell.source == 'Custom' &&
				(this.selectedCell.customText === undefined || this.selectedCell.customText === '')) {
				correctedCell = {
					'x': this.selectedCell['x'] + this.tableZone['x'],
					'y': this.selectedCell['y'] + this.tableZone['y'],
					'w': this.selectedCell['width'],
					'h': this.selectedCell['height']
				}

				console.log('about to make customSource call. corrected cell:', correctedCell)
				this.customSource({
					payload: {
						doc_key: this.document.key,
						page_no: this.page.page_no,
						zone: JSON.stringify(correctedCell)
					}
				}).then((response) => {
					var data = undefined;
					if (response.data !== undefined && response.data.data !== undefined) {
						data = response.data.data;
					}
					else {
						data = response.data;
					}
					if (data !== undefined && data.customText != undefined) {
						this.selectedCell.customText = data.customText;
					}

				}).catch((error) => {
					this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.unableTextContent"), color: 'error' })
				})
			}
			else {
				console.log('skipped customSource call')
			}
		},

		getOcr(cell) {
			this.ocrTableZone({
				payload: {
					doc_key: this.document.key,
					page_no: this.page.page_no,
					name: cell.name,
					id: cell.id,
					left: cell.x + this.tableZone.x,
					top: cell.y + this.tableZone.y,
					width: cell.width,
					height: cell.height,
				}
			}).then((response) => {
				var data = undefined;
				if (response.data !== undefined && response.data.data !== undefined) {
					data = response.data.data;
				}
				else {
					data = response.data;
				}
				if (data !== undefined && data.transactId != undefined) {
					cell.ocrStatus = data.transactId;
				}

			}).catch((error) => {
				this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.unableToOCR"), color: 'error' })
				EventBus.$emit("callback-ocr", undefined)
			})
		},

		/*
			nudge up means the topmost row height decreases as the bottommost row height increases.
			the increase or decrease is the amount of the nudge
		*/
		nudgeUp() {
			this.refreshCells()
			this.refreshSelection()
			//this doesn't make sense.  0-indexed arrays have elements up to length-1, not length
			for (var i = 0; i <= this.tableZone.rows.length; i++) {
				if (i == 0) {
					this.tableZone.rows[i].height = this.tableZone.rows[i].height - 1
				}
				else if (i == this.tableZone.rows.length) {
					this.tableZone.rows[i].height = this.tableZone.rows[i].height + 1
				}
				else {
					this.tableZone.rows[i].svg_y = this.tableZone.rows[i].svg_y - 1;
					this.tableZone.rows[i].y = this.tableZone.rows[i].y - 1
				}

				this.refreshGridSelectors()
				this.updateTableControl()
			}
		},
		/*
			nudge down means the topmost row height increases as the bottommost row height decreases.
			the increase or decrease is the amount of the nudge
		*/
		nudgeDown() {
			this.refreshCells()
			this.refreshSelection()
			for (var i = 0; i <= this.tableZone.rows.length; i++) {
				if (i == 0) {
					this.tableZone.rows[i].height = this.tableZone.rows[i].height + 1
				}
				else if (i == this.tableZone.rows.length) {
					this.tableZone.rows[i].height = this.tableZone.rows[i].height - 1
				}
				else {
					this.tableZone.rows[i].svg_y = this.tableZone.rows[i].svg_y + 1
					this.tableZone.rows[i].y = this.tableZone.rows[i].y + 1
				}

				this.refreshGridSelectors()
				this.updateTableControl()
			}
		},
		/*
			nudge left means the leftmost column width shrinks as the rightmost column width grows.
			the increase or decrease is the amount of the nudge
		*/
		nudgeLeft() {
			this.refreshCells()
			this.refreshSelection()
			for (var i = 0; i <= this.tableZone.cols.length; i++) {
				if (i == 0) {
					this.tableZone.cols[i].width = this.tableZone.cols[i].width - 1
				}
				else if (i == this.tableZone.cols[i].length) {
					this.tableZone.cols[i].width = this.tableZone.cols[i].width + 1
				}
				else {
					this.tableZone.cols[i].svg_x = this.tableZone.cols[i].svg_x - 1
					this.tableZone.cols[i].x = this.tableZone.cols[i].x - 1
				}
				this.refreshGridSelectors()
				this.updateTableControl()
			}
		},
		/*
			nudge right means the leftmost column width grows as the rightmost column width shrinks.
			the increase or decrease is the amount of the nudge.
		*/
		nudgeRight() {
			this.refreshCells()
			this.refreshSelection()
			for (var i = 0; i <= this.tableZone.cols.length; i++) {
				if (i == 0) { //first
					this.tableZone.cols[i].width = this.tableZone.cols[i].width + 1
				}
				else if (i == this.tableZone.cols.length) {  //last
					this.tableZone.cols[i].width = this.tableZone.cols[i].width - 1
				}
				else { //anything in between
					this.tableZone.cols[i].svg_x = this.tableZone.cols[i].svg_x + 1
					this.tableZone.cols[i].x = this.tableZone.cols[i].x + 1
				}
				this.refreshGridSelectors()
				this.updateTableControl()
			}
		},

		/*
			Add snap svg plugin
		*/
		addSnapSvg() {
			Snap.plugin(function (Snap, Element) {
				var $this = this //'this' is the outer vue component, which is referred to as '$this' inside
				Element.prototype.alert_coords = function (evt) {
					var pt = document.getElementById("svgTable").createSVGPoint()  // Created once for document
					pt.x = evt.clientX / $this.zoomLevel
					pt.y = evt.clientY / $this.zoomLevel

					// The cursor point, translated into svg coordinates
					var cursorpt = pt.matrixTransform(document.getElementById("svgTable").getScreenCTM().inverse())
					console.log("cursorpt (" + cursorpt.x + ", " + cursorpt.y + ")")
				}

				Element.prototype.bglayer = function (params) {
					this.mouseover(function () {
						//only clear if dragging
						if ($this.dragging !== undefined && $this.dragging !== null && $this.dragging == true) {
							$this.svg_selected_cells.clear()
						}
					})

					this.click(function (evt) {
						var pt = document.getElementById("svgTable").createSVGPoint()  // Created once for document
						var version = $this.getChromeVersion()
						if (version === undefined || (version !== undefined && version.major < 94)) {
							pt.x = evt.clientX / $this.zoomLevel
							pt.y = evt.clientY / $this.zoomLevel
						}
						else {
							pt.x = evt.clientX
							pt.y = evt.clientY
						}

						// The cursor point, translated into svg coordinates
						var cursorpt = pt.matrixTransform(document.getElementById("svgTable").getScreenCTM().inverse())
						var found_col = undefined
						for (var col = 0; col < $this.tableZone.cols.length; col++) {
							if (cursorpt.x >= $this.tableZone.cols[col].svg_x && cursorpt.x <= $this.tableZone.cols[col].svg_x + $this.tableZone.cols[col].width) {
								found_col = $this.tableZone.cols[col]
								break
							}
						}

						var found_row = undefined
						for (var row = 0; row < $this.tableZone.rows.length; row++) {
							if (cursorpt.y >= $this.tableZone.rows[row].svg_y && cursorpt.y <= $this.tableZone.rows[row].svg_y + $this.tableZone.rows[row].height) {
								found_row = $this.tableZone.rows[row]
								break
							}
						}
						console.log('selected cells: ', $this.selectedCells)

						if ($this.selectedCells && evt.shiftKey) {
							// Something is already selected, need to span the selection
							$this.refreshMultiSelection($this.selected_col_index, $this.selected_row_index, found_col.id.replace('c_', ''), found_row.id.replace('r_', ''))
						}
						else if ($this.selectedCell && evt.shiftKey) {
							console.log('here!')
							if (found_col !== undefined && found_row !== undefined) {
								$this.refreshMultiSelection($this.selected_col_index, $this.selected_row_index, found_col.id.replace('c_', ''), found_row.id.replace('r_', ''))
							}
						}
						else {
							console.log('here! 2')
							$this.refreshSelection(found_col, found_row)
						}

					})

					return this
				};

				Element.prototype.limitCol = function (params) {
					// Init initial values of the column divider
					this.data('col_ref', params.col_obj)
					this.data('col', params.col)
					this.data('x', params.x)
					this.data('origX', params.x)
					this.data('table_width', params.maxx)
					this.data('original_transform', this.transform().local)
					this.data('ibb', this.getBBox())

					// Update the column object real svg x position
					params.col_obj.svg_x = this.data('ibb')['x']
					this.data('init_svg_x', this.data('ibb')['x'])

					if (this.data('col') != 'c_0') {

						this.drag(limitMoveCol, limitStartDrag, limitColEndDrag)

						this.mouseover(function () {

							if ($this.dragging === undefined || $this.dragging === null) {
								this.attr({ 'fill': $this.svg_styles.ROWCOL_SELECTOR_HOVER, 'stroke': 'black', 'strokeWidth': .5 })
							}

						}).mouseout(function () {
							if ($this.dragging === undefined || $this.dragging === null) {
								this.attr({ 'fill': $this.svg_styles.ROWCOL_SELECTOR, 'stroke': 'black', 'strokeWidth': 0 })
							}

						})
					}
					return this
				}

				function limitStartDrag(x, y, ev) {
					console.log('starting to drag column')
					$this.svg_selected_cells.clear()

					this.attr({ 'fill': $this.svg_styles.ROWCOL_SELECTOR_HOVER, 'stroke': 'black', 'strokeWidth': .5 })

					$this.dragging = this.data('col')
					this.data('startx', x)
					this.data('ibb', this.getBBox())

					// Set the min and max x position
					var neighbors = $this.getColBoundaries(this.data('col'))

					var minx = 1
					if (neighbors['previous'] != undefined) {
						minx = neighbors['previous'].svg_x
					}
					var maxx = this.data('table_width')
					if (neighbors['next'] != undefined) {
						maxx = neighbors['next'].svg_x
					}

					this.data('minx', parseInt(minx))
					this.data('maxx', parseInt(maxx))
					this.data('previous_transform', this.transform().local)
					this.data('offsetX', 0)
				}

				function limitMoveCol(dx, dy) {
					dx = dx / $this.zoomLevel
					var current_x = parseInt(this.data('startx')) + dx
					var svg_x = this.data('ibb')['x'] + dx

					var new_col_x = parseInt(this.data('x')) + dx

					if (svg_x - 1 > this.data('minx') && svg_x + 1 < this.data('maxx')) {
						this.last_dx = dx
						this.data('offsetX', dx)
						this.transform(this.data('original_transform') + "T" + [dx, this.data('y')])
						this.data('previous_transform', this.data('original_transform') + "T" + [dx, this.data('y')])
					}
					else {
						this.transform(this.data('previous_transform'))
					}

				}

				function limitColEndDrag(ev) {
					console.log('stopping column drag')
					this.stop()

					var new_svg_x = parseInt(this.getBBox()['x'])
					var svg_offset = 0

					if (new_svg_x > this.data('init_svg_x')) {
						svg_offset = new_svg_x - this.data('init_svg_x')
					}
					else {
						svg_offset = this.data('init_svg_x') - new_svg_x
					}

					var offset = this.data('offsetX') === 'undefined' ? 0 : this.data('offsetX')
					var new_col_x = parseInt(this.data('x') + offset)

					this.attr({
						'fill': $this.svg_styles.ROWCOL_SELECTOR, 'stroke': 'black', 'strokeWidth': 0, 'width': $this.svg_styles.COL_DIVIDER_WIDTH
					})

					// Update init values
					this.data('original_transform', this.transform().local)
					this.data('x', new_col_x)
					this.data('origX', new_col_x)
					this.data('init_svg_x', new_svg_x)

					for (var i = 0; i < $this.tableZone.cols.length; i++) {
						if ($this.tableZone.cols[i].id.toString() === $this.dragging.toString()) {	
							if (new_col_x !== undefined) {
								$this.tableZone.cols[i].x = new_col_x
							}
							$this.tableZone.cols[i].svg_x = new_svg_x
							break
						}
					}
					$this.dragging = undefined
					$this.refreshCells()
					$this.refreshSelection()
				}

				Element.prototype.limitRow = function (params) {
					this.data('row', params.col.id)
					this.data('x', params.x)
					this.data('y', params.y)
					this.data('origY', params.y)
					this.data('table_height', params.maxy)
					this.data('original_transform', this.transform().local)
					this.data('ibb', this.getBBox())

					params.col.svg_y = this.data('ibb')['y']
					this.data('init_svg_y', this.data('ibb')['y'])

					if (this.data('row') != 'r_0') {
						this.drag(limitMoveRow, limitRowStartDrag, limitRowEndDrag)

						this.mouseover(function () {
							if ($this.dragging === undefined || $this.dragging === null) {
								this.attr({ 'fill': $this.svg_styles.ROWCOL_SELECTOR_HOVER, 'stroke': 'black', 'strokeWidth': .5 })
							}
						}).mouseout(function () {
							if ($this.dragging === undefined || $this.dragging === null) {
								this.attr({ 'fill': $this.svg_styles.ROWCOL_SELECTOR, 'stroke': 'black', 'strokeWidth': 0 })
							}

						})
					}
					return this
				}

				function limitRowStartDrag(x, y, ev) {
					console.log('in limit row start drag')
					$this.svg_selected_cells.clear()

					this.attr({ 'fill': $this.svg_styles.ROWCOL_SELECTOR_HOVER, 'stroke': 'black', 'strokeWidth': .5 })

					$this.dragging = this.data('row')
					this.data('starty', y)
					this.data('ibb', this.getBBox())

					// Set the min and max x position
					var neighbors = $this.getRowBoundaries(this.data('row'))

					var miny = 1
					if (neighbors['previous'] != undefined) {
						miny = neighbors['previous'].svg_y
					}
					var maxy = this.data('table_height')
					if (neighbors['next'] != undefined) {
						maxy = neighbors['next'].svg_y
					}

					this.data('miny', parseInt(miny))
					this.data('maxy', parseInt(maxy))
					this.data('previous_transform', this.transform().local)
					this.data('offsetY', 0)
				}

				function limitMoveRow(dx, dy) {
					console.log('in limit move row')
					dy = dy / $this.zoomLevel

					var current_y = parseInt(this.data('starty')) + dy
					var svg_y = this.data('ibb')['y'] + dy

					var new_row_y = parseInt(this.data('y')) + dy

					if (svg_y - 1 > this.data('miny') && svg_y + 1 < this.data('maxy')) {
						this.last_dy = dy
						this.data('offsetY', dy)
						this.transform(this.data('original_transform') + "T" + [this.data('x'), dy])
						this.data('previous_transform', this.data('original_transform') + "T" + [this.data('x'), dy])
					}
					else {
						this.transform(this.data('previous_transform'))
					}
				}

				function limitRowEndDrag(x, y, ev) {
					console.log('in limit row end drag')
					this.stop()
					var new_svg_y = parseInt(this.getBBox()['y'])
					var svg_offset = 0

					if (new_svg_y > this.data('init_svg_y')) {
						svg_offset = new_svg_y - this.data('init_svg_y')
					}
					else {
						svg_offset = this.data('init_svg_y') - new_svg_y
					}

					var new_row_y = parseInt(this.data('y') + this.data('offsetY'));

					//this is causing the line to disappear after onmouseup
					//this.attr({
					//	'fill': $this.svg_styles.ROWCOL_SELECTOR, 'stroke': 'black', 'strokeWidth': 0, 'width': $this.svg_styles.COL_DIVIDER_WIDTH
					//})

					// Update init values
					this.data('original_transform', this.transform().local)
					this.data('y', new_row_y)
					this.data('origY', new_row_y)
					this.data('init_svg_y', new_svg_y)

					for (var i = 0; i < $this.tableZone.rows.length; i++) {
						if ($this.tableZone.rows[i].id.toString() === $this.dragging.toString()) {	
							$this.tableZone.rows[i].y = new_svg_y
							$this.tableZone.rows[i].svg_y = new_svg_y	
							break
						}
					}

					$this.dragging = undefined
					$this.refreshCells()
					$this.refreshSelection()

				}
			}.bind(this))

		},

		//target function for keydown event
		keydown(event) {
			console.log('keydown event:', event)
			console.log('target', event.target)

			if (event.target.nodeName == 'TEXTAREA' || event.target.nodeName == 'INPUT') {
				return false
			}

			this.handleBasicKeydownEvent(event)
		},
		//handle the basic keydown event
		handleBasicKeydownEvent(event) {
			switch (event.keyCode) {
				case 73: //i
					this.zoomIn()
					break
				case 79: //o
					this.zoomOut()
					break
				case 37: //Arrow lefft
					if (this.selectedCells && event.shiftKey) {
						// Something is already selected, need to span the selection
						var last_col = this.tableZone.cols[this.last_selected_col_index - 1];
						if (last_col) {
							this.refreshMultiSelection(this.selected_col_index,
								this.selected_row_index,
								this.last_selected_col_index - 1,
								this.selected_row_index);
						}
					}
					else if (this.selectedCell && event.shiftKey) {
						// Something is already selected, need to span the selection
						this.refreshMultiSelection(this.selected_col_index,
							this.selected_row_index,
							this.selected_col_index - 1,
							this.selected_row_index);
					}
					else {

						if (this.selected_col_index !== undefined && this.selected_col_index - 1 >= 0) {
							var select_col = this.tableZone.cols[this.selected_col_index - 1];
							if (select_col != undefined) {
								this.refreshSelection(select_col, this.tableZone.rows[this.selected_row_index]);
							}
						}
					}

					event.preventDefault()
					break
				case 38: //arrow up
					if (this.selectedCells && event.shiftKey) {
						// Something is already selected, need to span the selection
						var last_row = this.tableZone.rows[this.last_selected_row_index - 1]
						if (last_row) {
							this.refreshMultiSelection(this.selected_col_index,
								this.selected_row_index,
								this.selected_col_index,
								this.last_selected_row_index - 1)
						}
					}
					else if (this.selectedCell && event.shiftKey) {
						// Something is already selected, need to span the selection
						this.refreshMultiSelection(this.selected_col_index,
							this.selected_row_index,
							this.selected_col_index,
							this.selected_row_index - 1)
					}
					else {
						if (this.selected_row_index !== undefined && this.selected_row_index - 1 >= 0) {
							var select_row = this.tableZone.rows[this.selected_row_index - 1]
							if (select_row != undefined) {
								this.refreshSelection(this.tableZone.cols[this.selected_col_index], select_row)

							}
						}
					}

					event.preventDefault()
					break

				case 39: //Arrow right
					if (this.selectedCells && event.shiftKey) {
						// Something is already selected, need to span the selection
						last_col = this.tableZone.cols[this.last_selected_col_index + 1]
						if (last_col) {
							this.refreshMultiSelection(this.selected_col_index,
								this.selected_row_index,
								this.last_selected_col_index + 1,
								this.selected_row_index)
						}
					}
					else if (this.selectedCell && event.shiftKey) {
						// Something is already selected, need to span the selection
						last_col = this.tableZone.cols[this.selected_col_index + 1]
						if (last_col) {
							this.refreshMultiSelection(this.selected_col_index,
								this.selected_row_index,
								this.selected_col_index + 1,
								this.selected_row_index)
						}
					}
					else {
						if (this.selected_col_index !== undefined && this.selected_col_index + 1 < this.tableZone.cols.length) {
							select_col = this.tableZone.cols[this.selected_col_index + 1]
							if (select_col != undefined) {
								//                                this.refreshSelection(select_col, this.tableZone.rows[this.selected_row_index]);
								this.refreshSelection(select_col, this.tableZone.rows[this.selected_row_index])
							}
						}
					}
					event.preventDefault()
					break

				case 40: //arrow down
					if (this.selectedCells && event.shiftKey) {
						// Something is already selected, need to span the selection
						last_row = this.tableZone.rows[this.last_selected_row_index + 1]
						if (last_row) {
							this.refreshMultiSelection(this.selected_col_index,
								this.selected_row_index,
								this.selected_col_index,
								this.last_selected_row_index + 1)
						}
					}
					else if (this.selectedCell && event.shiftKey) {
						// Something is already selected, need to span the selection
						this.refreshMultiSelection(this.selected_col_index,
							this.selected_row_index,
							this.selected_col_index,
							this.selected_row_index + 1)
					}
					else {
						if (this.selected_row_index !== undefined && this.selected_row_index + 1 < this.tableZone.rows.length) {
							select_row = this.tableZone.rows[this.selected_row_index + 1];
							if (select_row != undefined) {
								this.refreshSelection(this.tableZone.cols[this.selected_col_index], select_row)
							}
						}
					}
					event.preventDefault()
					break

				case 8: // del
					break
				case 46: // del
					break
				case 67: // c -> Split column
					this.splitCol(this.selected_col_index)
					break
				case 68: // d -> Merge Down
					this.removeRow(this.selected_row_index)
					break
				case 77: // m -> Merge right
					this.removeCol(this.selected_col_index)
					break
				case 82: // r -> split Row
					this.splitRow(this.selected_row_index)
					break

				case 83: // s -> Span
					if (this.selectedCells !== undefined) {
						if (this.selectedCells.columns && this.selectedCells.columns.start != this.selectedCells.columns.end && !this.isSpan) {
							this.spanColumns(this.selectedCells);
						}
						else if (this.selectedCells.rows && !this.isRowSpan) {
							this.spanRows(this.selectedCells);
						}
					}
					break;



				default:
				//nothing here

			}
		}

	},
	mounted() {
		this.loading = true
		/*
			This is just a hack to load the snapsvg code.  Using the npm 
			route had some problems with loading the code.  Will resolve later.
		*/
		/*global Snap */
		//console.log('in mounted')
		const s = document.createElement("script")
		s.type = "text/javascript"
		s.src = "https://cdnjs.cloudflare.com/ajax/libs/snap.svg/0.5.1/snap.svg-min.js"
		//var addSnapSvg = this.addSnapSvg
		s.onload = function () {
			console.log("snap is loaded")
			//moving to the fetchTablePreview
			//this.snapTable = Snap("#svgTable")
			//addSnapSvg()
		}
		document.body.appendChild(s)

		console.log('initializing table editor from mounted')
		this.initTableEditor()

		EventBus.$on("callback-ocr", (msg) => {
			console.log('got a callback on ocr!!!', msg)
			this.callbackOcr(msg)
		})

		EventBus.$on("import/OCRCell", (msg) => {
			this.callbackImportOcrCell(msg)
		})

		EventBus.$on("callback-table", (msg) => {
			this.callbackTable(msg)
		})

		/*
			picked up from TableToolBar emitters
		*/
		EventBus.$on("split-row", (index) => {
			console.log('split row picked up: ', index)
			index != undefined ? this.splitRow(index) : null
		})

		EventBus.$on("remove-row", (index) => {
			console.log('delete row picked up: ', index)
			index != undefined ? this.removeRow(index) : null
		})

		EventBus.$on("split-column", (index) => {
			console.log('split column picked up: ', index)
			index != undefined ? this.splitCol(index) : null
		})

		EventBus.$on("remove-column", (index) => {
			console.log('remove col picked up: ', index)
			index != undefined ? this.removeCol(index) : null
		})

		EventBus.$on("span-columns", (selectedCells) => {
			console.log('span columns picked up: ', selectedCells)
			selectedCells != undefined ? this.spanColumns(selectedCells) : null
		})

		EventBus.$on("span-rows", (selectedCells) => {
			console.log('span rows picked up: ', selectedCells)
			selectedCells != undefined ? this.spanRows(selectedCells) : null
		})

		EventBus.$on("remove-spans", (msg) => {
			console.log('remove spans picked up: ', msg)
			this.removeSpans()
		})

		EventBus.$on("ml-table-radius-change", (msg) => {
			console.log('ml table radius change picked up: ', msg)
			console.log('autodetect col radius:', this.autoDetect.columnRadius)
			console.log('autodetect row radius:', this.autoDetect.rowRadius)
			this.onRadiusMLTableChange()
		})

		EventBus.$on("zoom-in-table", (msg) => {
			console.log('zoom in table picked up: ', msg)
			this.zoomIn()
		})

		EventBus.$on("zoom-out-table", (msg) => {
			console.log('zoom out table picked up: ', msg)
			this.zoomOut()
		})

		EventBus.$on("close-table-editor", (msg) => {
			console.log('close table editor picked up: ', msg)
			this.closeTableEditor()
		})

		EventBus.$on("ocr-all-cells", (msg) => {
			console.log('ocr all cells picked up: ', msg)
			this.ocrAllCells()
		})

		EventBus.$on("custom-all-cells", (msg) => {
			console.log('custom all cells picked up: ', msg)
			this.customAllCells()
		})

		EventBus.$on("nudge-up", (msg) => {
			this.nudgeUp()
		})

		EventBus.$on("nudge-down", (msg) => {
			this.nudgeDown()
		})

		EventBus.$on("nudge-left", (msg) => {
			this.nudgeLeft()
		})

		EventBus.$on("nudge-right", (msg) => {
			this.nudgeRight()
		})

		EventBus.$on("save-table", (msg) => {
			this.saveTableLayout()
			this.tableZoneIsDirty = false
			if(msg.close !== undefined && msg.close == true){
				this.closeTableEditor()
			}
			
		})

		EventBus.$on("preview-table-layout", (msg) => {
			this.previewTable()
		})

		EventBus.$on("source-changed", (msg) => {
			this.sourceChanged(this.tableZone, this.selectedCell)
		})

		EventBus.$on("get-ocr-selected-head", (msg) => {
			this.getOcr(this.selectedHead)
		})

		EventBus.$on("table-sensitivity-change", (msg) => {
			this.setTableSensitivity()
		})

		EventBus.$on("check-and-summarize", (msg) => {
			this.checkAndSummarize()
		})


	},
	created() {
		window.addEventListener("keydown", this.keydown)
	},

	beforeDestroy(){	
		EventBus.$off('callback-ocr')
		EventBus.$off('import/OCRCell')
		EventBus.$off('callback-table')
		EventBus.$off('split-row')
		EventBus.$off('remove-row')
		EventBus.$off('split-column')
		EventBus.$off('remove-column')
		EventBus.$off('span-columns')
		EventBus.$off('span-rows')
		EventBus.$off('remove-spans')
		EventBus.$off('ml-table-radius-change')
		EventBus.$off('zoom-in-table')
		EventBus.$off('zoom-out-table')
		EventBus.$off('close-table-editor')
		EventBus.$off('ocr-all-cells')
		EventBus.$off('custom-all-cells')
		EventBus.$off('nudge-up')
		EventBus.$off('nudge-down')
		EventBus.$off('nudge-left')
		EventBus.$off('nudge-right')
		EventBus.$off('save-table')
		EventBus.$off('preview-table-layout')
		EventBus.$off('get-ocr-selected-head')
		EventBus.$off('table-sensitivity-change')
		EventBus.$off('check-and-summarize')
		
	}

}

</script>

<style lang="scss" scoped>
#table-editor-container {
	position: fixed;
	left: 412px;
	top: 152px;
	overflow: auto;
	width: 83%;
	height: 83%;
}

#tableEditor {
	position: absolute;
	box-shadow: 0 0 3px 1px rgb(0 0 0 / 35%);
	background-color: #ffffff;
	left: 10px;
	top: 10px;
	/* overflow-y: auto; */
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
}

.ui-svg-ew {
	cursor: ew-resize;
	width: 4px;
	stroke: 4;
	height: 100%;
}

.ui-svg-ns {
	cursor: ns-resize;
	height: 4px;
	top: 0;
	width: 100%;
}
</style>