<template>
<v-app id="app">

    <systemBar/>
    <mainMenu/>
    <v-main>
        <confirm ref="confirm"></confirm>
        <transition name="fade">
          <v-container class="no-margin-padding">
            <router-view/>
          </v-container>
          
        </transition>
    </v-main>

    <Snackbar></Snackbar>

</v-app>
</template>

<script>
import systemBar from "@/components/SystemBar"
import mainMenu from "@/components/MainMenu"
import Snackbar from "@/components/Snackbar"
import confirm from "@/components/Confirm"

import { mapGetters, mapActions } from "vuex"

const Centrifuge = require('centrifuge')

export default {
  name: 'App',
  components: {
    systemBar,
    mainMenu,
    Snackbar,
    confirm
  },
  data() {
    return {
      centrifuge: undefined,
      connected: false,
      retrying : false,
      subscription: undefined,
    }
  },
  computed: {
    ...mapGetters({
      userChannel: "user/userChannel",
      checkConnection: "application/checkConnection",
			
    }),
  },  
  mounted() {
    this.$root.$confirm = this.$refs.confirm
		/****** when app mounts, check authentication.  if auth'd and set, login component should pick up   *****/
		//this.checkAuth()
		
		//fetch options will run before the user logs in, then fails, but doesn't get re-invoked
    //this.fetchOptions()
  },
  watch: {
    userChannel: function(channelInfo) {
        console.log("channelInfo", this.userChannel)
        this.centrifuge = new Centrifuge(channelInfo.url, 
                            { debug: true, 
                            'refreshHeaders': { "X-CSRF-TOKEN": channelInfo.csrf_token },
                            })
        this.centrifuge.setToken(channelInfo.token)
        this.centrifuge.on('connect', this.onConnect)
        this.centrifuge.on('disconnect', this.onDisconnect)
        this.centrifuge.on('refreshFailed', this.onRefreshFailed)
        this.subscription = this.centrifuge.subscribe(channelInfo.channel).on('publish', this.onMessage)
        this.centrifuge.connect()
    },
    checkConnection: function() {
      console.log('Checking connection')
      if (this.centrifuge !== undefined && this.centrifuge._status !== 'connected') {
        console.log('Not connected')
        this.$store.dispatch('application/socketDisconnected', {context: undefined})
        this.$store.dispatch('user/fetchUserChannel')
      }
    }
  },
  methods: {
    ...mapActions({
      fetchOptions: "application/fetchOptions",
			//checkAuth: "user/checkAuth",
    }),      
    onConnect(info) {
      this.$store.dispatch('application/socketConnected', {info: info})
      this.connected = true
    },
    onDisconnect(context) {
      this.connected = false
      this.$store.dispatch('application/socketDisconnected', {context: context})
      
      if (!this.retrying) {
        this.retrying = true
        console.log('Retrying')
        this.$store.dispatch('user/fetchUserChannel')
      }
      
    },
    onMessage(msg) {    
      this.$store.dispatch('application/processWSMessage', {msg: msg})
    },
    onRefreshFailed(context) {
      console.log('Refresh Failed', context)
    }
  }
}

</script>
