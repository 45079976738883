<template>
	<div>
		<script type='application/javascript' src='https://widget.freshworks.com/widgets/3000000170.js' async defer></script>


		<v-system-bar v-if="authenticated" app height="72" color="base" class="border-bottom"
			style="border-bottom: 1px solid #003366 !important;">
			<v-img src="@/assets/logo.svg" max-width="50" alt="Equidox Logo" class="mr-3" style="margin-left: 6px;"></v-img>

			<v-toolbar-title>
				<h1 class="system-toolbar-title font-weight-bold" style="margin-left:1px;"> {{ currentRouteName }} </h1>
				<span class="subtitle-1 system-toolbar-document-title" v-if="current_document"> {{ current_document.title }}
				</span>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn small class="" style="margin-right:120px !important;" @click="goToClassic">
				Switch to classic
				<v-img src="@/assets/logo.old.svg" max-width="36" alt="Equidox Logo" class="mt-3 ml-1" ></v-img>
			</v-btn>
		
			<UserMenu></UserMenu>
			
		</v-system-bar>
	</div>
</template>

<script>
import i18n from "@/plugins/i18n"
import UserMenu from "./UserMenu"
import { mapGetters } from "vuex"

export default {
	name: "SystemBar",
	components: {
		UserMenu,
	},
	data() {
		return {
			showWidget: false
		};
	},
	computed: {
		...mapGetters({
			authenticated: "user/authenticated",
			current_document: "document/getDocument",
			language: "language/getLanguage",
		}),
		currentRouteName() {
			return i18n.t(this.$route.meta.i18Msg)
		},
	},
	methods: {
		goToClassic(){
			window.open(process.env.VUE_APP_HOST_URL + '/classic', '_self')
		},
		showHelpWidget() {
			const container = document.getElementById("freshworks-container")
			if (container === null) {

				!function () { if ("function" != typeof window.FreshworksWidget) { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }()

				const FreshworksWidget = window.FreshworksWidget
				FreshworksWidget("setLabels", {
					'fr': {
						banner: "Contactez notre équipe de support",
						launcher: "Aide",
						contact_form: {
							title: "Contactez nous",
							submit: "Envoyer",
							confirmation: "Nous reviendrons vers vous bientôt"
						},
						frustration_tracking: {
							banner: "Pouvons-nous aider?",
							description: "Nous avons remarqué que vous êtes coincé. Dites-nous ce que vous avez essayé d'accomplir et notre équipe d'assistance vous contactera dans les meilleurs délais.",
							confirmation: "Merci. Nous serons en contact!"
						}
					},
					'es': {
						banner: "Contacta a nuestro equipo de soporte",
						launcher: "Apoyo",
						contact_form: {
							title: "Contáctenos",
							submit: "Enviar",
							confirmation: "¡Nos pondremos en contacto con usted pronto!"
						},
						frustration_tracking: {
							banner: "¿Podemos ayudar?",
							description: "Notamos que estás atrapado. Díganos qué estaba tratando de lograr y nuestro equipo de soporte se comunicará con usted lo antes posible.",
							confirmation: "Gracias. ¡Estaremos en contacto!"
						}
					}
				})
				FreshworksWidget('prefill', 'ticketForm', {
					priority: 1,
					custom_fields: {
						product_category: 'Equidox',
					}
				})
			}

			setInterval(function () {
				document.getElementById("freshworks-container").style.display = "inherit"
			}, 2000)


		},
		hideHelpWidget() {
			document.getElementById("freshworks-container").style.display = "none"
		}
	},
	mounted() {

		window.fwSettings = {
			'widget_id': 151000002168,
			'locale': this.language
		}


		console.log("Route name is =========== === = == = =>>>>>>>>")
		console.log(this.$route.meta.i18Msg)
		if (this.$route.meta.i18Msg !== undefined && this.authenticated) {
			this.showHelpWidget()
		}
	},
	watch: {
		authenticated: function (loggedin) {
			console.log("====/=/=/=/=/=/=/=/=/====/=/=/=/=/=/=/=/=/====/=/=/=/=/=/=/=/=/")
			if (loggedin) {
				console.log("Showing widget")
				this.showHelpWidget()
			}
			else {
				console.log("hiding widget")
				this.hideHelpWidget()
			}
		}
	}
}
</script>

<style lang="scss" scoped>
// not working color="base" overrides
.border-bottom {
	border-bottom: 1px solid #195872 !important;
}

.system-toolbar-title {
	color: var(--v-primary-base);
	margin-right: 1em;
	font-weight: 700 !important;
	font-size: 1.25rem;
}

.system-toolbar-document-title {
	color: var(--v-primary-base);
}
</style>
<style>
#freshworks-container {
	display: none;
}

</style>